import React from 'react';

const FullArrowIcon = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.41416 10L10.8499 17.4357L9.43569 18.85L0.585733 10L9.43569 1.15004L10.8499 2.56426L3.41416 10Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0002 11L2.00026 11V9L20.0002 9V11Z" />
        </svg>
    );
};

export default FullArrowIcon;