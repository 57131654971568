import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import InfoItem from "./InfoItem";

const InfoGroup = ({items}) => {
    return (
        <InfoGroupContainer>
            {items.map(item => <InfoItem key={`info-item-${item.id}`} item={item} />)}
        </InfoGroupContainer>
    );
};

const InfoGroupContainer = styled.div`

`

InfoGroup.propTypes = {
    items: PropTypes.array.isRequired,
};

export default InfoGroup;