import React from 'react';
import styled from "styled-components";

const PresentationItem = ({ item }) => {
    return (
        <PresentationItemWrapper>
            <PresentationItemContainer>
                {item.name}
            </PresentationItemContainer>
        </PresentationItemWrapper>
    );
};

const PresentationItemWrapper = styled.div`
  
`

const PresentationItemContainer = styled.a`
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.other.borderRadiusSmall};
  ${({ theme }) => theme.mixins.gothamRegular()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
  background-color: #fff;
  padding: 0 6px;
  margin: 1px;
  transition: background-color ${({ theme }) => theme.transitions.normal};
  :hover {
    background-color: ${({ theme }) => theme.colors.border};
  }
`

export default PresentationItem;