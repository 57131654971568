import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router';

import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { API_getDeveloping } from '../../utils/api';
import Loader from '../Loader';
import { preparePrice } from '../../utils/preparePrice';
import PageConstructor from '../PageConstructor';
import { searchIcons } from '../BankGuarantees/components';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const Developing = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const [tableFields, setTableFields] = useState([]);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback(allItems => {
    const [headerFields, ...bodyFields] = allItems;

    const preparedTableFields = headerFields.map((headerField, index) => {
      const isStageColumn = index === 0;
      const isDatesColumn = index === 1 || index === 2;

      const item = {
        id: index,
        name: 'cell_' + index,
        label: headerField,
      };

      if (isStageColumn) {
        item.props = {
          width: '200px',
        };
      }

      else if (isDatesColumn) {
        item.props = {
          width: '100px',
        };
      }

      else {
        item.props = {
          width: '200px',
        };
      }

      return item;
    });

    setTableFields(preparedTableFields);

    // По договоренности с бекендом
    const prepareValue = (value) => {
      return typeof value === 'number' ? preparePrice(value) : value;
    }

    const bodyProperties = bodyFields.map((properties, parentIndex) => {
      const propertiesItem = properties.reduce((result, property, index) => {
        result['cell_' + index] = { value: prepareValue(property) }
        return result;
      }, {});

      propertiesItem.id = { value: parentIndex };

      return propertiesItem;
    });

    return bodyProperties;
  }, []);

  const requestUrl = API_getDeveloping(contractId);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск по освоениям' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} fields={tableFields} data={filteredRecievedItems} addBetween={false} controls={false} /> : null}
        </PageContent>
      </PageContainer>
    );
  };

  const filteredSearchProperties = tableFields.map(({ name }) => name);

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl);

  const developingProps = {
    FILTERED_SEARCH_PROPERTIES: [
      ...filteredSearchProperties,
    ],

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'developing',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...developingProps} />;
};

export default Developing;