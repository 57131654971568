import React from 'react';
import {withAuth} from '../../utils/withAuth';
import Contracts from "../Contracts/ContractsPage";

const Archive = () => {
    return (
        <Contracts isArchive={true}/>
    );
};


export default withAuth(Archive);