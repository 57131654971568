import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import Cards from '../../components/Cards';
import Page from '../../components/Page';
import Input from '../../components/Input';
import SearchIcon from '../../components/Icons/SearchIcon';
import Button from '../../components/Button';
import {useModal} from '../../hooks/useModal';
import EditContractModal from '../../components/Forms/EditContractModal';
import PageFooter from '../../components/styled/PageFooter';
import PageContent from '../../components/styled/PageContent';
import PageHeading from '../../components/styled/PageHeading';
import PageContainer from '../../components/styled/PageContainer';
import Group from '../../components/Structure/Group';
import {fetchRequest} from '../../utils/fetchRequest';
import {API_URL_CONTRACTS} from '../../utils/api';
import {getAuthorizationHeader} from '../../utils/auth';
import Loader from '../../components/Loader';
import {filterItemsBySearchText} from '../../utils/filterItemsBySearchText';
import NoData from "../../components/NoData";
import {TAB_CHARACTERISTICS} from '../Contract/tabs';
import {keyToValue} from '../../utils/dataConversion';
import {prepareExportUrl} from '../../utils/prepareExportUrl';
import {setExportState} from '../../features/export/exportReducer';
import Item from "../../components/Table/TableHeader/Item";
import {routes} from "../../routes";
import {useHistory} from "react-router-dom";
import {calculateColor} from "../../utils/calculateColor";

export const contractsCardFields = [
    {
        name: 'name',
        label: keyToValue('name'),
    }, {
        name: 'number',
        width: 200,
        label: keyToValue('number'),
    }, {
        name: 'date',
        width: 250,
        isDate: true,
        label: keyToValue('date'),
    }, {
        name: 'deadline',
        width: 250,
        isDate: true,
        label: keyToValue('deadline'),
    }, {
        name: 'price',
        width: 600,
        isPrice: true,
        label: keyToValue('price'),
    }, {
        name: 'program_name',
        width: 480,
        label: keyToValue('program_name'),
    }, {
        name: 'counterpart',
        width: 1000,
        label: keyToValue('counterpart'),
    }, {
        name: 'note',
        label: keyToValue('note'),
    }
    , {
        name: 'is_archive',
        label: keyToValue('is_archive'),
    }
];

const searchIcon = {
    id: 0,
    component: SearchIcon,
    position: 'left'
};

const ContractsPage = ({isArchive = false}) => {
    const history = useHistory();

    const CONTRACTS_INITIAL = [];

    const dispatch = useDispatch();

    const [isFetched, setIsFetched] = useState(false);
    const [allContracts, saveAllContracts] = useState(CONTRACTS_INITIAL);
    const [filteredContracts, setFilteredContracts] = useState(CONTRACTS_INITIAL);
    const [noFoundAfterFiltering, setNoFoundAfterFiltering] = useState(false);

    const [sort, setSort] = useState({id: "0", direction: 'up', key: ''});

    const [showModal] = useModal({
        component: EditContractModal,
        name: 'new-contract',
        title: 'Новый контракт'
    });

    const prepareContracts = (contractsData) => {
        return contractsData.map(contractData => {
            const contractItem = {
                id: {value: contractData.id},
                route: {value: 'contracts/' + contractData.id + '/' + TAB_CHARACTERISTICS.key},
            };

            contractsCardFields.forEach(fieldsData => {
                const {name, isDate} = fieldsData;

                let value = contractData[name];

                if (isDate) {
                    value = value?.date;
                }

                contractItem[name] = {
                    ...fieldsData,
                    value,
                };
            });

            return contractItem;
        });
    };

    const requestUrl = API_URL_CONTRACTS;
    const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, contractsCardFields.slice(0, -1));

    useEffect(() => {
        dispatch(setExportState({
            exportUrl: EXPORT_MODULE_URL,
            exportName: 'contracts',
        }));
    }, [EXPORT_MODULE_URL]);

    const fetchData = useCallback(async () => {
        const options = {
            headers: {
                'Authorization': getAuthorizationHeader(),
            },
        };

        const {response, success} = await fetchRequest(requestUrl, options);

        return success && prepareContracts(response.data);
    }, [requestUrl]);

    useEffect(() => {
        const getData = async () => {
            const contracts = await fetchData();

            setIsFetched(true)

            if (contracts) {
                setFilteredContracts(contracts.filter(item => isArchive ? item?.is_archive?.value : !item?.is_archive?.value));
                saveAllContracts(contracts.filter(item => isArchive ? item?.is_archive?.value : !item?.is_archive?.value));
            }
        }


        getData();
    }, []);

    useEffect(() => {
        if (sort.id === "0") {
            return;
        }

        let sortedAndfilteredItems = filteredContracts;
        if (sort.id !== "0") {
            sortedAndfilteredItems = filteredContracts.sort((a, b) => {
                const nameA = a[sort.key]?.value?.toUpperCase(); // ignore upper and lowercase
                const nameB = b[sort.key]?.value?.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return sort.direction === 'up' ? -1 : 1;
                }
                if (nameA > nameB) {
                    return sort.direction === 'up' ? 1 : -1;
                }

                // names must be equal
                return 0;
            });
        }
        setFilteredContracts(sortedAndfilteredItems)
    }, [sort, filteredContracts])

    const handleSearchValue = useCallback(({value: searchValue}) => {
        if (!searchValue) {
            setFilteredContracts(allContracts);
            return;
        }

        setNoFoundAfterFiltering(false);

        const FILTERED_PROPERTIES = [
            'counterpart',
            'number',
            'name',
            'note',
            'program_name',
        ];

        const filteredItems = filterItemsBySearchText(allContracts, FILTERED_PROPERTIES, searchValue);

        if (filteredItems.length === 0) {
            setNoFoundAfterFiltering(true);
        }

        setFilteredContracts(filteredItems);
    }, [allContracts, setFilteredContracts, sort]);

    const isLoaderVisible = !filteredContracts.length && !noFoundAfterFiltering && !isFetched;
    const isContractsVisible = Boolean(filteredContracts.length);

    return (
        <Page>
            <PageContainer>
                <PageHeading>
                    <Group>
                        <SearchWrapper>
                            <Input
                                icon={[searchIcon]}
                                placeholder='Поиск контрактов'
                                name='contracts'
                                onChange={handleSearchValue}/>
                        </SearchWrapper>
                        <Item width={'300px'} item={'Дата подписания контракта'} onSort={(data) => {
                            setSort({...data, direction: data.direction === 'up' ? 'down' : 'up'})
                        }} sort={sort.id === "1" ? sort : undefined} id='1' name='date' noSortable={false}/>
                        <Item width={'200px'} item={'Наименование'} onSort={(data) => {
                            setSort({...data, direction: data.direction === 'up' ? 'down' : 'up'})
                        }} sort={sort.id === "2" ? sort : undefined} id='2' name='name' noSortable={false}/>
                        <Item width={'200px'} item={'Номер'} onSort={(data) => {
                            setSort({...data, direction: data.direction === 'up' ? 'down' : 'up'})
                        }} sort={sort.id === "3" ? sort : undefined} id='3' name='number' noSortable={false}/>
                    </Group>
                </PageHeading>
                <PageContent>
                    <Loader isLoading={isLoaderVisible}/>
                    {isContractsVisible && (
                        <Cards items={filteredContracts} modalType='contract'/>
                    )}
                    {noFoundAfterFiltering && <NoData>Ничего не найдено.</NoData>}
                </PageContent>
                {
                    !isArchive &&
                    <PageFooter>
                        <PageFooterContainer>
                            <Button label='Добавить новый контракт' onClick={showModal}/>
                            <ArchiveButton onClick={() => history.push(routes.archive.path)}>Архив</ArchiveButton>
                        </PageFooterContainer>
                    </PageFooter>
                }
            </PageContainer>
        </Page>
    );
};

const ArchiveButton = styled.div`
    ${({theme, type}) => theme.mixins.gothamSemibold({color: calculateColor(type)})};
    cursor: pointer;
    font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    :hover {
        color: ${({theme}) => theme.colors.primary};
    }
`

const SearchWrapper = styled.div`
    width: 500px;
    min-width: 200px;
`

const PageFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`

export default ContractsPage;