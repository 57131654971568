export const objectToKeys = (obj) => {
    return Object.keys(obj)
}

export const keyToValue = (key) => {
    switch (key) {
        case 'name':
            return 'Наименование'
        case 'date':
            return 'Дата подписания контракта'
        case 'deadline':
            return 'Окончание выполнения работ'
        case 'counterpart':
            return 'Контрагент'
        case 'number':
            return 'Номер гос. контракта'
        case 'price':
            return 'Цена контракта'
        case 'note':
            return 'Примечание'
        case 'fio':
            return 'ФИО'
        case 'program_name':
            return 'Название программы'
        default:
            return ''
    }

}