import React from 'react';

const ArrowIcon = () => {
    return (
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.00092 2.93934L6.47059 0.469665L7.53125 1.53033L4.00092 5.06066L0.47059 1.53033L1.53125 0.469665L4.00092 2.93934Z" />
        </svg>
    );
};

export default ArrowIcon;