import { prepareDate } from "./dates";

const p = value => (String(value) || '').trim().toLocaleLowerCase();

/**
 * Фильтрует массив элементов, оставляет только объекты, 'properties' которых совпадают с 'searchText'
 */
export const filterItemsBySearchText = (arrayItems, properties, searchText) => {
  const arrayFiltered = [];
  const search = p(searchText);

  for (let i = 0, l = arrayItems.length; i < l; i++) {
    const arrayItem = arrayItems[i];

    let found = false;

    for (let j = 0, k = properties.length; j < k; j++) {

      const property = properties[j];

      if (!arrayItem[property]) {
        continue;
      }

      const isDate = arrayItem[property].isDate;

      let propertyValue = arrayItem[property].value;

      if (isDate) {
        propertyValue = prepareDate(propertyValue);
      }

      const propertyValuePrepared = p(propertyValue);

      if (propertyValuePrepared.includes(search)) {
        found = true;
        break;
      }
    }

    if (found) {
      arrayFiltered.push(arrayItem);
    }
  }

  return arrayFiltered;
};