import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router';

import PageHeading from '../styled/PageHeading';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import PageContainer from '../styled/PageContainer';
import { SearchWrapper } from '../BankGuarantees';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { API_getLimitBudgetaryObligations } from '../../utils/api';
import Loader from '../Loader';
import { setProperties } from '../../utils/setProperties';
import PageConstructor from '../PageConstructor';
import { searchIcons } from '../BankGuarantees/components';
import EditLimitModal from '../Forms/EditLimitModal';
import { useModal } from '../../hooks/useModal';
import AddNewLimitsYearModal from '../AddNewLimitsYearModal';
import LimitsYear from '../LimitsYear';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const ContractLimits = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const [tableFields, setTableFields] = useState([]);
  const [responseData, setResponseData] = useState(null);

  const addNewYearModalProps = {
    component: AddNewLimitsYearModal,
    name: 'add-new-limits-year-limit',
    title: 'Новый год для лимита',
    props: { contractId }
  };

  const [showNewYearModal] = useModal(addNewYearModalProps);

  const prepareRecievedItemsFunction = useCallback((allItems = []) => {
    const currentItem = allItems[0];

    const staticTableFields = (id = 0) => [
      !isArchive ? {
        id: id,
        name: 'add_new_year',
        component: <Button label='Добавить новый год' onClick={() => {
          showNewYearModal({ data: { responseData: currentItem } });
        }} />,
        noSortable: true,
        props: {
          width: '140px',
        },
      }: {},
      {
        id: id + 1,
        name: 'prices_per_note',
        label: 'Сумма',
        props: {
          width: '250px',
        },
      },
      {
        id: id + 2,
        name: 'update_dates',
        label: 'Дата изменения',
        props: {
          width: '130px',
        },
      },
      {
        id: id + 3,
        name: 'note',
        label: 'Примечание',
        props: {
          width: '400px',
        },
      },
    ];

    if (!allItems.length) {
      setTableFields(staticTableFields());

      return [];
    };

    const tableRowsCount = Math.max(
      currentItem.prices.length ||
      currentItem.prices_per_note.length ||
      currentItem.notes.length
    );

    setResponseData(currentItem);

    const tableFieldsYears = currentItem.years.map((year, index) => {
      return {
        id: index,
        _isYear: true,
        _yearName: year,
        name: 'year_' + year,
        label: year,
        props: {
          width: '220px',
        },
        component: <LimitsYear label={year} responseData={currentItem} />,
      }
    });

    let index = tableFieldsYears.length;

    const allTableFields = [].concat(tableFieldsYears, staticTableFields(index));
    const propertiesItemArray = [];

    for (let i = 0; i < tableRowsCount; i++) {
      const { notes, prices_per_note, prices, update_dates, id } = currentItem;

      const pricesByYearFields = tableFieldsYears.reduce((result, fieldYear, indexYear) => {
        result[fieldYear.name] = { value: prices[i][indexYear], isPrice: true };
        return result;
      }, {});

      const propertiesItem = setProperties({
        id: { value: i },
        note: { value: notes[i] },
        prices_per_note: { value: prices_per_note[i], isPrice: true },
        update_dates: { value: update_dates[i], isDate: true },
        add_new_year: { value: ' ' },
        ...pricesByYearFields
      });

      propertiesItemArray.push(propertiesItem);
    };

    setTableFields(allTableFields);

    return propertiesItemArray;
  }, [tableFields]);

  const requestUrl = API_getLimitBudgetaryObligations([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onRowClick,
      onSort,
      sort,
      modalProps,
    } = props;

    const onShowModalHandler = () => {
      const hasYears = tableFields.filter(({ _isYear }) => Boolean(_isYear)).length;

      if (!hasYears) {
        alert('Невозможно добавить новый лимит. Сначала необходимо добавить новый год.');
        return;
      }

      showModal();
    };

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск лимитов' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {<Table disabled={isArchive} onRowClick={onRowClick} sort={sort} onSort={onSort} fields={tableFields} data={filteredRecievedItems} modalType='contract-limit' modalProps={modalProps} />}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label='Добавить новый лимит' onClick={onShowModalHandler} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const FILTERED_SEARCH_PROPERTIES_YEARS = tableFields.filter(({ _isYear }) => _isYear)
    .map(({ name }) => name);

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, [
    ...tableFields,
    { name: 'numeric[][]', label: 'Стоимость', withoutPrefix: true },
  ]);

  const contractLimitsProps = {
    FILTERED_SEARCH_PROPERTIES: [
      ...FILTERED_SEARCH_PROPERTIES_YEARS,
      'note',
      'prices_per_note',
      'update_dates',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditLimitModal,
      name: 'new-limit',
      title: 'Новый лимит',
      props: { tableFields, contractId, responseData },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'limits',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...contractLimitsProps} />;
};

export default ContractLimits;