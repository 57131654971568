import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const File = ({file}) => {

    const handleFileClick = (e) => {
        e.stopPropagation()
    }

    return (
        <FileWrapper onClick={handleFileClick}>
            <FileContainer href={file.link} target={'_blank'} >
                {file.name}
            </FileContainer>
        </FileWrapper>
    );
};

const FileWrapper = styled.div`
  
`

const FileContainer = styled.a`
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: ${({theme}) => theme.other.borderRadiusSmall};
  ${({theme}) => theme.mixins.gothamRegular()};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  background-color: #fff;
  padding: 0 6px;
  margin: 1px;
  transition: background-color ${({theme}) => theme.transitions.normal};
  :hover {
    background-color: ${({theme}) => theme.colors.border};
  }
`

File.propTypes = {

};

export default File;