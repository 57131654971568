import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { useModal } from '../../hooks/useModal';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { filterItemsBySearchText } from '../../utils/filterItemsBySearchText';
import { getAuthorizationHeader } from '../../utils/auth';
import { fetchRequest } from '../../utils/fetchRequest';
import { setExportState } from '../../features/export/exportReducer';
import { DEFAULT_FILTERS_DATA, filterClosedDateItems } from '../../utils/filtersHelpers';
import { getObjectIdFromRoute } from '../../utils/getObjectIdFromRoute';

const PageConstructor = (props) => {
  const {
    FILTERED_SEARCH_PROPERTIES,

    modalProps: modalPropsData = {},
    prepareRecievedItemsFunction,
    requestUrl,

    getTemplate,

    isSortableTable,

    EXPORT_MODULE_NAME,
    EXPORT_MODULE_URL,

    IS_FILTERS_ENABLED,

    RECIEVED_DATA_ITEMS,
  } = props;

  const INITIAL_EMPTY_ITEMS = RECIEVED_DATA_ITEMS?.length ? prepareRecievedItemsFunction(RECIEVED_DATA_ITEMS) : [];

  const [allRecievedItems, setAllRecievedItems] = useState(INITIAL_EMPTY_ITEMS);
  const [filteredRecievedItems, setFilteredRecievedItems] = useState(INITIAL_EMPTY_ITEMS);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [isFiltersVisible, setFiltersVisible] = useState(false);
  const [filtersData, setFiltersData] = useState(DEFAULT_FILTERS_DATA);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!EXPORT_MODULE_NAME || !EXPORT_MODULE_URL) return;

    dispatch(setExportState({
      exportUrl: EXPORT_MODULE_URL,
      exportName: EXPORT_MODULE_NAME,
    }));
  }, [EXPORT_MODULE_URL, EXPORT_MODULE_NAME]);

  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const objectId = getObjectIdFromRoute(route);

  const [showModal] = useModal(modalPropsData);

  const [sort, setSort] = useState({
    id: null,
    key: null,
    direction: null,
  });

  const onSort = useCallback(({ id, direction, key }) => {
    if (!isSortableTable) return;

    setSort({
      key,
      id: direction !== 'down' ? id : undefined,
      direction: !direction ? 'up' : direction === 'up' ? 'down' : direction === 'down' ? undefined : undefined,
    });
  }, [sort, isSortableTable]);

  useEffect(() => {
    if (!sort.key) return;

    let directionValue = null;

    switch (sort.direction) {
      case 'up':
        directionValue = 1;
        break;

      case 'down':
        directionValue = -1;
        break;
    }

    let compareFunction = () => { };

    if (directionValue === null) {
      compareFunction = (A, B) => {
        if (A.id.value < B.id.value) {
          return -1;
        }

        if (A.id.value > B.id.value) {
          return 1;
        }

        return 0;
      };
    } else {
      const sortKey = sort.key;

      compareFunction = (A, B) => {
        const isPrice = A[sortKey].isPrice || B[sortKey].isPrice;
        const isNumber = A[sortKey].isNumber || B[sortKey].isNumber;

        const comparedValue = (isPrice || isNumber) ?
          A[sortKey].value - B[sortKey].value : // Умеем сортировать числа как numbers
          ('' + A[sortKey].value).localeCompare(B[sortKey].value); // Все остальное, как строки

        return directionValue * comparedValue;
      };
    }

    const sortedFilteredRecievedItems = [...filteredRecievedItems].sort(compareFunction);
    const sortedAllRecievedItems = [...allRecievedItems].sort(compareFunction);

    setFilteredRecievedItems(sortedFilteredRecievedItems);
    setAllRecievedItems(sortedAllRecievedItems);
  }, [sort]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const options = {
      headers: {
        'Authorization': getAuthorizationHeader(),
      },
    };

    const { success, response } = await fetchRequest(requestUrl, options);

    setLoading(false);

    return success && prepareRecievedItemsFunction(response.data);
  }, [contractId]);

  useEffect(() => {
    if (RECIEVED_DATA_ITEMS && RECIEVED_DATA_ITEMS.length) return;

    const getData = async () => {
      const allItemsArray = await fetchData();

      if (allItemsArray) {
        setAllRecievedItems(allItemsArray);
        setFilteredRecievedItems(allItemsArray);
      }
    };

    getData();
  }, [fetchData, RECIEVED_DATA_ITEMS]);

  const handleSearchValue = useCallback(({ value: searchValue }) => {
    setSearchValue(searchValue);

    if (!searchValue) {
      setFilteredRecievedItems(allRecievedItems);
      return;
    }

    const filteredItems = filterItemsBySearchText(allRecievedItems, FILTERED_SEARCH_PROPERTIES, searchValue);

    setFilteredRecievedItems(filteredItems);
  }, [setSearchValue, setFilteredRecievedItems, allRecievedItems]);

  const toggleFilters = useCallback(() => {
    if (!IS_FILTERS_ENABLED) return;

    setFiltersVisible(!isFiltersVisible)
  }, [isFiltersVisible, IS_FILTERS_ENABLED]);

  const onFiltersSubmit = useCallback((filterProps) => {
    if (!IS_FILTERS_ENABLED) return;
  
    const allRecievedItemsNew = filterClosedDateItems([...allRecievedItems], filterProps);

    setFilteredRecievedItems(allRecievedItemsNew);
    setSearchValue('');
  }, [allRecievedItems, setSearchValue, setFilteredRecievedItems, IS_FILTERS_ENABLED]);

  const onFileImport = useCallback(async (files, fileImportUrl) => {
    const [file] = files;

    const requestData = new FormData();

    requestData.append('file', file);
    requestData.append('contract_object_id', objectId);

    const options = {
      method: 'POST',
      headers: {
        'Authorization': getAuthorizationHeader(),
      },
      body: requestData,
    };

    const { success } = await fetchRequest(fileImportUrl, options);

    if (success) {
      alert('Импорт прошел успешно.')
      window.location.reload();
    } else {
      alert('Прозошла ошибка при импорте файла.')
    }
  }, [objectId]);

  return getTemplate({
    searchValue,
    setSearchValue,
    handleSearchValue,
    isLoading,
    allRecievedItems,
    filteredRecievedItems,
    setFilteredRecievedItems,
    showModal,
    onSort,
    sort,
    modalProps: modalPropsData.props,

    onFileImport,

    isFiltersVisible,
    toggleFilters,
    filtersData,
    onFiltersSubmit,
    setFiltersData,
  });
};

export default PageConstructor;