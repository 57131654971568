import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import CloseIcon from "../../Icons/CloseIcon";
import Icon from "../../Icon";

const File = ({file, onDelete}) => {
    return (
        <FileContainer>
            <Icon icon={CloseIcon} onClick={() => onDelete(file)} size={30} />
            <FileLabel>
                {file.label}
            </FileLabel>
        </FileContainer>
    );
};

const FileContainer = styled.div`
  display: flex;
  align-items: center;
`

const FileLabel = styled.div`
  ${({theme}) => theme.mixins.gothamRegular};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

File.propTypes = {
    file: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default File;