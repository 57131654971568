import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import PageContainer from '../../components/styled/PageContainer';
import PageHeading from '../../components/styled/PageHeading';
import Tabs from '../../components/Tabs';
import TabsContent from '../../components/Tabs/TabsContent';
import Page from '../../components/Page';
import { getObjectIdFromRoute } from '../../utils/getObjectIdFromRoute';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import styled from 'styled-components';

import AboutObject from '../../components/AboutObject';
import Watchers from '../../components/Watchers';
import UP from '../../components/UP';
import USh from '../../components/USh';
import UH from '../../components/UH';
import StatItem from '../../components/StatItem';
import { Statistics } from '../../components/GroupContent';
import { withAuth } from '../../utils/withAuth';

export const TAB_ABOUT = {
  label: 'Об объекте',
  key: 'about',
  component: <AboutObject />,
};

export const TAB_WATCHERS = {
  label: 'Наблюдатели',
  key: 'watchers',
  component: <Watchers />,
};

export const TAB_UP = {
  label: 'Закрытие УП',
  key: 'UP',
  component: <UP />,
};

export const TAB_USH = {
  label: 'Закрытие УШ',
  key: 'USh',
  component: <USh />,
};

export const TAB_UH = {
  label: 'Закрытие УХ',
  key: 'UH',
  component: <UH />,
};

export const TABS_ALL = [
  TAB_ABOUT,
  TAB_WATCHERS,
  TAB_UP,
  TAB_USH,
  TAB_UH,
];

const ObjectPage = () => {
  const route = useRouteMatch();
  const type = route.params.type;

  const { upData } = useSelector(state => state);
  const { upClosedCount, upCount } = upData;

  const hasUpCount = Boolean(upClosedCount) || Boolean(upCount);

  const objectId = getObjectIdFromRoute(route);
  const contractId = getContractIdFromRoute(route);

  const tabsList = useMemo(() =>
    TABS_ALL.map((tab, index) => {
      return {
        id: index,
        label: tab.label,
        key: tab.key,
        component: tab.component,
        route: `/contracts/${contractId}/objects/${objectId}/${tab.key}`
      };
    })
    , [objectId, contractId]);

  const getActiveTab = useCallback((tabsList, currentType) => {
    return tabsList.find(tab => tab.key === currentType);
  }, []);

  const [activeTab, setActiveTab] = useState(getActiveTab(tabsList, type));

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  }

  useEffect(() => {
    if (type) {
      handleTabChange(getActiveTab(tabsList, type));
    }
  }, [type]);

  return (
    <Page>
      <PageContainer>
        <PageHeading>
          <ContentWrapper>
            <Tabs tabs={tabsList} active={activeTab.id} onChange={handleTabChange} />
            {hasUpCount && (
              <StatisticsWrapper>
                <Statistics>
                  <StatItem label={'Количество УП'} value={upCount} />
                  <StatItem label={'Закрытые УП'} value={upClosedCount} />
                </Statistics>
              </StatisticsWrapper>
            )}
          </ContentWrapper>
        </PageHeading>
        <TabsContent>
          {activeTab.component}
        </TabsContent>
      </PageContainer>
    </Page>
  );
};

export const StatisticsWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  height: 40px;
  display: flex;
  align-items: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  
  > div:first-child {
    flex: 1 1 auto;
  }
`

ObjectPage.propTypes = {

};

export default withAuth(ObjectPage);