import React from 'react';

const FilterIcon = () => {
    return (
        <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0H0V3H30V0Z" />
            <path d="M24.5 9H5.5V12H24.5V9Z" />
            <path d="M19.5 18H10.5V21H19.5V18Z" />
        </svg>
    );
};

export default FilterIcon;