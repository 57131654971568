import React, { useState } from 'react';
import styled from "styled-components";
import Column from "../Structure/Column";
import Row from "../Structure/Row";
import { prepareDateToSee } from "../../utils/dates";
import DatePicker from "../DatePicker";
import Button from "../Button";
import Group from "../Structure/Group";
import Checkbox from "../Checkbox";

const Filters = ({ handleHide, onFiltersSubmit, filtersData, setFiltersData, DEFAULT_FILTERS_DATA }) => {
    const handleForm = (props) => {
        const { name, value } = props;

        const newFiltersData = Object.keys(filtersData).reduce((result, key) => {
            result[key] = { ...filtersData[key] };
            return result;
        }, {});

        newFiltersData[name].errorText = '';
        newFiltersData[name].value = value;

        setFiltersData(newFiltersData);
    };

    const resetFilters = () => {
        setFiltersData(DEFAULT_FILTERS_DATA);
    };

    const onSubmit = () => {
        handleHide();
        onFiltersSubmit(filtersData);
    };

    const handleCheckbox = ({ checked }) => {
        handleForm({ name: 'no_closed_dates_checkbox', value: checked });
    }

    return (
        <FiltersContainer>
            <Heading>
                Фильтры
            </Heading>
            <ContentWrapper>
                <Row direction={'row'}>
                    <Column>
                        <DatePicker
                            label='Плановая дата от'
                            onChange={handleForm}
                            name='date_from'
                            calendarValue={filtersData.date_from.value}
                            displayedValue={prepareDateToSee(filtersData.date_from.value)}
                        />
                    </Column>
                    <Column>
                        <DatePicker
                            label='Плановая дата до'
                            onChange={handleForm}
                            name='date_before'
                            calendarValue={filtersData.date_before.value}
                            displayedValue={prepareDateToSee(filtersData.date_before.value)}
                        />
                    </Column>
                </Row>
                <Row>
                    <Checkbox render={'Не закрытые даты'} onChange={handleCheckbox} checked={filtersData.no_closed_dates_checkbox.value} name={'no_closed_dates_checkbox'} />
                </Row>
                <Group align='right'>
                    <Button color={'alert'} label={'Сбросить фильтры'} onClick={resetFilters} />
                    <Button label='Закрыть' theme='hollow' onClick={handleHide} />
                    <Button label={'Применить'} onClick={onSubmit} />
                </Group>
            </ContentWrapper>
        </FiltersContainer>
    );
};

const ContentWrapper = styled.div`

`

const Heading = styled.div`
  ${({ theme }) => theme.mixins.gothamBold({ color: theme.colors.primary })};
  margin-bottom: 15px;
`

const FiltersContainer = styled.div`
  position: absolute;
  z-index: 10;
  min-width: 450px;
  border-radius: ${({ theme }) => theme.other.borderRadiusNormal};
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,.15), 0 4px 10px rgba(0,0,0,.05);
`

export default Filters;