import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const PageFooter = ({children}) => {
    return (
        <PageFooterContainer>
            {children}
        </PageFooterContainer>
    );
};

const PageFooterContainer = styled.div`
  flex: 0 0 auto;
  padding: 0 10px 10px 10px;
`

PageFooter.propTypes = {
    children: PropTypes.any.isRequired,
};

export default PageFooter;