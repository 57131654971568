import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import Group from '../../Structure/Group';
import Button from '../../Button';
import Column from '../../Structure/Column';
import DatePicker from '../../DatePicker';
import Textarea from '../../Textarea';
import { API_getBankGuarantees, API_getBankGuaranteesEditAndDeleteUrl, API_URL_BANK_GUARANTESS_SET_POSITIONS } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import { prepareModalData } from '../../../utils/prepareModalData';
import { prepareDateToSee } from '../../../utils/dates';

const EditBankGuaranteeModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
  const bankGuaranteeId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getBankGuaranteesEditAndDeleteUrl(bankGuaranteeId) :
      API_getBankGuarantees();

    return requestUrl;
  }, [isEditable, bankGuaranteeId]);

  const bankGuaranteeFieldsData = {
    amount: {
      required: true,
      value: item.amount || '',
      errorText: '',
      isPrice: true,
    },
    date: {
      required: true,
      value: item.date || '',
      errorText: '',
      isDate: true,
    },
    validity: {
      required: true,
      value: item.validity || '',
      errorText: '',
      isDate: true,
    },
    number: {
      required: true,
      value: item.number || '',
      errorText: '',
    },
    issued_by: {
      required: true,
      value: item.issued_by || '',
      errorText: '',
    },
    last_modified_date: {
      required: false,
      value: item.last_modified_date || '',
      errorText: '',
      isDate: true,
    },
    note: {
      required: false,
      value: item.note || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    }
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    return (
      <>
        <Row>
          <Input
            type='number'
            placeholder='Сумма гарантии'
            label='Сумма'
            onChange={handleForm}
            {...getFieldProps('amount')}
          />
        </Row>
        <Row direction='row'>
          <Column>
            <DatePicker
              label='Дата'
              onChange={handleForm}
              name='date'
              calendarValue={fieldsData.date.value}
              displayedValue={prepareDateToSee(fieldsData.date.value)}
              required={fieldsData.date.required}
              errorText={fieldsData.date.errorText}
            />
          </Column>
          <Column>
            <DatePicker
              label='Срок действия'
              onChange={handleForm}
              name='validity'
              calendarValue={fieldsData.validity.value}
              displayedValue={prepareDateToSee(fieldsData.validity.value)}
              required={fieldsData.validity.required}
              errorText={fieldsData.validity.errorText}
            />
          </Column>
        </Row>
        <Row>
          <Input
            placeholder='Номер гарантии'
            label='Номер'
            onChange={handleForm}
            {...getFieldProps('number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Кто выдал гарантию'
            label='Кто выдал'
            onChange={handleForm}
            {...getFieldProps('issued_by')}
          />
        </Row>
        <Row direction='row'>
          <Column>
            <DatePicker
              label='Дата последнего изменения'
              onChange={handleForm}
              name='last_modified_date'
              calendarValue={fieldsData.last_modified_date.value}
              displayedValue={prepareDateToSee(fieldsData.last_modified_date.value)}
              required={fieldsData.last_modified_date.required}
              errorText={fieldsData.last_modified_date.errorText}
            />
          </Column>
        </Row>
        <Row>
          <Textarea placeholder='Примечание' onChange={handleForm} name='note' value={fieldsData.note.value} label='Примечание' />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: bankGuaranteeFieldsData,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_BANK_GUARANTESS_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditBankGuaranteeModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditBankGuaranteeModal;