import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import DataField from "../../DataField";
import {Link, useRouteMatch} from "react-router-dom";
import {keyToValue} from "../../../utils/dataConversion";
import Icon from "../../Icon";
import SettingsIcon from "../../Icons/SettingsIcon";
import {useModal} from "../../../hooks/useModal";
import EditContractModal from "../../Forms/EditContractModal";
import ConfirmDeleteContract from "../../ConfirmDeleteContract";
import {useAnchorPopup} from "../../AnchorPopup/useAnchorPopup";
import List from "../../List";
import {preparePrice} from '../../../utils/preparePrice';
import {prepareDate} from '../../../utils/dates';
import {DEFAULT_EMPTY_PROPERTY} from '../../../utils/setProperties';
import EditGroupUPModal from '../../Forms/EditGroupUPModal';
import {getContractIdFromRoute} from '../../../utils/getContractIdFromRoute';
import {getObjectIdFromRoute} from '../../../utils/getObjectIdFromRoute';
import ConfirmDeleteObjectGroup from '../../ConfirmDeleteObjectGroup';
import {getGroupIdFromRoute} from '../../../utils/getGroupIdFromRoute';
import PlusIcon from "../../Icons/PlusIcon";
import {AddBetween} from "../../Table/TableBody/TableRow";
import ConfirmArchiveContract from "../../ConfirmArchiveContract";

const Card = ({item, controls, plain, theme, modalType, addBetween, last, allIds, disabled=false}) => {
    const route = useRouteMatch();
    const contractId = getContractIdFromRoute(route);
    const objectId = getObjectIdFromRoute(route);
    const groupId = getGroupIdFromRoute(route);
    const isActive = item.id.value === groupId;

    const listItems = [
        {
            id: 0,
            label: 'Редактировать',
        },
        {
            id: 1,
            label: !item?.is_archive?.value ? 'В архив' : 'Восстановить из архива',
            action: 'archive',
        },
        {
            id: 2,
            label: 'Удалить',
            type: 'alert',
            action: 'delete',
        }
    ]

    const _renderData = (item) => {
        const components = []
        for (let i = 0, keys = Object.keys(item); i < keys.length; i++) {
            const key = keys[i];

            if (key === 'id' || key === 'route' || key === 'is_archive') continue;

            const {value, isPrice, isDate} = item[key];

            let fieldValue = value || DEFAULT_EMPTY_PROPERTY;

            if (isPrice) {
                fieldValue = preparePrice(value);
            } else if (isDate) {
                fieldValue = prepareDate(value);
            }

            components.push(
                <DataField copy={key === 'name'} key={`data-${item.id.value}-${i}`} width={item[key].width}
                           title={keyToValue(key)} text={fieldValue} theme={theme}/>
            );
        }

        return components
    }

    const _renderContent = (plain) => {
        if (plain) {
            return content
        }

        return (
            <>
                <Link to={{pathname: item.route.value, state: item}}>
                    {content}
                </Link>
            </>
        )
    }

    const content = <Content>
        {_renderData(item)}
    </Content>

    let editModalProps = {};
    let deleteModalProps = {};
    let betweenModalProps = {};
    let archiveModalProps = {}

    switch (modalType) {
        case 'contract': {
            editModalProps = {
                component: EditContractModal,
                name: 'edit-contract',
                title: 'Редактировать контракт',
                props: {item, isEditable: true},
            };

            archiveModalProps = {
                component: ConfirmArchiveContract,
                name: 'archive-contract',
                title: !item?.is_archive?.value ? 'В архив' : 'Восстановить из архива',
                props: item,
            };

            deleteModalProps = {
                component: ConfirmDeleteContract,
                name: 'confirm-delete-contract',
                title: 'Подтверждение удаления контракта',
                props: item,
            };
            break;
        }

        case 'object-groups-up': {
            editModalProps = {
                component: EditGroupUPModal,
                name: 'edit-object-groups-up',
                title: 'Редактировать группу',
                props: {item, isEditable: true, objectId, contractId},
            };

            betweenModalProps = {
                component: EditGroupUPModal,
                name: 'edit-object-groups-up',
                title: 'Добавить новую группу',
                props: {objectId, contractId},
            };

            deleteModalProps = {
                component: ConfirmDeleteObjectGroup,
                name: 'confirm-delete-object-groups-up',
                title: 'Подтверждение удаления группы',
                props: {item, groupId},
            };
            break;
        }

        default:
            break;
    }

    const [showDeleteModal] = useModal(deleteModalProps)
    const [showArchiveModal] = useModal(archiveModalProps)
    const [showBetweenAddModal] = useModal(betweenModalProps)
    const [showEditModal] = useModal(editModalProps)

    const [showAnchorPopup] = useAnchorPopup({
        component: List, props: {
            list: listItems, onClick: (el) => {
                if (el.action === 'delete') {
                    showDeleteModal()
                } else {
                    if (el.action === 'archive') {
                        showArchiveModal()
                    } else {
                        showEditModal()
                    }
                }
            }
        }
    })

    const handleAddBetween = (e, id) => {
        e.preventDefault()
        e.stopPropagation()

        showBetweenAddModal({data: {appendAfterId: id, allIds}})
    }

    return (
        <CardWrapper>
            <CardContainer isActive={isActive} localTheme={theme}>
                {_renderContent(plain)}
                {controls && !disabled && <IconWrapper>
                    <Icon icon={SettingsIcon} color={theme === 'light' ? 'white' : 'primary'} onClick={(e) => {
                        showAnchorPopup(e)
                    }}/>
                </IconWrapper>}
                {!!addBetween && !last && !disabled && (
                    <AddBetween onClick={(e) => handleAddBetween(e, item.id.value)}>
                        <Icon icon={PlusIcon} size={30}/>
                    </AddBetween>
                )}
            </CardContainer>
        </CardWrapper>
    );
};

const IconWrapper = styled.div`
    opacity: 0;
    transition: opacity ${({theme}) => theme.transitions.normal};
`

const CardWrapper = styled.div`
    margin-bottom: 10px;

    :last-child {
        margin-bottom: 0;
    }
`

const CardContainer = styled.div`
    position: relative;
    display: flex;
    background-color: ${({isActive}) => isActive ? 'rgba(0,0,0,.2)' : ''};
    align-items: center;
    ${({localTheme}) => localTheme === 'dark' ? css`
        border: 1px solid ${({theme}) => theme.colors.border};
    ` : css`
        border: 1px solid rgba(255, 255, 255, .2);
    `};
    border-radius: ${({theme}) => theme.other.borderRadiusNormal};
    padding: 4px 10px;
    cursor: pointer;
    transition: background-color, border, ${({theme}) => theme.transitions.normal};

    @media (max-width: 1024px) {
        display: block;
        max-width: 100%;
    }
    
    :hover {
        ${({localTheme}) => localTheme === 'light' && css`
            border: 1px solid transparent;
        `};
        background-color: rgba(0, 0, 0, .05);

        ${IconWrapper} {
            opacity: 1;
        }

        ${AddBetween} {
            display: flex;
        }
    }

    border-color: ${({isActive}) => isActive ? '#37516D' : ''};

    a {
        flex: 1 1 auto;
    }
`

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
`

Card.propTypes = {
    item: PropTypes.object.isRequired,
    route: PropTypes.string,
    controls: PropTypes.bool,
    plain: PropTypes.bool,
    theme: PropTypes.oneOf(['light', 'dark'])
};

Card.defaultProps = {
    route: '',
    controls: true,
    plain: false,
    theme: 'dark',
}

export default Card;