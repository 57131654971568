import React from 'react';

const XLSIcon = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.7677 15.5267L5.63528 12.6667L3.84552 9.91333H5.5834L6.54961 11.4933L7.52231 9.91333H9.2148L7.42504 12.6667L9.29261 15.5267H7.55473L6.48476 13.84L5.44074 15.5267H3.7677Z" />
            <path d="M9.86316 15.5268V9.92676H11.3676V14.1668H14.0068V15.5001L9.86316 15.5268Z" />
            <path d="M14.2662 14.6667L15.1092 13.64C15.6056 14.0678 16.2319 14.3036 16.8795 14.3067C17.0389 14.3158 17.1978 14.2813 17.34 14.2067C17.3898 14.1743 17.4303 14.1288 17.4573 14.0749C17.4843 14.021 17.4968 13.9607 17.4934 13.9002C17.4901 13.8397 17.4712 13.7812 17.4385 13.7307C17.4058 13.6803 17.3606 13.6398 17.3075 13.6133C17.0763 13.5021 16.8322 13.4215 16.5813 13.3733L16.1014 13.2467C15.9539 13.2093 15.8088 13.1626 15.6669 13.1067C15.534 13.0542 15.4041 12.9941 15.2778 12.9267C15.1681 12.8601 15.064 12.7843 14.9666 12.7C14.8709 12.6196 14.7897 12.5226 14.7266 12.4133C14.6615 12.3007 14.6114 12.1796 14.5775 12.0533C14.5377 11.9057 14.5181 11.7532 14.5191 11.6C14.5128 11.3534 14.5615 11.1086 14.6615 10.8844C14.7616 10.6602 14.9103 10.4625 15.0963 10.3067C15.5349 9.95347 16.0833 9.77582 16.6396 9.80666C17.4464 9.77823 18.2375 10.0399 18.8768 10.5467L18.0922 11.6733C17.6563 11.3281 17.1249 11.1343 16.5748 11.12C16.4308 11.1078 16.2871 11.1453 16.1662 11.2267C16.1232 11.2559 16.0877 11.2954 16.0629 11.3419C16.038 11.3883 16.0245 11.4403 16.0236 11.4933C16.0236 11.5575 16.0413 11.6203 16.0745 11.6746C16.1078 11.7288 16.1553 11.7723 16.2116 11.8C16.4531 11.908 16.7053 11.9885 16.9638 12.04C17.2551 12.0988 17.5413 12.1813 17.8198 12.2867C18.0508 12.3672 18.2693 12.4817 18.4683 12.6267C18.6465 12.7506 18.7897 12.9208 18.8833 13.12C18.9763 13.329 19.0228 13.5568 19.0195 13.7867C19.0286 14.0414 18.9794 14.2948 18.8758 14.5264C18.7721 14.758 18.617 14.9614 18.4229 15.12C17.954 15.4607 17.3884 15.6319 16.8147 15.6067C16.3379 15.6095 15.8642 15.5283 15.414 15.3667C14.9883 15.2177 14.5975 14.9793 14.2662 14.6667Z" />
            <path d="M13.6631 20H2.87264C2.105 19.993 1.37142 19.6731 0.832866 19.1107C0.29431 18.5483 -0.00523867 17.7892 -5.59201e-05 17V3C-0.00523867 2.2108 0.29431 1.45171 0.832866 0.889285C1.37142 0.326863 2.105 0.00704441 2.87264 0H10.304C10.6927 0.000549058 11.0774 0.080381 11.4358 0.234871C11.7942 0.38936 12.1193 0.615436 12.3921 0.9L15.7057 4.38667C16.2385 4.94569 16.5366 5.69737 16.5358 6.48V8.66667H14.5904V6.48C14.5845 6.22913 14.4846 5.99029 14.3115 5.81333L11.0238 2.3C10.9306 2.20152 10.8184 2.12401 10.6946 2.07237C10.5707 2.02073 10.4377 1.99609 10.304 2H2.87264C2.62094 2.00699 2.38216 2.11607 2.20842 2.30343C2.03469 2.49078 1.94011 2.7412 1.94534 3V17C1.94011 17.2588 2.03469 17.5092 2.20842 17.6966C2.38216 17.8839 2.62094 17.993 2.87264 18H13.6631C13.8101 17.9994 13.9548 17.962 14.0847 17.8912C14.2146 17.8204 14.3258 17.7181 14.4088 17.5933C14.5264 17.419 14.5897 17.2121 14.5904 17H16.5358C16.5367 17.6275 16.3487 18.24 15.9976 18.7533C15.7324 19.1365 15.3824 19.4493 14.9765 19.6661C14.5707 19.8828 14.1205 19.9973 13.6631 20Z" />
        </svg>
    );
};

export default XLSIcon;