import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from "styled-components";
import { calculateHeight } from "../Button";
import { darken } from 'polished'

const FileLoader = ({ label, onLoad, size, multiple }) => {
    const ref = useRef()

    const handleFile = (e) => {
        onLoad && onLoad(e.target.files)
    }

    const loaderId = 'fileLoader_' + Math.random();

    return (
        <FileLoaderContainer size={size} htmlFor={loaderId}>
            {label}
            <input type="file" id={loaderId} ref={ref} onChange={handleFile} multiple={multiple} />
        </FileLoaderContainer>
    );
};

const FileLoaderContainer = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.other.borderRadiusNormal};
    border: ${({ buttonTheme, theme }) => buttonTheme === 'hollow' ? `2px solid ${theme.colors.primary}` : 0};
    height: ${({ size }) => calculateHeight(size)};
    padding: 0 20px;
    ${({ theme, buttonTheme }) => theme.mixins.gothamBold({ color: buttonTheme === 'hollow' ? theme.colors.primary : '#fff' })};
    font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
    transition: background-color ${({ theme }) => theme.transitions.normal};
    ${({ disabled }) => disabled ? css`
        cursor: not-allowed;
        background-color: ${({ theme, buttonTheme }) => buttonTheme === 'hollow' ? 'transparent' : theme.colors.border};
    ` : css`
        background-color: ${({ theme, buttonTheme }) => buttonTheme === 'hollow' ? 'transparent' : theme.colors.primary};
        cursor: pointer;
        :hover {
            background-color: ${({ theme, buttonTheme }) => buttonTheme === 'hollow' ? 'transparent' : darken(.1, theme.colors.primary)};
        }
    `}
    label{
      cursor: pointer;
    }
    input {
      position: absolute;
      left: -9999px;
      visibility: hidden;
    }
`

FileLoader.propTypes = {
    label: PropTypes.string.isRequired,
    onLoad: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['small', 'normal', 'big']),
};

FileLoader.defaultProps = {
    size: 'normal',
    multiple: true,
}

export default FileLoader;