import React from 'react';

import { API_getShipPropertiesEditAndDeleteUrl } from '../../utils/api';

import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteCharacteristic = ({ hideModal, data }) => {
  const characteristicId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getShipPropertiesEditAndDeleteUrl(characteristicId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить характеристику',
    DELETE_STATIC_VALUE: data.value.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteCharacteristic;