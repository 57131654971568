import React, { useCallback } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getAuthorizationHeader } from '../../utils/auth';
import { fetchRequest } from '../../utils/fetchRequest';
import Row from '../Structure/Row';
import Group from '../Structure/Group';
import Button from '../Button';
import { API_getLimitBudgetaryObligationsEditAndDeleteUrl } from '../../utils/api';

const ConfirmDeleteContractLimit = (props) => {
  const { hideModal, data } = props;
  const { rowIndex, responseData, ROW_INDEX_INITIAL_STATIC } = data;
  const limitId = responseData.id;

  const getRequestData = () => {
    const requestData = {
      contract_id: responseData.contract_id,
      notes: [].concat(responseData.notes),
      update_dates: [].concat(responseData.update_dates),
      years: [].concat(responseData.years),
      prices: [].concat(responseData.prices),
    };
    
    // Здесь используем порядковый индекс первоначальный, а не с учетом сортировок и фильтраций
    const deleteIndex = ROW_INDEX_INITIAL_STATIC;

    requestData.notes.splice(deleteIndex, 1);
    requestData.update_dates.splice(deleteIndex, 1);
    requestData.prices.splice(deleteIndex, 1);

    return requestData;
  };

  const REQUEST_URL = API_getLimitBudgetaryObligationsEditAndDeleteUrl(limitId);

  const deleteProperty = useCallback(() => {
    const requestData = getRequestData();

    const fetchDelete = async () => {
      const options = {
        method: 'PUT',
        headers: {
          'Authorization': getAuthorizationHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      };

      const url = REQUEST_URL;
      const { success } = await fetchRequest(url, options);

      if (success) {
        window.location.reload();
      }
    };

    fetchDelete();
  }, []);

  // Здесь используем порядковый индекс, чтобы показать порядок удаления строки визуальный
  const DELETE_STATIC_VALUE = rowIndex + 1;

  return (
    <ConfirmDeleteContainer>
      <Row>
        Вы уверены, что хотите удалить лимит с порядковым номером:<ContextName>{DELETE_STATIC_VALUE}</ContextName>
      </Row>
      <Group align='right'>
        <Button label='Отмена' theme='hollow' onClick={hideModal} />
        <Button label='Удалить' color='alert' onClick={deleteProperty} />
      </Group>
    </ConfirmDeleteContainer>
  );
};

const ConfirmDeleteContainer = styled.div`
  ${({ theme }) => theme.mixins.gothamRegular()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

const ContextName = styled.span`
  ${({ theme }) => theme.mixins.gothamSemibold()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

ConfirmDeleteContractLimit.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default ConfirmDeleteContractLimit;