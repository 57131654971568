import React from 'react';
import PropTypes from 'prop-types';
import FileLoader from "../FileLoader";
import styled, {css} from "styled-components";
import File from "./File";

const FilePreview = ({label, onLoad, files, onDelete}) => {
    return (
        <FilePreviewContainer>
            <Label>
                {label}
            </Label>
            <Files>
                {files && files.map(file => <File file={file} onDelete={onDelete} />)}
            </Files>
            <FileLoader label={'Прикрепить файлы'} onLoad={onLoad}/>
        </FilePreviewContainer>
    );
};

export const Label = styled.div`
  margin-bottom: 5px;
  ${({theme, error}) => theme.mixins.gothamBold({color: error ? theme.colors.alert : theme.colors.text})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

const FilePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Files = styled.div`
  margin-bottom: 10px;
`

FilePreview.propTypes = {
    onLoad: PropTypes.func.isRequired,
    files: PropTypes.array,
};

export default FilePreview;