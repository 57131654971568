import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {calculateColor} from "../../../utils/calculateColor";
import {useDispatch} from "react-redux";
import {hide} from "../../../features/anchorPopup/anchorPopupSlice";

const Item = ({item, onClick, align}) => {

    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(hide())
        onClick(item)
    }

    return (
        <ItemContainer onClick={handleClick} type={item.type} align={align}>
            {item.label}
        </ItemContainer>
    );
};

const ItemContainer = styled.div`
  ${({theme, type}) => theme.mixins.gothamSemibold({color: calculateColor(type)})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: ${({align}) => align === 'left' ? 'flex-start' : 'flex-end'};
  padding: 4px 10px;
  cursor: pointer;
  transition: background-color ${({theme}) => theme.transitions.normal};

  :hover {
    background-color: rgba(0,0,0,.05);
  }
`

Item.propTypes = {
    item: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    align: PropTypes.string,
};

export default Item;