import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Error = ({error}) => {
    return (
        <ErrorContainer>
            {error}
        </ErrorContainer>
    );
};

const ErrorContainer = styled.div`
  background-color: ${({theme}) => theme.colors.alert};
  padding: 7px 10px;
  border-radius: ${({theme}) => theme.other.borderRadiusNormal};
  ${({theme}) => theme.mixins.gothamRegular({size: '12px', color: '#fff'})};
  text-align: center;
`

Error.propTypes = {
    error: PropTypes.string.isRequired,
};

export default Error;