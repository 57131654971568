import React from 'react';

import { API_getContractEditAndDeleteUrl } from '../../utils/api';
import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteContract = ({ hideModal, data }) => {
  const contractId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getContractEditAndDeleteUrl(contractId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить контракт',
    DELETE_STATIC_VALUE: data.name.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteContract;