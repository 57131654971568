import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13.6C10.655 13.6 9.34033 13.2012 8.22208 12.454C7.10382 11.7068 6.23225 10.6448 5.71757 9.40225C5.2029 8.15971 5.06823 6.79246 5.33061 5.47339C5.59299 4.15432 6.24063 2.94267 7.19163 1.99168C8.14263 1.04068 9.35427 0.393043 10.6733 0.130663C11.9924 -0.131716 13.3597 0.00294633 14.6022 0.517622C15.8447 1.0323 16.9068 1.90387 17.6539 3.02212C18.4011 4.14038 18.8 5.45509 18.8 6.8C18.7978 8.60283 18.0807 10.3312 16.8059 11.606C15.5312 12.8808 13.8028 13.5979 12 13.6ZM12 2.4C11.1297 2.4 10.279 2.65806 9.55545 3.14154C8.83187 3.62501 8.26791 4.3122 7.93488 5.1162C7.60186 5.92019 7.51472 6.80488 7.6845 7.6584C7.85427 8.51192 8.27333 9.29592 8.88868 9.91127C9.50403 10.5266 10.288 10.9457 11.1416 11.1155C11.9951 11.2852 12.8798 11.1981 13.6838 10.8651C14.4878 10.532 15.1749 9.96809 15.6584 9.24451C16.1419 8.52094 16.4 7.67024 16.4 6.8C16.3978 5.6337 15.9336 4.51577 15.1089 3.69107C14.2842 2.86637 13.1663 2.40212 12 2.4Z" />
            <path d="M24 24H21.6V23.2C21.6 20.6539 20.5886 18.2121 18.7882 16.4117C16.9879 14.6114 14.5461 13.6 12 13.6C9.45392 13.6 7.01212 14.6114 5.21178 16.4117C3.41143 18.2121 2.4 20.6539 2.4 23.2V24H0V23.2C0 20.0174 1.26428 16.9651 3.51472 14.7147C5.76516 12.4642 8.8174 11.2 12 11.2C15.1826 11.2 18.2348 12.4642 20.4853 14.7147C22.7357 16.9651 24 20.0174 24 23.2V24Z" />
        </svg>

    );
};

export default UserIcon;