import React from 'react';

import { API_getReportsEditAndDeleteUrl } from '../../utils/api';

import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteUPRec = ({ hideModal, data }) => {
  const itemId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getReportsEditAndDeleteUrl(itemId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить репорт УП',
    DELETE_STATIC_VALUE: data.name.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteUPRec;