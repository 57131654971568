import React, {useCallback} from 'react';
import {useRouteMatch} from 'react-router';

import Button from '../Button';
import PageHeading from '../styled/PageHeading';
import {SearchWrapper} from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import PageContainer from '../styled/PageContainer';
import EditClaimWorksModal from '../Forms/EditClaimWorksModal';
import {getContractIdFromRoute} from '../../utils/getContractIdFromRoute';
import {API_getClaimWorks} from '../../utils/api';
import Loader from '../Loader';
import {setProperties} from '../../utils/setProperties';
import PageConstructor from '../PageConstructor';
import {searchIcons} from '../BankGuarantees/components';
import {prepareExportUrl} from '../../utils/prepareExportUrl';
import {prepareFiles} from "../../utils/prepareFiles";
import {useSelector} from "react-redux";

const tableFields = [
    {
        id: 1,
        name: 'date',
        label: 'Дата',
        props: {
            width: '100px',
        },
    },
    {
        id: 2,
        name: 'number',
        label: 'Номер требования',
        props: {
            width: '150px',
        },
    },
    {
        id: 3,
        name: 'cause',
        label: 'Причина выставления требования',
    },
    {
        id: 4,
        name: 'amount',
        label: 'Сумма требования',
        props: {
            width: '210px',
        },
    },
    {
        id: 5,
        name: 'status',
        label: 'Статус исполнения требования',
    },
    {
        id: 6,
        name: 'files',
        label: 'Файлы',
        props: {
            width: '100px',
        },
    },
];

const ClaimWorks = () => {
    const route = useRouteMatch();
    const contractId = getContractIdFromRoute(route);
    const {isArchive} = useSelector(state => state.breadcrumbs);

    const prepareRecievedItemsFunction = useCallback(allItems => {
        return allItems.map(properties => {
            const {
                id,
                amount,
                number,
                date,
                cause,
                status,
                files: filesData,
            } = properties;

            const filesList = prepareFiles(filesData);

            const propertiesItem = setProperties({
                id: {value: id},
                number: {value: number},
                cause: {value: cause},
                status: {value: status},
                date: {value: date?.date, isDate: true},
                amount: {value: amount, isPrice: true},
                files: { value: filesList, isFile: true },
            });

            return propertiesItem;
        });
    }, []);

    const requestUrl = API_getClaimWorks([{
        column: 'contract_id',
        operator: '=',
        value: contractId,
    }]);

    const getTemplate = (props) => {
        const {
            searchValue,
            handleSearchValue,
            isLoading,
            filteredRecievedItems,
            showModal,
            onSort,
            sort,
        } = props;

        return (
            <PageContainer>
                <PageHeading>
                    <SearchWrapper>
                        <Input icon={searchIcons} value={searchValue} placeholder={'Поиск претензионных работ'}
                               name='search' onChange={handleSearchValue}/>
                    </SearchWrapper>
                </PageHeading>
                <PageContent>
                    <Loader isLoading={isLoading}/>
                    {filteredRecievedItems.length ?
                        <Table disabled={isArchive} onSort={onSort} sort={sort}
                               fields={tableFields} data={filteredRecievedItems} modalType='claim-works'/> : null}
                </PageContent>
                <PageFooter>
                    {
                        !isArchive &&
                        <Button label={'Добавить новую претензионную работу'} onClick={showModal}/>
                    }
                </PageFooter>
            </PageContainer>
        );
    };

    const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, [
        ...tableFields,
        {name: 'numeric[][]', label: 'Стоимость этапа', withoutPrefix: true},
        {name: 'numeric[][]', label: 'Стоимость этапа', withoutPrefix: true},
    ]);

    const claimWorksProps = {
        FILTERED_SEARCH_PROPERTIES: [
            'cause',
            'status',
            'date',
            'amount',
            'number',
        ],

        isSortableTable: true,

        modalProps: {
            component: EditClaimWorksModal,
            name: 'new-claim',
            title: 'Новая претензионная работа',
            props: {contractId},
        },

        prepareRecievedItemsFunction,

        requestUrl,

        EXPORT_MODULE_NAME: 'claim-works',
        EXPORT_MODULE_URL,

        getTemplate,
    };

    return <PageConstructor {...claimWorksProps} />;
};

export default ClaimWorks;