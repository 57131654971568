import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import Button from '../../Button';
import Group from '../../Structure/Group';
import ModalConstructor from '../ModalConstructor';
import { API_getShipProperties, API_getShipPropertiesEditAndDeleteUrl, API_URL_SHIP_PROPERTIES_SET_POSITIONS } from '../../../utils/api';
import { prepareModalData } from '../../../utils/prepareModalData';

const EditCharacteristicsModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
  const characteristicId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getShipPropertiesEditAndDeleteUrl(characteristicId) :
      API_getShipProperties();

    return requestUrl;
  }, [isEditable, characteristicId]);

  const characteristicsFieldsData = {
    key: {
      required: true,
      value: item.key || '',
      errorText: '',
    },
    value: {
      required: true,
      value: item.value || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    }
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    return (
      <>
        <Row>
          <Input
            placeholder='Наименование характеристики'
            label='Наименование'
            onChange={handleForm}
            {...getFieldProps('key')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Значение характеристики'
            label='Значение'
            onChange={handleForm}
            {...getFieldProps('value')}
          />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: characteristicsFieldsData,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_SHIP_PROPERTIES_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditCharacteristicsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditCharacteristicsModal;