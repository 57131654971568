import { DEFAULT_EMPTY_PROPERTY } from './setProperties';
import { isDate } from './isDate';

export const prepareDate = (date) => {
    if (!isDate(date)) return DEFAULT_EMPTY_PROPERTY;

    const prepared = new Date(date).toLocaleString('ru', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    });

    return prepared + 'г.';
};

export const prepareDateToSee = (value) => {
    if (!isDate(value)) return value;

    const addZeroToValue = number => number < 10 ? '0' + number : number;

    const date = new Date(value);
    const preparedDate = date.getFullYear() + '-' + addZeroToValue(date.getMonth() + 1) + '-' + addZeroToValue(date.getDate());

    return preparedDate;
};