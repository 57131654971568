import React from 'react';

import { API_getContractEditAndDeleteUrl } from '../../utils/api';
import ArchiveModalConstructor from "../Forms/ArchiveModalConstructor";

const ConfirmArchiveContract = ({ hideModal, data }) => {
  const contractId = data.id.value;
  const isArchive = !data.is_archive.value

  const deleteModalProps = {
    hideModal,
    isArchive,

    ARCHIVE_REQUEST_URL: API_getContractEditAndDeleteUrl(contractId),
    ARCHIVE_STATIC_HINT: `Вы уверены, что хотите ${isArchive ? 'архивировать' : 'разархивировать'} контракт`,
    ARCHIVE_STATIC_VALUE: data.name.value,
  };

  return <ArchiveModalConstructor {...deleteModalProps} />
};

export default ConfirmArchiveContract;