import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Tab from "./Tab";
import {useDispatch} from "react-redux";

const Tabs = ({ tabs, onChange, active, name }) => {

    return (
        <TabsContainer>
            {tabs.map(tab => (
                <Tab key={tab.id} tab={tab} active={active === tab.id} onClick={onChange} />
            ))}
        </TabsContainer>
    );
};

const TabsContainer = styled.div`
    overflow: auto;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid ${({theme}) => theme.colors.border};
`

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tabs;