import SearchIcon from '../Icons/SearchIcon';

export const tableFields = [
  {
    id: 0,
    name: 'amount',
    label: 'Сумма',
    props: {
      width: '200px',
    },
  },
  {
    id: 1,
    name: 'date',
    label: 'Дата',
    props: {
      width: '100px',
    }
  },
  {
    id: 2,
    name: 'number',
    label: 'Номер',
    props: {
      width: '150px',
    }
  },
  {
    id: 3,
    name: 'validity',
    label: 'Срок действия',
    props: {
      width: '100px',
    }
  },
  {
    id: 4,
    name: 'issued_by',
    label: 'Кто выдал',
  },
  {
    id: 5,
    name: 'last_modified_date',
    label: 'Дата последнего изменения',
    props: {
      width: '120px',
    }
  },
  {
    id: 6,
    name: 'note',
    label: 'Примечание',
  },
];

export const searchIcons = [
  {
    id: 0,
    component: SearchIcon,
  },
];
