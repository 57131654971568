import React from 'react';
import styled from 'styled-components';
import Header from '../Header';
import PropTypes from "prop-types";

const Page = ({ children, role }) => {
  return (
    <PageContainer>
      <Header role={role} />
      <Content>
        {children}
      </Content>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
`

const Content = styled.div`
  //padding: 10px;
  height: ${({ theme }) => `calc(100% - ${theme.elements.headerHeight})`};
`

Page.propTypes = {
    role: PropTypes.string,
}

Page.defaultProps = {
    role: 'admin',
}

export default Page;