import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Item from "./Item";

const TableHeader = ({fields, controls, onSort, sort}) => {
    return (
        <HeaderContainer>
            <HeaderContent>
                <Items userSelect={onSort ? 'none' : 'auto'}>
                    {fields.map(item => <Item id={item.id} sort={(item.id === sort?.id) && sort} name={item.name} onSort={onSort} key={`table-header-${item.id}`} width={item.props?.width} item={item.component || item.label} noSortable={item.noSortable} />)}
                    {controls && <Fill />}
                </Items>
            </HeaderContent>
        </HeaderContainer>
    );
};

const Fill = styled.div`
  width: 40px;
  overflow: hidden;
  flex: 1 0 auto;
`

const HeaderContainer = styled.div`
  flex: 0 0 auto;
`

const HeaderContent = styled.div`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0 10px;
`

const Items = styled.div`
  display: flex;
  align-items: center;
  user-select: ${({ userSelect }) => userSelect};
`

TableHeader.propTypes = {
    fields: PropTypes.array.isRequired,
    controls: PropTypes.bool,
    onSort: PropTypes.func,
    sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default TableHeader;