import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Textarea from '../../Textarea';
import Group from '../../Structure/Group';
import Button from '../../Button';
import Input from '../../Input';
import { prepareModalData } from '../../../utils/prepareModalData';
import { API_getContractObjectsEditAndDeleteUrl, API_getContractObjects, API_URL_CONTRACT_OBJECTS_SET_POSITIONS } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';

const EditObjectModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
  const objectId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getContractObjectsEditAndDeleteUrl(objectId) :
      API_getContractObjects();

    return requestUrl;
  }, [isEditable, objectId]);

  const bankGuaranteeFieldsData = {
    registration_port: {
      required: false,
      value: item.registration_port || '',
      errorText: '',
    },
    delivery_port: {
      required: false,
      value: item.delivery_port || '',
      errorText: '',
    },
    building_number: {
      required: true,
      value: item.building_number || '',
      errorText: '',
    },
    contract_name: {
      required: false,
      value: item.contract_name || '',
      errorText: '',
    },
    price: {
      required: false,
      value: item.price || '',
      errorText: '',
    },
    name: {
      required: false,
      value: item.name || '',
      errorText: '',
    },
    note: {
      required: false,
      value: item.note || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    }
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    return (
      <>
        <Row>
          <Input
            type='number'
            placeholder='Введите номер'
            label='Строительный номер'
            onChange={handleForm}
            {...getFieldProps('building_number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите название объекта'
            label='Название'
            onChange={handleForm}
            {...getFieldProps('name')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите название контракта'
            label='Название контракта'
            onChange={handleForm}
            {...getFieldProps('contract_name')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Укажите порт поставки'
            label='Порт поставки'
            onChange={handleForm}
            {...getFieldProps('delivery_port')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Укажите порт регистрации'
            label='Порт регистрации'
            onChange={handleForm}
            {...getFieldProps('registration_port')}
          />
        </Row>
        <Row>
          <Input
            type='number'
            placeholder='Укажите стоимость'
            label='Стоимость'
            onChange={handleForm}
            {...getFieldProps('price')}
          />
        </Row>
        <Row>
          <Textarea placeholder='Примечание' onChange={handleForm} name='note' value={fieldsData.note.value} label='Примечание' />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  }

  const editModalProps = {
    fieldsData: bankGuaranteeFieldsData,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_CONTRACT_OBJECTS_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditObjectModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditObjectModal;