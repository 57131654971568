import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { ACCESS_TYPE_ADMIN, ACCESS_TYPE_WATCHER, routes } from './routes';
import Modal from './components/Modal';
import { checkIsAuthorized, checkIsAdminRole, checkIsWatcherRole } from './utils/auth';
import AnchorPopup from './components/AnchorPopup';
import './font-face.css'

const canAccessToCurrentRoute = route => {
  if (!route.access) return true;

  if (route.path === routes.login.path) return true;

  for (let i = 0; i < route.access.length; i++) {
    const accessType = route.access[i];

    if (accessType === ACCESS_TYPE_ADMIN && checkIsAdminRole()) return true;
    if (accessType === ACCESS_TYPE_WATCHER && checkIsWatcherRole()) return true;
  }

  return false;
};

const getRoutes = () => {
  const allRoutes = Object.values(routes).map(route => (
    <Route key={route.id} path={route.path} render={() => {
      if (!canAccessToCurrentRoute(route)) {
        return <Redirect to={checkIsAdminRole() ? routes.contracts.path : routes.watcherObjects.path} />
      }

      return <route.component/>
    }} exact={route.exact} />
  ));

  const defaultRoute = <Route key='default' path='/' render={() => {
    return checkIsAuthorized() ?
      checkIsWatcherRole() ?
        <Redirect to={routes.watcherObjects.path} /> : <Redirect to={routes.contracts.path} /> :
      <Redirect to={routes.login.path} />;
  }} />

  return [
    allRoutes,
    defaultRoute,
  ];
}

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback=''>
        <Switch>
          {getRoutes()}
        </Switch>
      </Suspense>
      <Modal />
      <AnchorPopup />
    </BrowserRouter>
  );
}

export default App;
