import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getAuthorizationHeader } from '../../../utils/auth';
import { fetchRequest } from '../../../utils/fetchRequest';
import Row from '../../Structure/Row';
import Group from '../../Structure/Group';
import Button from '../../Button';

const ArchiveModalConstructor = (props) => {
  const {
    hideModal,
    isArchive,
    ARCHIVE_REQUEST_URL,
    ARCHIVE_STATIC_HINT,
    ARCHIVE_STATIC_VALUE,
  } = props;

  const deleteProperty = useCallback(() => {
    const fetchDelete = async () => {
      const options = {
        method: 'PUT',
        headers: {
          'Authorization': getAuthorizationHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          is_archive: isArchive
        })
      };

      const url = ARCHIVE_REQUEST_URL;
      const { success } = await fetchRequest(url, options);

      if (success) {
        window.location.reload();
      }
    };

    fetchDelete();
  }, [ARCHIVE_REQUEST_URL]);

  return (
    <ConfirmDeleteContainer>
      <Row>
        {ARCHIVE_STATIC_HINT}:<ContextName>{ARCHIVE_STATIC_VALUE}</ContextName>
      </Row>
      <Group align='right'>
        <Button label='Отмена' theme='hollow' onClick={hideModal} />
        <Button label='Ок' color='alert' onClick={deleteProperty} />
      </Group>
    </ConfirmDeleteContainer>
  );
};

const ConfirmDeleteContainer = styled.div`
  ${({ theme }) => theme.mixins.gothamRegular()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

const ContextName = styled.span`
  ${({ theme }) => theme.mixins.gothamSemibold()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

ArchiveModalConstructor.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default ArchiveModalConstructor;