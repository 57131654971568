import React, {useCallback} from 'react';

import PageHeading from '../styled/PageHeading';
import {SearchWrapper} from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import PageContainer from '../styled/PageContainer';
import {searchIcons, tableFields} from './components';
import NewObjectCharacteristics from '../Forms/EditObjectCharacteristicsModal';
import {useRouteMatch} from 'react-router';
import {getObjectIdFromRoute} from '../../utils/getObjectIdFromRoute';
import {setProperties} from '../../utils/setProperties';
import {API_getAboutObject} from '../../utils/api';
import Loader from '../Loader';
import PageConstructor from '../PageConstructor';
import {getContractIdFromRoute} from '../../utils/getContractIdFromRoute';
import EditObjectCharacteristicsModal from '../Forms/EditObjectCharacteristicsModal';
import {prepareExportUrl} from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const AboutObject = () => {
    const route = useRouteMatch();
    const objectId = getObjectIdFromRoute(route);
    const contractId = getContractIdFromRoute(route);
    const {isArchive} = useSelector(state => state.breadcrumbs);

    const prepareRecievedItemsFunction = useCallback(allItems => {
        return allItems.map(item => {
            const {
                id,
                name,
                value,
            } = item;

            const propertiesItem = setProperties({
                id: {value: id},
                name: {value: name},
                value: {value: value},
            });

            return propertiesItem;
        });
    }, []);

    const requestUrl = API_getAboutObject([{
        column: 'contract_object_id',
        operator: '=',
        value: objectId,
    }]);

    const getTemplate = (props) => {
        const {
            searchValue,
            handleSearchValue,
            isLoading,
            filteredRecievedItems,
            showModal,
            onSort,
            sort,
        } = props;

        return (
            <PageContainer>
                <PageHeading>
                    <SearchWrapper>
                        <Input icon={searchIcons} value={searchValue} placeholder='Поиск по характеристикам'
                               name='search' onChange={handleSearchValue}/>
                    </SearchWrapper>
                </PageHeading>
                <PageContent>
                    <Loader isLoading={isLoading}/>
                    {filteredRecievedItems.length ?
                        <Table disabled={isArchive} onSort={onSort} sort={sort} fields={tableFields} data={filteredRecievedItems}
                               modalType='objects-about'/> : null}
                </PageContent>
                <PageFooter>
                    {
                        !isArchive && <Button label='Добавить новую характеристику' onClick={showModal}/>
                    }
                </PageFooter>
            </PageContainer>
        );
    };

    const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, tableFields);

    const aboutObjectProps = {
        FILTERED_SEARCH_PROPERTIES: [
            'name',
            'value',
        ],

        isSortableTable: true,

        modalProps: {
            component: EditObjectCharacteristicsModal,
            name: 'new-object-characteristics',
            title: 'Новая характеристика',
            props: {contractId, objectId},
        },

        prepareRecievedItemsFunction,

        requestUrl,

        EXPORT_MODULE_NAME: 'about',
        EXPORT_MODULE_URL,

        getTemplate,
    };

    return <PageConstructor {...aboutObjectProps} />;
};

export default AboutObject;