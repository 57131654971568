import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const TabsContent = ({content, id, children}) => {

    return (
        <TabsContentContainer>
            {children}
        </TabsContentContainer>
    );
};

const TabsContentContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`

TabsContent.propTypes = {

};

export default TabsContent;