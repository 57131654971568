import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import PresentationItem from "./PresentationItem";

const PresentationList = ({ list }) => {
    return (
        <PresentationListContainer>
            {list.map(item => <PresentationItem key={`item-${item.id}`} item={item} />)}
        </PresentationListContainer>
    );
};

const PresentationListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

PresentationList.propTypes = {
    list: PropTypes.array.isRequired,

};

export default PresentationList;