import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import CheckIcon from "../Icons/CheckIcon";

const Checkbox = ({ onChange, checked, render, id }) => {

  const ref = useRef()

  const handleChange = (event) => {
    event.preventDefault();

    onChange({
      id,
      checked: !ref.current.checked,
    })
  };

  return (
    <CheckboxContainer onClick={handleChange}>
      <input readOnly={true} type="checkbox" id={`checkbox-${id}`} ref={ref} checked={checked} />
      <CustomCheckbox htmlFor={`checkbox-${id}`}>
        {checked && <CheckIcon />}
      </CustomCheckbox>
      <label htmlFor={`checkbox-${id}`}>
        {render}
      </label>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  input {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }
  label {
    flex: 1 1 auto;
    ${({theme}) => theme.mixins.gothamRegular()};
    font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  }
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
`

const CustomCheckbox = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.other.borderRadiusNormal};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  margin-right: 10px;
`

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Checkbox;