import React from 'react';

const CheckIcon = () => {
    return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.9395 0.939453L15.0608 3.06077L6.00011 12.1214L0.939453 7.06077L3.06077 4.93945L6.00011 7.87879L12.9395 0.939453Z" fill="#37516D"/>
        </svg>
    );
};

export default CheckIcon;