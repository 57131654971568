import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Item from "./Item";

const List = ({list, align, onClick}) => {
    return (
        <ListContainer>
            {list.map(item => <Item key={item.id} item={item} onClick={onClick} align={align} />)}
        </ListContainer>
    );
};

const ListContainer = styled.div`
  border-radius: ${({theme}) => theme.other.borderRadiusNormal};
  background-color: #fff;
  padding: 4px 0;
  box-shadow: 0 10px 20px rgba(0,0,0,.15), 0 4px 10px rgba(0,0,0,.05);
`

List.propTypes = {
    list: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    align: PropTypes.string,
};

List.defaultProps = {
    align: 'right',
}

export default List;