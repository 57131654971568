import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setBreadcrumbsData } from '../../features/breadcrumbsReducer';
import {
  saveBuildingNumberToStorage,
  saveContractIdToStorage,
  saveContractIsArchiveToStorage,
  saveContractNameToStorage,
  saveContractNumberToStorage,
  saveObjectIdToStorage
} from '../../utils/breadcrumbsLocalStorage';
import { API_getContractEditAndDeleteUrl, API_getContractObjectsEditAndDeleteUrl } from '../../utils/api';
import { fetchRequest } from '../../utils/fetchRequest';
import { getObjectIdFromRoute } from '../../utils/getObjectIdFromRoute';

import Logo from '../Logo';
import Breadcrumbs from '../Breadcrumbs';
import Icon from '../Icon';
import ExitIcon from '../Icons/ExitIcon';
import ButtonsGroup from '../ButtonsGroup';
import Separator from '../Separator';
import { routes } from '../../routes';
import UserIcon from '../Icons/UserIcon';
import XLSIcon from '../Icons/XLSIcon';

import { getAuthorizationHeader, checkIsAdminRole, logoutUser } from '../../utils/auth';
import { DEFAULT_EMPTY_PROPERTY } from '../../utils/setProperties';

const Header = ({ role }) => {
  const history = useHistory();
  const exportState = useSelector(state => state.export);
  const [isExportProcessing, setExportProcessing] = useState(false);

  const handleExit = async () => {
    await logoutUser();

    history.push(routes.login.path);
  }

  const handleRouteChange = () => {
    history.push(routes.users.path);
  }

  const getExportInputData = useCallback(() => {
    const { exportUrl, exportName, exportData } = exportState;

    if (!exportUrl || !exportName) {
      return;
    }

    return {
      name: exportName,
      url: exportUrl,
      data: exportData,
    };
  }, [exportState]);

  const handleExportXLS = useCallback(() => {
    if (isExportProcessing) {
      alert('Дождитесь результата экспорта.');
      return;
    }

    const handleXLSRequest = async ({ url, name, data }) => {
      const options = {
        method: data ? 'POST' : 'GET',
        headers: {
          'Authorization': getAuthorizationHeader(),
          'Content-Type': data ? 'application/json' : 'text/plain; charset=UTF-8',
        },
      };

      if (data) {
        options.body = JSON.stringify(data);
      }

      document.body.classList.add('body_loading');

      setExportProcessing(true);

      const response = await fetch(url, options);

      setExportProcessing(false);

      if (!response.ok) {
        document.body.classList.remove('body_loading');

        alert('Произошла ошибка при попытке экспорта данных.');
        return;
      }

      const responseBuffer = await response.arrayBuffer();

      await fileDownload(responseBuffer, `${name}.xlsx`);

      document.body.classList.remove('body_loading');
    };

    const exportInputData = getExportInputData();

    if (exportInputData) {
      handleXLSRequest(exportInputData);
    } else {
      alert('Не найдено данных для экспорта текущей страницы.');
    }
  }, [getExportInputData, isExportProcessing]);

  const route = useRouteMatch();
  const dispatch = useDispatch();

  const state = useSelector(state => state.breadcrumbs);

  const contractId = getContractIdFromRoute(route);
  const objectId = getObjectIdFromRoute(route);

  const getInfoAboutContract = useCallback(async (contractId) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': getAuthorizationHeader(),
      },
    };

    dispatch(setBreadcrumbsData({
      contractId: DEFAULT_EMPTY_PROPERTY,
      contractName: DEFAULT_EMPTY_PROPERTY,
      contractNumber: DEFAULT_EMPTY_PROPERTY,
    }));

    const url = API_getContractEditAndDeleteUrl(contractId);

    const { response, success } = await fetchRequest(url, options);
    const responseData = response.data;

    if (success && responseData) {
      const { id: contractId, name: contractName, number: contractNumber, is_archive: isArchive } = responseData;

      saveContractIdToStorage(contractId);
      saveContractNameToStorage(contractName);
      saveContractNumberToStorage(contractNumber);
      saveContractIsArchiveToStorage(isArchive);


      dispatch(setBreadcrumbsData({
        contractId,
        contractName,
        contractNumber,
        isArchive,
      }));
    }
  }, []);

  const getInfoAboutObject = useCallback(async (objectId) => {
    const options = {
      method: 'GET',
      headers: {
        'Authorization': getAuthorizationHeader(),
      },
    };

    dispatch(setBreadcrumbsData({
      objectId: DEFAULT_EMPTY_PROPERTY,
      buildingNumber: DEFAULT_EMPTY_PROPERTY,
    }));

    const url = API_getContractObjectsEditAndDeleteUrl(objectId);

    const { response, success } = await fetchRequest(url, options);
    const responseData = response.data;

    if (success && responseData) {
      const { id: objectIdResponse, building_number: buildingNumber } = responseData;

      saveObjectIdToStorage(objectIdResponse);
      saveBuildingNumberToStorage(buildingNumber);

      dispatch(setBreadcrumbsData({
        objectId: objectIdResponse,
        buildingNumber,
      }));
    }
  }, []);

  useEffect(() => {
    if (objectId && state.objectId !== DEFAULT_EMPTY_PROPERTY && state.objectId !== objectId) {
      getInfoAboutObject(objectId)
    }
  }, [objectId, state.objectId]);

  useEffect(() => {
    if (contractId && state.contractId !== DEFAULT_EMPTY_PROPERTY && state.contractId !== contractId) {
      getInfoAboutContract(contractId)
    }
  }, [contractId, state.contractId]);

  return (
    <HeaderContainer>
      <LogoWrapper>
        <Link to={checkIsAdminRole() ? routes.contracts.path : routes.watcherObjects.path}>
          <Logo color='#333333' />
        </Link>
      </LogoWrapper>
      <Content>
        <BreadcrumbsWrapper>
          <Separator />
          <Breadcrumbs />
        </BreadcrumbsWrapper>
        <IconsWrapper>
          <ButtonsGroup />
          <Separator rightGap={10} />
          {role !== 'watcher' && (
            <>
              <Icon icon={XLSIcon} onClick={handleExportXLS} />
              <Icon icon={UserIcon} onClick={handleRouteChange} />
            </>
          )}
          <Icon icon={ExitIcon} onClick={handleExit} />
        </IconsWrapper>
      </Content>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  flex: 0 0 auto;
`

const LogoWrapper = styled.div`
  width: 80px;
  height: 30px;
  flex: 0 0 auto;
  margin-right: 100px;
  svg {
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
`

Header.propTypes = {
  role: PropTypes.string,
};

export default Header;