import React from "react";

import ContractCharacteristics from "../../components/ContractCharacteristics";
import BankGuarantees from "../../components/BankGuarantees";
import ContractLimits from "../../components/ContractLimits";
import ContractObjects from "../../components/ContractObjects";
import PaymentStages from "../../components/PaymentStages";
import KeyEvents from "../../components/KeyEvents";
import ClaimWorks from "../../components/ClaimWorks";
import JudicalWorks from "../../components/JudicalWorks";
import CashExecution from "../../components/CashExecution";
import Developing from "../../components/Developing";

export const TAB_CHARACTERISTICS = {
    key: 'ship-properties',
    name: 'Характеристики судна',
};

export const TAB_BANK_GUARANTEES = {
    key: 'bank-guarantees',
    name: 'Банковская гарантия',
};

export const TAB_LIMITS = {
    key: 'limits',
    name: 'ФАИП',
};

export const TAB_OBJECTS = {
    key: 'objects',
    name: 'Объекты',
};

export const TAB_PAYMENT_STAGES = {
    key: 'payment-stages',
    name: 'Этапы платежей',
};

export const TAB_KEY_EVENTS = {
    key: 'key-events',
    name: 'Ключевые события',
};

export const TAB_CLAIM_WORKS = {
    key: 'claim-works',
    name: 'Претензионная работа',
};

export const TAB_JUDICAL_WORKS = {
    key: 'judical-works',
    name: 'Судебная работа',
};

export const TAB_CASH_EXECUTION = {
    key: 'cash-execution',
    name: 'Кассовое исполнение',
};

export const TAB_DEVELOPING = {
    key: 'developing',
    name: 'Освоение',
};

export const tabsList = [
    {
        id: 0,
        label: TAB_CHARACTERISTICS.name,
        key: TAB_CHARACTERISTICS.key,
        route: TAB_CHARACTERISTICS.key,
        component: <ContractCharacteristics />
    },
    {
        id: 1,
        label: TAB_BANK_GUARANTEES.name,
        key: TAB_BANK_GUARANTEES.key,
        route: TAB_BANK_GUARANTEES.key,
        component: <BankGuarantees />
    },
    {
        id: 2,
        label: TAB_LIMITS.name,
        key: TAB_LIMITS.key,
        route: TAB_LIMITS.key,
        component: <ContractLimits />
    },
    {
        id: 3,
        label: TAB_OBJECTS.name,
        key: TAB_OBJECTS.key,
        route: TAB_OBJECTS.key,
        component: <ContractObjects />
    },
    {
        id: 4,
        label: TAB_PAYMENT_STAGES.name,
        key: TAB_PAYMENT_STAGES.key,
        route: TAB_PAYMENT_STAGES.key,
        component: <PaymentStages />
    },
    {
        id: 5,
        label: TAB_KEY_EVENTS.name,
        key: TAB_KEY_EVENTS.key,
        route: TAB_KEY_EVENTS.key,
        component: <KeyEvents />
    },
    {
        id: 6,
        label: TAB_CLAIM_WORKS.name,
        key: TAB_CLAIM_WORKS.key,
        route: TAB_CLAIM_WORKS.key,
        component: <ClaimWorks />
    },
    {
        id: 7,
        label: TAB_JUDICAL_WORKS.name,
        key: TAB_JUDICAL_WORKS.key,
        route: TAB_JUDICAL_WORKS.key,
        component: <JudicalWorks />
    },
    {
        id: 8,
        label: TAB_CASH_EXECUTION.name,
        key: TAB_CASH_EXECUTION.key,
        route: TAB_CASH_EXECUTION.key,
        component: <CashExecution />
    },
    {
        id: 9,
        label: TAB_DEVELOPING.name,
        key: TAB_DEVELOPING.key,
        route: TAB_DEVELOPING.key,
        component: <Developing />
    },
]
