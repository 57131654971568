import React from 'react';
import PropTypes from 'prop-types';

const SettingsIcon = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.424 20H7.58277L6.17141 16.813L5.31927 16.3133L1.90407 16.6914L-0.0198975 13.3153L1.9773 10.4997V9.50034L-0.0198975 6.69142L1.90407 3.31533L5.31927 3.6867L6.17141 3.18704L7.55614 0H11.3974L12.8287 3.18704L13.6809 3.6867L17.0961 3.30858L19.02 6.68467L16.9896 9.50034V10.4997L19.02 13.3086L17.0961 16.6847L13.6809 16.3133L12.8287 16.813L11.424 20ZM8.88094 17.9743H10.1192L11.2443 15.4018L13.2415 14.2404L16.0109 14.5442L16.6301 13.4571L14.9857 11.1749V8.83862L16.6301 6.55638L16.0109 5.46928L13.2415 5.77313L11.2443 4.61175L10.1192 2.02566H8.88094L7.75585 4.59824L5.75866 5.75962L2.98921 5.45577L2.3834 6.55638L4.02775 8.83862V11.1614L2.3834 13.4436L3.00253 14.5307L5.77197 14.2269L7.76917 15.3882L8.88094 17.9743Z" />
            <path d="M9.50015 13.7138C8.77597 13.7138 8.06805 13.496 7.46592 13.0879C6.86378 12.6799 6.39447 12.0999 6.11734 11.4213C5.84021 10.7427 5.7677 9.99597 5.90898 9.27558C6.05026 8.55519 6.39899 7.89347 6.91106 7.3741C7.42313 6.85473 8.07556 6.50103 8.78582 6.35774C9.49609 6.21444 10.2323 6.28799 10.9014 6.56907C11.5704 6.85015 12.1423 7.32614 12.5446 7.93686C12.9469 8.54758 13.1617 9.26558 13.1617 10.0001C13.1599 10.9845 12.7736 11.928 12.0873 12.6241C11.401 13.3202 10.4707 13.712 9.50015 13.7138ZM9.50015 8.31204C9.17098 8.31204 8.8492 8.41104 8.5755 8.59652C8.3018 8.78201 8.08848 9.04565 7.96251 9.3541C7.83654 9.66255 7.80358 10.002 7.8678 10.3294C7.93202 10.6569 8.09053 10.9576 8.32329 11.1937C8.55605 11.4298 8.85261 11.5906 9.17546 11.6557C9.49831 11.7208 9.83295 11.6874 10.1371 11.5596C10.4412 11.4319 10.7011 11.2155 10.884 10.9379C11.0669 10.6603 11.1645 10.334 11.1645 10.0001C11.1645 9.55239 10.9891 9.12303 10.677 8.80645C10.3649 8.48988 9.94156 8.31204 9.50015 8.31204Z" />
        </svg>

    );
};

SettingsIcon.propTypes = {

};

export default SettingsIcon;