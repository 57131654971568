import React, { useCallback } from 'react';
import styled from 'styled-components';

import { API_getShipProperties } from '../../utils/api';
import { useRouteMatch } from 'react-router-dom';
import EditCharacteristicsModal from '../Forms/EditCharacteristicsModal';
import { searchIcons, shipPropertiesTableFields as tableFields } from './components';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setProperties } from '../../utils/setProperties';
import PageConstructor from '../PageConstructor';
import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Loader from '../Loader';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import Table from '../Table';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const ContractCharacteristics = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback((shipProperties) => {
    return shipProperties.map(properties => {
      const { id, key, value } = properties;

      const propertiesItem = setProperties({
        id: { value: id },
        key: { value: key },
        value: { value },
      });

      return propertiesItem;
    });
  }, []);

  const requestUrl = API_getShipProperties([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onRowClick,
      onSort,
      sort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск характеристик' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} onSort={onSort} sort={sort} onRowClick={onRowClick} fields={tableFields} data={filteredRecievedItems} modalType='characteristics' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label='Добавить новую характеристику' onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, tableFields);

  const charasteristicsProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'key',
      'value',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditCharacteristicsModal,
      name: 'new-characteristics',
      title: 'Новая характеристика',
      props: { contractId },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    getTemplate,

    EXPORT_MODULE_URL,
    EXPORT_MODULE_NAME: 'ship-properties',
  };

  return <PageConstructor {...charasteristicsProps} />;
};

const SearchWrapper = styled.div`
    min-width: 250px;
    width: 450px;
    display: flex;
    align-items: center;
`;

export default ContractCharacteristics;