import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import Icon from "../Icon";
import CopyIcon from "../Icons/CopyIcon";
import {copyTextToClipboard} from "../../utils/copyTextToClipboard";

const DataField = ({title, text, width, theme, copy}) => {

    const handleCopy = (e, text) => {
        e.stopPropagation()
        e.preventDefault()
        copyTextToClipboard(text)
    }

    return (
        <DataFieldContainer width={width} localTheme={theme}>
            <ContentWrapper>
                <DataTitle>
                    {title}
                </DataTitle>
                <DataText>
                    {text}
                    {copy && (
                        <IconWrapper>
                            <Icon onClick={(e) => handleCopy(e, text)} icon={CopyIcon} padding={0} size={20} />
                        </IconWrapper>
                    )}
                </DataText>
            </ContentWrapper>
        </DataFieldContainer>
    );
};

const IconWrapper = styled.div`
  //margin-top: 15px;
  margin-left: 10px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DataTitle = styled.div`
  ${({theme}) => theme.mixins.gothamBold({color: theme.colors.secondary})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

const DataText = styled.div`
  ${({theme}) => theme.mixins.gothamSemibold()};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  display: flex;
  align-items: center;
`

const DataFieldContainer = styled.div`
  display: flex;
  flex: ${({width}) => !width && '1 0 100%'};
  width: ${({width}) => width && `${width}px`};
  align-items: flex-end;
  margin: 6px 30px 6px 0;
  :last-child {
    margin-right: 0;
  }
  
  ${({localTheme}) => localTheme === 'light' && css`
    ${DataTitle} {
      color: #fff;
    }
    ${DataText} {
      color: #fff;
    }
  `};
`

DataField.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    width: PropTypes.number,
    theme: PropTypes.oneOf(['light', 'dark']),
};

DataField.defaultProps = {
    theme: 'dark',
}

export default DataField;