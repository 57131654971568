import React from 'react';

import { API_getGroupsEditAndDeleteUrl } from '../../utils/api';

import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteObjectGroup = (props) => {
  const { hideModal, data } = props;
  const { item } = data;
  const itemId = item.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getGroupsEditAndDeleteUrl(itemId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить группу',
    DELETE_STATIC_VALUE: item.text.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteObjectGroup;