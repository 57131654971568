import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import Column from '../../Structure/Column';
import Textarea from '../../Textarea';
import Group from '../../Structure/Group';
import Button from '../../Button';
import { API_URL_USERS, API_URL_USERS_SET_POSITIONS, API_getUserEditAndDeleteUrl } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import Select from '../../Select';

const prepareEditData = (props) => {
  const data = props.data || {};
  const item = data.item || {};
  const isEditable = data.isEditable;
  const hideModal = props.hideModal;
  const appendAfterId = props.appendAfterId;
  const allIds = props.allIds;

  const prepareItem = item => {
    return Object.keys(item).reduce((result, key) => {
      const { value, isDate } = item[key];

      let preparedValue = value;

      if (isDate) {
        preparedValue = new Date(preparedValue);

      }

      result[key] = preparedValue;

      return result;
    }, {});
  };

  return {
    item: prepareItem(item),
    appendAfterId,
    isEditable,
    hideModal,
    allIds
  };
};

const EditUserModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds } = prepareEditData(props);
  const itemId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ? API_getUserEditAndDeleteUrl(itemId) : API_URL_USERS;

    return requestUrl;
  }, [isEditable, itemId]);

  const userFieldsData = {
    email: {
      required: true,
      value: item.email || '',
      errorText: '',
    },
    fullname: {
      required: true,
      value: item.fullname || '',
      errorText: '',
    },
    password: {
      required: true,
      value: item.password || '',
      errorText: '',
    },
    password_confirmation: {
      required: true,
      value: item.password_confirmation || '',
      errorText: '',
    },
    role: {
      required: true,
      value: item.role || 0,
      errorText: '',
      isSelect: true,
    },
    company: {
      required: false,
      value: item.company || '',
      errorText: '',
    },
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    const selectRoleList = [
      {
        id: 0,
        name: 'role',
        label: 'Пользователь',
        value: 0,
      },
      {
        id: 1,
        name: 'role',
        label: 'Администратор',
        value: 1,
      },
    ];

    const prepareRoleValue = (value) => {
      return selectRoleList.find(({ value: roleValue }) => roleValue === value).label;
    };

    return (
      <>
        <Row>
          <Input
            label='Логин/E-mail'
            onChange={handleForm}
            placeholder='Введите логин пользователя'
            {...getFieldProps('email')}
          />
        </Row>
        <Row direction='row'>
          <Column>
            <Input
              label='ФИО'
              onChange={handleForm}
              placeholder='Введите ФИО пользователя'
              {...getFieldProps('fullname')}
            />
          </Column>
        </Row>
        <Row direction='row'>
          <Column>
            <Input
              label='Пароль'
              onChange={handleForm}
              placeholder='Пароль пользователя'
              type='password'
              {...getFieldProps('password')}
            />
          </Column>
          <Column>
            <Input
              label='Пароль повторно'
              onChange={handleForm}
              placeholder='Пароль пользователя повторно'
              type='password'
              {...getFieldProps('password_confirmation')}
            />
          </Column>
        </Row>
        <Row direction='row'>
          <Column>
            <Select value={prepareRoleValue(fieldsData.role.value)} name='role' required={true} onChange={handleForm} label='Роль' list={selectRoleList} />
          </Column>
        </Row>
        <Row>
          <Textarea
            placeholder='Примечание'
            onChange={handleForm}
            label='Примечание'
            {...getFieldProps('company')}
          />
        </Row>
        <Group align='right'>
          <Button
            label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button
            label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editUserModalProps = {
    fieldsData: userFieldsData,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_USERS_SET_POSITIONS,
  };

  return <ModalConstructor {...editUserModalProps} />
};

EditUserModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditUserModal;