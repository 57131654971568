import React from 'react';

import { API_getJudicialWorksEditAndDeleteUrl } from '../../utils/api';

import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteJudicalWork = ({ hideModal, data }) => {
  const itemId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getJudicialWorksEditAndDeleteUrl(itemId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить судебную работу',
    DELETE_STATIC_VALUE: data.statement_claim_name.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteJudicalWork;