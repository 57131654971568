import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Separator = ({leftGap, rightGap}) => {
    return (
        <SeparatorContainer leftGap={leftGap} rightGap={rightGap}>
            <svg width="5" height="22" viewBox="0 0 5 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M3 4.33335C3 3.54393 2.64931 2.66076 1.75671 1.66898L3.24329 0.331055C4.35069 1.5615 5 2.90056 5 4.33335C5 5.76615 4.35069 7.10521 3.24329 8.33565C2.35069 9.32743 2 10.2106 2 11C2 11.7894 2.35069 12.6726 3.24329 13.6644C4.35069 14.8948 5 16.2339 5 17.6667C5 19.0995 4.35069 20.4385 3.24329 21.669L1.75671 20.3311C2.64931 19.3393 3 18.4561 3 17.6667C3 16.8773 2.64931 15.9941 1.75671 15.0023C0.649307 13.7719 4.2469e-07 12.4328 4.87319e-07 11C5.49949e-07 9.56722 0.649308 8.22816 1.75671 6.99772C2.64931 6.00594 3 5.12278 3 4.33335Z" fill="black"/>
            </svg>
        </SeparatorContainer>
    );
};

const SeparatorContainer = styled.div`
  margin-left: ${({leftGap}) => leftGap}px;
  margin-right: ${({rightGap}) => rightGap}px;
`

Separator.propTypes = {
    leftGap: PropTypes.number,
    rightGap: PropTypes.number,
};

Separator.defaultProps = {
    leftGap: 20,
    rightGap: 20,
}

export default Separator;