import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

const Tab = ({ tab, active, onClick }) => {

  const history = useHistory()

  const handleClick = () => {
    // Ничего не перерендериваем кликая по активному табику
    if (active) return;

    history.push(tab.route)
    onClick(tab)
  }

  return (
    <TabContainer active={active} onClick={handleClick}>
      {tab.label}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  margin-right: 30px;
  min-height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  ${({ theme }) => theme.mixins.gothamBold()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
  color: ${({ theme }) => theme.colors.primary};
  opacity: .4;
  transition: opacity ${({ theme }) => theme.transitions.normal};
  ${({ active }) => active && css`
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
    opacity: 1 !important;
  `};
  :hover {
    opacity: .7;
  }
  :last-child {
    margin-right: 0;
  }
`

Tab.propTypes = {
  tab: PropTypes.object.isRequired,
  active: PropTypes.bool,
};

Tab.defaultProps = {
  active: false,
}

export default Tab;