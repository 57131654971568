import React from 'react';
import styled from 'styled-components';

import { DEFAULT_EMPTY_PROPERTY } from '../../../../../utils/setProperties';
import { preparePrice } from '../../../../../utils/preparePrice';
import { prepareDate } from '../../../../../utils/dates';
import FilesList from '../../../../FilesList';
import PresentationList from '../../../../PresentationList';

const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0);

const TableCell = React.memo(({ item, row }) => {
  const { value, isDate, isPrice, isSelect, isFile, isList, avaliableSelectValues } = row[item.name] || {};

  let displayedValue = value;

  if (isPrice && isNumber(displayedValue)) {
    displayedValue = <Inline>{preparePrice(displayedValue)}</Inline>;

  } else if (isDate && displayedValue) {
    displayedValue = prepareDate(displayedValue);

  } else if (isSelect) {
    displayedValue = avaliableSelectValues[displayedValue];

  } else if (isFile) {
    displayedValue = displayedValue.length ? <FilesList list={displayedValue} /> : null

  } else if (isList) {
    displayedValue = displayedValue.length ? <PresentationList list={displayedValue} /> : null
  }

  displayedValue = displayedValue || DEFAULT_EMPTY_PROPERTY;

  return (
    <TableCellContainer width={item.props?.width || '100%'}>
      {displayedValue}
    </TableCellContainer>
  );
});

export const Inline = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TableCellContainer = styled.div`
  width: ${({ width }) => width};
  flex: ${({ width }) => width === '100%' ? '1 1 auto' : '0 0 auto'};
  ${({ theme }) => theme.mixins.gothamRegular()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
  margin-right: 30px;
  min-height: 40px;
  padding: 3px 0;
  display: flex;
  align-items: center;
  :last-child {
    margin-right: 0;
  }
`

TableCell.propTypes = {

};

export default TableCell;