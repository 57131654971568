import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import EditKeyEventModal from '../Forms/EditKeyEventModal';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setProperties } from '../../utils/setProperties';
import { API_getKeyEvents } from '../../utils/api';
import { tableFields } from './components';
import PageConstructor from '../PageConstructor';
import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Loader from '../Loader';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import { searchIcons } from '../BankGuarantees/components';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const KeyEvents = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback(allItems => {
    return allItems.map(properties => {
      const {
        id,
        note,
        name,
        rationale,
        responsible,
        start_date,
        expiration_date_planned,
        expiration_date_real,
      } = properties;

      const propertiesItem = setProperties({
        id: { value: id },
        name: { value: name },
        note: { value: note },
        rationale: { value: rationale },
        responsible: { value: responsible },
        start_date: { value: start_date?.date, isDate: true },
        expiration_date_planned: { value: expiration_date_planned?.date, isDate: true },
        expiration_date_real: { value: expiration_date_real?.date, isDate: true },
      });

      return propertiesItem;
    });
  }, []);

  const requestUrl = API_getKeyEvents([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      sort,
      onSort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск ключевых событий' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} sort={sort} onSort={onSort} fields={tableFields} data={filteredRecievedItems} modalType='key-event' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label={'Добавить новое событие'} onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, tableFields);

  const keyEventsProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'name',
      'note',
      'rationale',
      'start_date',
      'responsible',
      'expiration_date_planned',
      'expiration_date_real',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditKeyEventModal,
      name: 'new-key-event',
      title: 'Новое событие',
      props: { contractId },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'key-events',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...keyEventsProps} />;
};

export default KeyEvents;