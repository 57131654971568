import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';

import TableCell from './TableCell';
import Icon from '../../../Icon';
import SettingsIcon from '../../../Icons/SettingsIcon';
import { useAnchorPopup } from '../../../AnchorPopup/useAnchorPopup';
import List from '../../../List';
import { useModal } from '../../../../hooks/useModal';
import PlusIcon from '../../../Icons/PlusIcon';
import { getObjectIdFromRoute } from '../../../../utils/getObjectIdFromRoute';
import { getAllModalPropsByModalType } from '../../../../utils/getAllModalPropsByModalType';
import { getContractIdFromRoute } from '../../../../utils/getContractIdFromRoute';
import { getGroupIdFromRoute } from '../../../../utils/getGroupIdFromRoute';
import { getUpIdFromRoute } from '../../../../utils/getUpIdFromRoute';

const TableRow = (props) => {
  const { item, fields, rowIndex, controls, onRowClick, controlsList, addBetween, modalType, modalProps, allIds, lastItem, disabled } = props;
  const { _systemSettings } = item;

  const colored = _systemSettings?.colored;

  const route = useRouteMatch();

  const contractId = getContractIdFromRoute(route);
  const objectId = getObjectIdFromRoute(route);
  const groupId = getGroupIdFromRoute(route);
  const constructionCertificateId = getUpIdFromRoute(route);

  const [ROW_INDEX_INITIAL_STATIC] = useState(rowIndex);

  const listItems = [
    {
      id: 0,
      label: 'Редактировать',
    },
    {
      id: 1,
      label: 'Удалить',
      type: 'alert',
      action: 'delete',
    }
  ];

  const {
    editModalProps,
    betweenAddModalProps,
    deleteModalProps,
  } = getAllModalPropsByModalType({
    modalType,
    modalProps,

    item,

    contractId,
    objectId,
    groupId,
    constructionCertificateId,

    rowIndex,

    ROW_INDEX_INITIAL_STATIC,
  });

  const [showDeleteModal] = useModal(deleteModalProps)
  const [showBetweenAddModal] = useModal(betweenAddModalProps)
  const [showEditModal] = useModal(editModalProps)

  const [showAnchorPopup] = useAnchorPopup({
    component: List, props: {
      list: [...controlsList, ...listItems], onClick: (el) => {
        if (el.onClick) {
          el.onClick(item)
        } else if (el.action === 'delete') {
          showDeleteModal()
        } else {
          showEditModal()
        }
      }
    }
  })

  const handleRowClick = () => {
    onRowClick(item)
  }

  const handleAddBetween = (e, id) => {
    e.preventDefault()
    e.stopPropagation()

    showBetweenAddModal({ data: { appendAfterId: id, allIds } })
  }

  const getControlsContent = () => {
    return controls && (
      <>
        <Actions>
          <Icon icon={SettingsIcon} onClick={(e) => {
            showAnchorPopup(e)
          }} />
        </Actions>
      </>
    );
  };

  const getAddBetweenContent = () => {
    return addBetween && !lastItem && (
      <AddBetween onClick={(e) => handleAddBetween(e, item.id.value)}>
        <Icon icon={PlusIcon} size={30} />
      </AddBetween>
    );
  };

  const getTableFieldsContent = () => {
    return fields.map((element, index) => (
      <TableCell
        key={`row-cell-${item.id.value}-${index}`}
        item={element}
        row={item}
      />
    ));
  };

  const getTableRow = () => {
    return (
      <TableRowContainer colored={colored} cursorPointer={!!onRowClick} onClick={onRowClick && handleRowClick}>
        {getTableFieldsContent()}
        {
          !disabled && getControlsContent()
        }
        {
            !disabled && getAddBetweenContent()
        }
      </TableRowContainer>
    );
  };

  const tableRowContent = getTableRow();

  return tableRowContent;
};

export const AddBetween = styled.div`
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-15px);
  bottom: -15px;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 10px rgba(0,0,0,.15), 0 4px 4px rgba(0,0,0,.05);
  border-radius: ${({ theme }) => theme.other.borderRadiusNormal};
  cursor: pointer;
  display: none;
  z-index: 1;
`

const Actions = styled.div`
  opacity: 0;
  transition: opacity ${({ theme }) => theme.transitions.normal};
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex: 1 0 auto;
  display: flex;
  justify-content: flex-end;
`

const TableRowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: ${({ colored }) => colored ? 'rgba(255, 177, 85, 0.3)' : ''};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  transition: background-color ${({ theme }) => theme.transitions.normal};
  cursor: ${({ cursorPointer }) => cursorPointer && 'pointer'};
  :hover {
    background-color: rgba(0,0,0,.03);
    ${Actions} {
      opacity: 1;
    }
    ${AddBetween} {
      display: flex;
    }
  }
`

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  controls: PropTypes.bool,
  onRowClick: PropTypes.func,
  controlsList: PropTypes.array,
  addBetween: PropTypes.bool,
};

TableRow.defaultProps = {
  controlsList: [],
}

export default TableRow;