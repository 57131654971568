import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { getAuthorizationHeader } from '../../../utils/auth';
import { fetchRequest } from '../../../utils/fetchRequest';
import { isInt } from '../../../utils/isInt';
import { isFloat } from '../../../utils/isFloat';
import { prepareDateToSee } from '../../../utils/dates';
import { API_URL_FILES, API_URL_LOAD_FILES } from '../../../utils/api';
import { MAX_PRICE_AVALIABLE_VALUE } from '../../../const/MAX_PRICE_AVALIABLE_VALUE';

const ModalConstructor = (props) => {
  const {
    getTemplate,
    isEditable,
    hideModal,
    fieldsData: fieldsDataProps,
    appendAfterId,
    getFetchRequestUrl,
    getRequestMethod,
    getFetchRequestData: getFetchRequestDataProps,

    SAVE_FILES_MODEL_TYPE,

    ALL_OBJECT_IDS,
    SET_POSITIONS_URL,
  } = props;

  const [fieldsData, setFieldsData] = useState(fieldsDataProps);

  const DEFAULT_ERROR_TEXT = 'Поле обязательно для ввода';
  const submitButtonText = isEditable ? 'Сохранить' : 'Создать';

  const reloadLocation = useCallback(() => {
    window.location.reload();
  }, []);

  const getFetchRequestData = useCallback(({ fieldsData }) => {
    let requestData = null;

    const dataJSON = Object.keys(fieldsData).reduce((result, property) => {
      const fieldsItem = fieldsData[property];
      const isDate = fieldsItem.isDate;
      const isFile = fieldsItem.isFile;

      const needToSendAlways = fieldsItem.needToSendAlways;

      let value = fieldsItem.value;

      if (isDate) {
        value = prepareDateToSee(value);
      }

      let needToAddField = (fieldsItem.value !== fieldsDataProps[property].value) || needToSendAlways;

      if (isFile) {
        needToAddField = false;
      }

      // Добавляем только изменившиеся данные
      if (needToAddField) {
        result[property] = value;
      }

      return result;
    }, {});

    requestData = JSON.stringify(dataJSON);

    return requestData;
  }, [fieldsData, fieldsDataProps]);

  const getFetchRequestDataFn = getFetchRequestDataProps || getFetchRequestData;

  const handlErrorMessages = useCallback(messages => {
    const newFieldsData = Object.keys(fieldsData).reduce((result, key) => {
      const errorText = Array.isArray(messages[key]) && messages[key].length ?
        messages[key].map(text => text).join('') : '';

      result[key] = {
        ...fieldsData[key],
        errorText,
      };

      return result;
    }, {});

    setFieldsData(newFieldsData);
  }, [fieldsData, setFieldsData]);

  const getAllItemIdsWithCreatedId = useCallback((createdItemId) => {
    const newAllIds = [];

    for (let i = 0; i < ALL_OBJECT_IDS.length; i++) {
      const currentId = ALL_OBJECT_IDS[i];

      newAllIds.push(currentId);

      if (currentId === appendAfterId) {
        newAllIds.push(createdItemId);
      }
    }

    return newAllIds;
  }, [ALL_OBJECT_IDS, appendAfterId]);

  const updateIdsPositionRequest = useCallback(async (createdItemId) => {
    const requestAllIds = getAllItemIdsWithCreatedId(createdItemId);

    const data = JSON.stringify({
      ids: requestAllIds
    });

    const options = {
      method: 'POST',
      headers: {
        'Authorization': getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
      body: data,
    };

    const url = SET_POSITIONS_URL;

    await fetchRequest(url, options);
  }, [getAllItemIdsWithCreatedId]);

  const handleForm = useCallback(({ name, value }) => {
    const newFieldsData = Object.keys(fieldsData).reduce((result, key) => {
      result[key] = { ...fieldsData[key] };
      return result;
    }, {});

    newFieldsData[name].errorText = '';
    newFieldsData[name].value = value;

    setFieldsData(newFieldsData);
  }, [setFieldsData, fieldsData]);

  const handleFileInput = useCallback(({ files, itemId, fileKeyInState }) => {
    const addedFileData = [...files].map((file, index) => {
      return {
        id: index,
        name: file.name,
        label: file.name,
        file,
      };
    });

    const newFieldsData = {
      ...fieldsData,
      [fileKeyInState]: {
        ...fieldsData[fileKeyInState],
        value: [...fieldsData[fileKeyInState].value, ...addedFileData],
      },
    };

    setFieldsData(newFieldsData);
  }, [setFieldsData, fieldsData]);

  const handleFileDelete = useCallback(({ file, fileKeyInState }) => {
    const newFilesArray = [];

    for (let i = 0, l = fieldsData[fileKeyInState].value.length; i < l; i++) {
      const currentFile = fieldsData[fileKeyInState].value[i];

      if (currentFile.id !== file.id) {
        newFilesArray.push(currentFile);
      }
    };

    const newFieldsData = {
      ...fieldsData,
      [fileKeyInState]: {
        ...fieldsData[fileKeyInState],
        value: newFilesArray,
      },
    };

    setFieldsData(newFieldsData);
  }, [setFieldsData, fieldsData]);

  const saveFilesAfterSuccessedResponse = useCallback(async (createdItemId) => {
    const files = fieldsData.files.value || [];

    const addedNewFiles = files.filter(({ file }) => Boolean(file));

    const startedFileIds = (fieldsDataProps.files.value || []).map(({ id }) => id);
    const currentFileIds = files.map(({ id }) => id);

    const deletedFileIds = startedFileIds.filter(startedFileId => !currentFileIds.includes(startedFileId));

    const fetchFileAdd = async () => {
      const requestData = new FormData();

      requestData.append('model_type', SAVE_FILES_MODEL_TYPE);
      requestData.append('model_id', createdItemId);

      [...addedNewFiles].forEach((file, fileIndex) => {
        requestData.append(`files[${fileIndex}][file]`, file.file);
        requestData.append(`files[${fileIndex}][name]`, file.name);
      });

      const options = {
        method: 'POST',
        headers: {
          'Authorization': getAuthorizationHeader(),
        },
        body: requestData,
      };

      const url = API_URL_LOAD_FILES;
      const { response, success } = await fetchRequest(url, options);

      if (success && response) {
        const responseItems = response.data || [];

        if (responseItems.length !== addedNewFiles.length) {
          alert('Не удалось загрузить все файлы');
        }
      }
    };

    const fetchFileDelete = async () => {
      const requestData = {
        ids: deletedFileIds,
      };

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthorizationHeader(),
        },
        body: JSON.stringify(requestData),
      };

      const url = API_URL_FILES;

      await fetchRequest(url, options);
    };

    deletedFileIds.length && await fetchFileDelete();
    addedNewFiles.length && await fetchFileAdd();

    reloadLocation();
  }, [fieldsData, SAVE_FILES_MODEL_TYPE]);

  const fetchDataRequest = useCallback(async () => {
    const requestData = getFetchRequestDataFn({ isEditable, fieldsData });
    const requestMethod = getRequestMethod ? getRequestMethod() : (isEditable ? 'PUT' : 'POST');

    const options = {
      method: requestMethod,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthorizationHeader(),
      },
      body: requestData,
    };

    const url = getFetchRequestUrl(isEditable);

    const { success, response } = await fetchRequest(url, options);
    const messages = response?.error?.messages;
    const createdItemId = response?.data?.id;

    if (messages) {
      handlErrorMessages(messages);
      return;
    }

    if (success) {
      // Если передано SAVE_FILES_MODEL_TYPE — сохраняем файлы, выбранные пользователем по ID из ответа сервера
      if (SAVE_FILES_MODEL_TYPE) {
        await saveFilesAfterSuccessedResponse(createdItemId);
      }

      // Если есть ID элемента, после которого необходимо вставить — отправляем запрос на изменение позиций элементов
      if (appendAfterId && createdItemId) {
        await updateIdsPositionRequest(createdItemId);
      }

      // Иначе обновляем страницу
      reloadLocation();
    }
  }, [isEditable, fieldsData, appendAfterId, handlErrorMessages, getFetchRequestData, saveFilesAfterSuccessedResponse, SAVE_FILES_MODEL_TYPE]);

  const checkIsCorrectFieldsData = () => {
    const keys = Object.keys(fieldsData);

    let newFieldsData = {};
    let hasErrors = false;

    for (let i = 0, l = keys.length; i < l; i++) {
      const key = keys[i];
      const fieldsItem = fieldsData[key];

      const isRequired = fieldsItem.required;
      const isPrice = fieldsItem.isPrice;
      const isSelect = fieldsItem.isSelect;
      const validateFunction = fieldsItem.validateFunction;
      const validateFunctionErrorText = fieldsItem.validateFunctionErrorText;

      const fieldsValue = fieldsItem.value;

      newFieldsData[key] = { ...fieldsItem };

      if (isPrice) {
        const valueNumber = Number(fieldsValue);
        const isCorrectFormatForPrice = String(fieldsValue).length && (isInt(valueNumber) || isFloat(valueNumber));

        if (valueNumber > MAX_PRICE_AVALIABLE_VALUE) {
          newFieldsData[key].errorText = 'Значение должно быть меньше ' + MAX_PRICE_AVALIABLE_VALUE;
          hasErrors = true;
        }

        else if (!isCorrectFormatForPrice) {
          newFieldsData[key].errorText = 'Некорректное значение';
          hasErrors = true;
        }
      }

      if (validateFunction && !validateFunction(fieldsValue)) {
        newFieldsData[key].errorText = validateFunctionErrorText;
        hasErrors = true;
      }

      if (!isRequired) continue;

      if (isSelect && fieldsValue < 0) {
        newFieldsData[key].errorText = 'Некорректное значение';
        hasErrors = true;
      }

      const isEmpty = !isPrice && !isSelect && !Boolean(fieldsValue);

      if (isEmpty) {
        newFieldsData[key].errorText = DEFAULT_ERROR_TEXT;
        hasErrors = true;
      }
    }

    setFieldsData(newFieldsData);

    if (hasErrors) {
      console.log('❌ [checkIsCorrectFieldsData] hasErrors', newFieldsData);
    }

    return !hasErrors;
  };

  const onSubmitAction = () => {
    if (checkIsCorrectFieldsData()) {
      fetchDataRequest();
    }
  };

  const onCloseAction = useCallback(() => hideModal(), [hideModal]);

  return getTemplate({
    onCloseAction,
    onSubmitAction,
    submitButtonText,
    handleForm,
    handleFileInput,
    handleFileDelete,
    fieldsData,
    setFieldsData,
  });
};

ModalConstructor.propTypes = {
  // Возвращает разметку попапа
  getTemplate: PropTypes.func,

  // Находится ли модальное окно в состоянии редактирования
  isEditable: PropTypes.bool,

  // Функция закрытия модального окна
  hideModal: PropTypes.func,

  // Данные, которыми заполняется форма внутри модального окна
  fieldsData: PropTypes.object,

  // ID записи, после которой необходимо вставить созданную
  appendAfterId: PropTypes.number,

  // Массив всех ID записей таблицы
  allObjectIds: PropTypes.object,
};

export default ModalConstructor;