import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Group = ({children, align}) => {
    return (
        <GroupContainer align={align}>
            {children}
        </GroupContainer>
    );
};

const GroupContainer = styled.div`
    overflow: auto;
  display: flex;
  align-items: center;
  justify-content: ${({align}) => align === 'right' ? 'flex-end' : 'flex-start'};
  flex: 1 1 auto;
  > * {
    margin-right: 10px;
    :last-child {
      margin-right: 0;
    }
  }
`

Group.propTypes = {
    children: PropTypes.any.isRequired,
    align: PropTypes.oneOf(['left', 'right']),
};

Group.defaultProps = {
    align: 'left',
}

export default Group;