import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {calculateColor} from "../../../utils/calculateColor";
import {Link} from "react-router-dom";

const Item = ({item, last}) => {

    const _renderContent = () => {
        if(last) {
            return (
                <ItemContainer>
                    {item.label}
                </ItemContainer>
            )
        } else {
            return (
                    <ItemContainer>
                        <Link to={item.path}>
                            {item.label}
                        </Link>
                    </ItemContainer>
            )
        }
    }

    return (
        <>
            {_renderContent()}
        </>
    );
};

const ItemContainer = styled.div`
  ${({theme, type}) => theme.mixins.gothamSemibold({color: calculateColor(type)})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    color: ${({theme}) => theme.colors.secondary};
    transition: color ${({theme}) => theme.transitions.normal};
    :hover {
      color: ${({theme}) => theme.colors.primary};
    }
  }
`

Item.propTypes = {
    item: PropTypes.string.isRequired,
    last: PropTypes.bool,
};

Item.defaultProps = {
    last: false,
}

export default Item;