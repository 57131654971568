export const tableFields = [
    {
        id: 0,
        name: 'number',
        label: 'Номер УШ',
        props: {
            width: '80px',
        },
    },
    {
        id: 1,
        name: 'name',
        label: 'Наименование УШ',
    },
    {
        id: 2,
        name: 'current_documentation',
        label: 'Действующая документация',
        props: {
            width: '300px',
        },
    },
    {
        id: 3,
        name: 'presentations',
        label: 'Дата предъявления',
        props: {
            width: '250px',
        },
    },
    {
        id: 4,
        name: 'closing_date_planned',
        label: 'Плановая дата закрытия УШ',
        props: {
            width: '100px',
        },
    },
    {
        id: 5,
        name: 'closing_date_real',
        label: 'Дата закрытия',
        props: {
            width: '100px',
        },
    },
    {
        id: 6,
        name: 'files',
        label: 'Фотографии и необходимые файлы',
        props: {
            width: '300px',
        },
    },
]
