import React from 'react';

import { API_getObjectTravelingCertificatesEditAndDeleteUrl } from '../../utils/api';

import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteUH = ({ hideModal, data }) => {
  const itemId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getObjectTravelingCertificatesEditAndDeleteUrl(itemId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить запись УХ',
    DELETE_STATIC_VALUE: data.name.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteUH;