import FilesList from "../FilesList";
import InfoGroup from "../InfoGroup";

const filesList = [
    {
        id: 0,
        name: 'filename.png',
        link: '/file.png',
    },
    {
        id: 1,
        name: 'file.jpg',
        link: '/file.jpg',
    },
]


export const UPtableFields = [
    {
        id: 0,
        name: 'number',
        label: 'Номер рапорта/извещения',
        props: {
            width: '120px',
        },
    },
    {
        id: 1,
        name: 'name',
        label: 'Наименование',
    },
    {
        id: 3,
        name: 'current_documentation',
        label: 'Действующая документация',
    },
    {
        id: 4,
        name: 'presentations',
        label: 'Дата предъявления',
        props: {
            width: '250px',
        },
    },
    {
        id: 5,
        name: 'closing_date',
        label: 'Дата закрытия',
        props: {
            width: '100px',
        },
    },
    {
        id: 6,
        name: 'files',
        label: 'Файлы и фотографии',
        props: {
            width: '300px',
        },
    },
]