import {useDispatch} from "react-redux";
import {hide, show} from "../../features/anchorPopup/anchorPopupSlice";
import {useCallback, useState} from "react";

export function cloneEvent(e) {
    function ClonedEvent() {};
    let clone = new ClonedEvent();
    for (let p in e) {
        let d = Object.getOwnPropertyDescriptor(e, p);
        if (d && (!d.writable || !d.configurable || !d.enumerable || d.get || d.set)) {
            Object.defineProperty(clone, p, d);
        } else {
            clone[p] = e[p];
        }
    }
    Object.setPrototypeOf(clone, e);
    return clone;
}

const getBoundingClientRect = (element) => {
    if(element) {
        const {top, right, bottom, left, width, height, x, y} = element.getBoundingClientRect()
        return {top, right, bottom, left, width, height, x, y}
    }
    return {}
}

export const useAnchorPopup = ({component, props}) => {
    const dispatch = useDispatch();

    const [target, setTarget] = useState(null)
    const [ev, setEv] = useState(null)

    const hideAnchorPopup = useCallback((e) => {
        return new Promise(resolve => {
            e.stopPropagation()
            dispatch(hide())
            const clone = cloneEvent(e);
            setTarget(null)
            setEv(null)
            resolve({e: clone, elPos: getBoundingClientRect(e.currentTarget)});
        })
    }, [dispatch])

    const showAnchorPopup = useCallback((ev, options) => {
        return new Promise(resolve => {
            hideAnchorPopup(ev).then(({e, elPos}) => {
                e.preventDefault();
                e.persist();
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                setTarget(e.currentTarget);
                setEv(e);
                dispatch(show({component, positionType: options?.positionType, elementPosition: elPos, props: {...props, ...options}}));
                resolve();
            })
        });
    }, [component, dispatch, hideAnchorPopup, props])

    const updatePosition = useCallback(() => {
        // ev && showAnchorPopup(ev)
        ev && dispatch(hide())
        // ev && dispatch(show({component, elementPosition: getBoundingClientRect(target), props}))
    }, [ev, showAnchorPopup])

    return [showAnchorPopup, hideAnchorPopup, updatePosition]
}
