import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from "../../Structure/Row";
import Input from "../../Input";
import Column from "../../Structure/Column";
import DatePicker from "../../DatePicker";
import Group from "../../Structure/Group";
import Button from "../../Button";
import FilePreview from "../../FilePreview";
import { prepareModalData } from '../../../utils/prepareModalData';
import ModalConstructor from '../ModalConstructor';
import { prepareDateToSee } from '../../../utils/dates';
import { API_getConstructionCertificates, API_getConstructionCertificatesEditAndDeleteUrl, API_URL_GET_CONSTRUCTION_CERTIFICATES_SET_POSITIONS } from '../../../utils/api';
import { validateRegexpNumberFunction } from '../../../utils/validateRegexpNumberFunction';

const EditGroupRecordModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, groupId } = prepareModalData(props);
  const itemId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getConstructionCertificatesEditAndDeleteUrl(itemId) :
      API_getConstructionCertificates();

    return requestUrl;
  }, [isEditable, itemId]);

  const editProps = {
    name: {
      required: true,
      value: item.name || '',
      errorText: '',
    },
    number: {
      required: true,
      value: item.number || '',
      errorText: '',
      validateFunction: validateRegexpNumberFunction,
      validateFunctionErrorText: 'Значение должно соответствовать формату xx.yy'
    },
    closing_date_planned: {
      required: true,
      value: item.closing_date_planned || '',
      errorText: '',
      isDate: true,
    },
    closing_date_real: {
      required: false,
      value: item.closing_date_real || '',
      errorText: '',
      isDate: true,
    },
    current_documentation: {
      required: false,
      value: item.current_documentation || '',
      errorText: '',
    },
    files: {
      required: false,
      value: item.files || [],
      errorText: '',
      isFile: true,
    },
    group_id: {
      value: groupId,
      needToSendAlways: true,
    }
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      handleFileInput,
      handleFileDelete,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    const onFileLoadHandler = (files) => {
      handleFileInput({ files, fileKeyInState: 'files' });
    };

    const onFileDeleteHandler = (file) => {
      handleFileDelete({ file, fileKeyInState: 'files' });
    };

    return (
      <>
        <Row>
          <Input
            placeholder='Введите номер УП'
            label='Номер УП'
            onChange={handleForm}
            {...getFieldProps('number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите наименование УП'
            label='Наименование УП'
            onChange={handleForm}
            {...getFieldProps('name')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите документацию'
            label='Документация'
            onChange={handleForm}
            {...getFieldProps('current_documentation')}
          />
        </Row>
        <Row direction='row'>
          <Column>
            <DatePicker
              label='Плановая дата закрытия УП'
              onChange={handleForm}
              name='closing_date_planned'
              calendarValue={fieldsData.closing_date_planned.value}
              displayedValue={prepareDateToSee(fieldsData.closing_date_planned.value)}
              required={fieldsData.closing_date_planned.required}
              errorText={fieldsData.closing_date_planned.errorText}
            />
          </Column>
          <Column>
            <DatePicker
              label='Дата закрытия УП'
              onChange={handleForm}
              name='closing_date_real'
              calendarValue={fieldsData.closing_date_real.value}
              displayedValue={prepareDateToSee(fieldsData.closing_date_real.value)}
              required={fieldsData.closing_date_real.required}
              errorText={fieldsData.closing_date_real.errorText}
            />
          </Column>
        </Row>
        <Row>
          <FilePreview onLoad={onFileLoadHandler} files={fieldsData.files.value} onDelete={onFileDeleteHandler} />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: editProps,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    SAVE_FILES_MODEL_TYPE: 'construction_certificates',

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_GET_CONSTRUCTION_CERTIFICATES_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditGroupRecordModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditGroupRecordModal;