import React, { useState } from 'react';
import styled, { useTheme } from "styled-components";
import { useDispatch } from "react-redux";
import { changeUserFontSize } from "../../features/app/appSlice";
import { API_getUserEditAndDeleteUrl } from '../../utils/api';
import { getAuthorizationHeader, getUserConfigFontSizeFromStorage, getUserIdFromStorage, saveUserConfigFontSizeToStorage } from '../../utils/auth';
import { fetchRequest } from '../../utils/fetchRequest';
import { USER_FONT_SIZE_CONST_LG, USER_FONT_SIZE_CONST_MD, USER_FONT_SIZE_CONST_SM } from '../../theme';

const ButtonsGroup = () => {

  const theme = useTheme()
  const dispatch = useDispatch()

  const userIdFromStorage = getUserIdFromStorage();
  const userFontSizeFromStorage = getUserConfigFontSizeFromStorage();

  const initialFontSize = userFontSizeFromStorage || theme.userSettings.fontSize;
  const initialUserId = userIdFromStorage || null;

  const [activeFontSize, setActiveFontSize] = useState(initialFontSize);

  const saveUserFontSizeToDB = async (size) => {
    const url = API_getUserEditAndDeleteUrl(initialUserId);

    const requestData = {
      config: {
        fontSize: size
      }
    };

    const options = {
      method: 'PUT',
      headers: {
        'Authorization': getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    };

    await fetchRequest(url, options);
  };

  const toggleFontSize = (size) => {
    if (size === activeFontSize) return;

    dispatch(changeUserFontSize(size))

    saveUserFontSizeToDB(size);

    setActiveFontSize(size);

    saveUserConfigFontSizeToStorage(size);
  }

  return (
    <GroupContainer>
      <Item isActive={activeFontSize === USER_FONT_SIZE_CONST_SM} onClick={() => toggleFontSize(USER_FONT_SIZE_CONST_SM)}>
        S
      </Item>
      <Item isActive={activeFontSize === USER_FONT_SIZE_CONST_MD} onClick={() => toggleFontSize(USER_FONT_SIZE_CONST_MD)}>
        M
      </Item>
      <Item isActive={activeFontSize === USER_FONT_SIZE_CONST_LG} onClick={() => toggleFontSize(USER_FONT_SIZE_CONST_LG)}>
        L
      </Item>
    </GroupContainer>
  );
};

const GroupContainer = styled.div`
  user-select: none;
  display: flex;
  border-radius: ${({ theme }) => theme.other.borderRadiusNormal};
  border: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
`

const Item = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isActive }) => isActive ? 1 : .5};
  ${({ theme, isActive }) => theme.mixins.gothamBold({ color: isActive ? '#fff' : theme.colors.primary })};
  transition: opacity ${({ theme }) => theme.transitions.normal};
  background-color: ${({ theme, isActive }) => isActive ? theme.colors.primary : 'transparent'};
  :nth-child(2) {
    border-left: 1px solid ${({ theme }) => theme.colors.border};
    border-right: 1px solid ${({ theme }) => theme.colors.border};
  }
  :hover {
    opacity: 1;
  }
`

export default ButtonsGroup;