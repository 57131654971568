export const prepareModalData = props => {
  const data = props.data || {};
  const item = data.item || {};
  const isEditable = data.isEditable;
  const contractId = data.contractId;
  const objectId = data.objectId;
  const rowIndex = data.rowIndex;
  const groupId = data.groupId;
  const constructionCertificateId = data.constructionCertificateId;

  const hideModal = props.hideModal;
  const appendAfterId = props.appendAfterId;
  const allIds = props.allIds;

  const prepareItem = item => {
    return Object.keys(item).reduce((result, key) => {
      const { value, isDate } = item[key];

      let preparedValue = value;

      if (isDate && preparedValue) {
        preparedValue = new Date(preparedValue);
      }

      result[key] = preparedValue;

      return result;
    }, {});
  };

  return {
    item: prepareItem(item),
    data,
    appendAfterId,
    isEditable,
    hideModal,
    allIds,
    contractId,
    objectId,
    groupId,
    constructionCertificateId,
    rowIndex,
  };
};