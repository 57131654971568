import React from 'react';
import styled from 'styled-components';
import LoaderIcon from '../Icons/LoaderIcon';

const Loader = ({ isLoading, fillValue }) => {
  if (!isLoading) return null;

  return (
    <LoaderElement>
      <LoaderIcon fillValue={fillValue} />
    </LoaderElement>
  );
};

const LoaderElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default Loader;