import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import Column from '../../Structure/Column';
import DatePicker from '../../DatePicker';
import Group from '../../Structure/Group';
import Button from '../../Button';
import { prepareModalData } from '../../../utils/prepareModalData';
import { API_getJudicialWorks, API_getJudicialWorksEditAndDeleteUrl, API_URL_GET_JUDICAL_WORKS_SET_POSITIONS } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import { prepareDateToSee } from '../../../utils/dates';
import FilePreview from "../../FilePreview";

const EditJudicalWorkModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
  const judicalWorkId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getJudicialWorksEditAndDeleteUrl(judicalWorkId) :
      API_getJudicialWorks();

    return requestUrl;
  }, [isEditable, judicalWorkId]);

  const judicalWorkProps = {
    court_case_number: {
      required: false,
      value: item.court_case_number || '',
      errorText: '',
    },
    court_hearing_date: {
      required: false,
      value: item.court_hearing_date || '',
      errorText: '',
      isDate: true,
    },
    defendant: {
      required: false,
      value: item.defendant || '',
      errorText: '',
    },
    matter_dispute: {
      required: false,
      value: item.matter_dispute || '',
      errorText: '',
    },
    plaintiff: {
      required: false,
      value: item.plaintiff || '',
      errorText: ''
    },
    statement_claim_date: {
      required: true,
      value: item.statement_claim_date || '',
      errorText: '',
      isDate: true,
    },
    statement_claim_name: {
      required: true,
      value: item.statement_claim_name || '',
      errorText: '',
    },
    statement_claim_number: {
      required: true,
      value: item.statement_claim_number || '',
      errorText: '',
    },
    statement_claim_result: {
      required: false,
      value: item.statement_claim_result || '',
      errorText: '',
    },
    statement_claim_status: {
      required: false,
      value: item.statement_claim_status || '',
      errorText: '',
    },
    third_parties: {
      required: false,
      value: item.third_parties || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    },
    files: {
      required: false,
      value: item.files || [],
      errorText: '',
      isFile: true,
    },
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
      handleFileInput,
      handleFileDelete,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    const onFileLoadHandler = (files) => {
      handleFileInput({ files, fileKeyInState: 'files' });
    };

    const onFileDeleteHandler = (file) => {
      handleFileDelete({ file, fileKeyInState: 'files' });
    };

    return (
      <>
        <Row direction='row'>
          <Column>
            <DatePicker
              label='Дата'
              onChange={handleForm}
              name='statement_claim_date'
              calendarValue={fieldsData.statement_claim_date.value}
              displayedValue={prepareDateToSee(fieldsData.statement_claim_date.value)}
              required={fieldsData.statement_claim_date.required}
              errorText={fieldsData.statement_claim_date.errorText}
            />
          </Column>
          <Column>
            <DatePicker
              label='Дата судебного заседания'
              onChange={handleForm}
              name='court_hearing_date'
              calendarValue={fieldsData.court_hearing_date.value}
              displayedValue={prepareDateToSee(fieldsData.court_hearing_date.value)}
              required={fieldsData.court_hearing_date.required}
              errorText={fieldsData.court_hearing_date.errorText}
            />
          </Column>
        </Row>
        <Row>
          <Input
            placeholder='Введите номер работы'
            label='Номер'
            onChange={handleForm}
            {...getFieldProps('statement_claim_number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите наименование искового заявления'
            label='Наименование искового заявления'
            onChange={handleForm}
            {...getFieldProps('statement_claim_name')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите номер судебного дела'
            label='Номер судебного дела'
            onChange={handleForm}
            {...getFieldProps('court_case_number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите данные об истце'
            label='Истец'
            onChange={handleForm}
            {...getFieldProps('plaintiff')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите данные об ответчике'
            label='Ответчик'
            onChange={handleForm}
            {...getFieldProps('defendant')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите данные о третьих лицах'
            label='Третьи лица'
            onChange={handleForm}
            {...getFieldProps('third_parties')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите данные о предмете спора'
            label='Предмет спора'
            onChange={handleForm}
            {...getFieldProps('matter_dispute')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите данные о стадии рассмотрения искового заявления'
            label='Стадия рассмотрения искового заявления'
            onChange={handleForm}
            {...getFieldProps('statement_claim_status')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите данные о результате рассмотрения искового заявления'
            label='Результат рассмотрения искового заявления'
            onChange={handleForm}
            {...getFieldProps('statement_claim_result')}
          />
        </Row>
        <Row>
          <FilePreview onLoad={onFileLoadHandler} files={fieldsData.files.value} onDelete={onFileDeleteHandler} />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: judicalWorkProps,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    SAVE_FILES_MODEL_TYPE: 'judicial_works',

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_GET_JUDICAL_WORKS_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditJudicalWorkModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditJudicalWorkModal;