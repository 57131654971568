import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const InfoItem = ({item}) => {
    return (
        <InfoItemContainer>
            {item.date}
            {item.label}
        </InfoItemContainer>
    );
};

const InfoItemContainer = styled.div`

`

InfoItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default InfoItem;