import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setProperties } from '../../utils/setProperties';
import { API_getJudicialWorks } from '../../utils/api';
import Loader from '../Loader';
import PageConstructor from '../PageConstructor';
import { searchIcons } from '../BankGuarantees/components';
import EditJudicalWorkModal from '../Forms/EditJudicalWorkModal';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {prepareFiles} from "../../utils/prepareFiles";
import {useSelector} from "react-redux";

const tableFields = [
  {
    id: 0,
    name: 'statement_claim_date',
    label: 'Дата',
    props: {
      width: '100px',
    }
  },
  {
    id: 1,
    name: 'statement_claim_number',
    label: 'Номер',
    props: {
      width: '100px',
    }
  },
  {
    id: 2,
    name: 'statement_claim_name',
    label: 'Наименование искового заявления',
    props: {
      width: '400px',
    },
  },
  {
    id: 3,
    name: 'court_case_number',
    label: 'Номер судебного дела',
    props: {
      width: '100px',
    },
  },
  {
    id: 4,
    name: 'plaintiff',
    label: 'Истец',
    props: {
      width: '200px',
    },
  },
  {
    id: 5,
    name: 'defendant',
    label: 'Ответчик',
    props: {
      width: '200px',
    },
  },
  {
    id: 6,
    name: 'third_parties',
    label: 'Третьи лица',
    props: {
      width: '150px',
    },
  },
  {
    id: 7,
    name: 'matter_dispute',
    label: 'Предмет спора',
    props: {
      width: '250px',
    },
  },
  {
    id: 8,
    name: 'court_hearing_date',
    label: 'Дата судебного заседания',
    props: {
      width: '130px',
    },
  },
  {
    id: 9,
    name: 'statement_claim_status',
    label: 'Стадия рассмотрения искового заявления',
    props: {
      width: '600px',
    },
  },
  {
    id: 10,
    name: 'statement_claim_result',
    label: 'Результат рассмотрения искового заявления',
    props: {
      width: '600px',
    },
  },
  {
    id: 11,
    name: 'files',
    label: 'Файлы',
    props: {
      width: '100px',
    },
  },
];

const JudicalWorks = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback(allItems => {
    return allItems.map(properties => {
      const {
        id,
        defendant,
        plaintiff,
        court_hearing_date,
        court_case_number,
        matter_dispute,
        statement_claim_name,
        statement_claim_number,
        statement_claim_date,
        statement_claim_result,
        statement_claim_status,
        third_parties,
        files: filesData,
      } = properties;

      const filesList = prepareFiles(filesData);

      const propertiesItem = setProperties({
        id: { value: id },
        defendant: { value: defendant },
        plaintiff: { value: plaintiff },
        court_case_number: { value: court_case_number },
        court_hearing_date: { value: court_hearing_date?.date, isDate: true },
        matter_dispute: { value: matter_dispute },
        statement_claim_name: { value: statement_claim_name },
        statement_claim_date: { value: statement_claim_date?.date, isDate: true },
        statement_claim_result: { value: statement_claim_result },
        statement_claim_number: { value: statement_claim_number },
        statement_claim_status: { value: statement_claim_status },
        third_parties: { value: third_parties },
        files: { value: filesList, isFile: true },
      });

      return propertiesItem;
    });
  }, []);

  const requestUrl = API_getJudicialWorks([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onSort,
      sort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск по судебным работам' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} onSort={onSort} sort={sort} fields={tableFields} data={filteredRecievedItems} modalType='judical-works' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label='Добавить новую судебную работу' onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, tableFields);

  const judicalWorksProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'defendant',
      'plaintiff',
      'court_hearing_date',
      'court_case_number',
      'matter_dispute',
      'statement_claim_name',
      'statement_claim_number',
      'statement_claim_date',
      'statement_claim_result',
      'statement_claim_status',
      'third_parties',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditJudicalWorkModal,
      name: 'new-judical-work',
      title: 'Новая судебная работа',
      props: { contractId },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'judical-works',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...judicalWorksProps} />;
};

export default JudicalWorks;