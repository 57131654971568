import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";

const PageContent = ({children, noPaddings}) => {
    return (
        <PageContentContainer noPaddings={noPaddings}>
            {children}
        </PageContentContainer>
    );
};

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  //overflow-x: hidden;
    max-width: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
  position: relative;
  ${({noPaddings}) => noPaddings ? css`
    padding: 10px 0 0 0;
  ` : css`
    padding: 10px 10px 0 10px;
  `};
  :last-child {
    margin-bottom: 0;
  }
`

PageContent.propTypes = {
    children: PropTypes.any.isRequired,
    noPaddings: PropTypes.bool,
};

PageContent.defaultProps = {
    noPaddings: false,
}

export default PageContent;