import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import PageContent from '../styled/PageContent';
import PageContainer from '../styled/PageContainer';
import Row from '../Structure/Row';
import ObjectGroups from '../ObjectGroups';
import GroupContent from '../GroupContent';
import GroupUP from '../GroupUP';
import { getObjectIdFromRoute } from '../../utils/getObjectIdFromRoute';
import { getAuthorizationHeader } from '../../utils/auth';
import { API_getConstructionCertificates, API_getGroupConstructionCertificates, API_getGroupsEditAndDeleteUrl } from '../../utils/api';
import { fetchRequest } from '../../utils/fetchRequest';
import { setProperties } from '../../utils/setProperties';
import { prepareFiles } from '../../utils/prepareFiles';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { getGroupIdFromRoute } from '../../utils/getGroupIdFromRoute';
import Presentations from '../Presentations';
import { getRouteUrl, getWatcherRouteUrl } from '../../utils/getRouteUrl';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import { setExportState } from '../../features/export/exportReducer';
import { UPGrouptableFields } from '../GroupContent/components';
import { resetUpCount } from '../../features/upReducer';
import { DEFAULT_FILTERS_DATA } from '../../utils/filtersHelpers';
import { prepareDateToSee } from '../../utils/dates';

const UP = (props) => {
  const { allGroupsData, isWatcherUser } = props;

  const UP_INITIAL_ARRAY = [];

  const [allUPData, setAllUPData] = useState(UP_INITIAL_ARRAY);
  const [filteredUPData, setFilteredUPData] = useState(UP_INITIAL_ARRAY);
  const [filtersData, setFiltersData] = useState(DEFAULT_FILTERS_DATA);
  const [isLoading, setLoading] = useState(false);

  const route = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const isExactRoute = route.isExact;

  const objectId = getObjectIdFromRoute(route);
  const contractId = getContractIdFromRoute(route);
  const groupId = getGroupIdFromRoute(route);

  useEffect(() => {
    return () => {
      dispatch(resetUpCount());
    };
  }, []);

  useEffect(() => {
    return () => {
      setFiltersData(DEFAULT_FILTERS_DATA);
    };
  }, [groupId]);

  const prepareConstructionCertificates = useCallback((allItems) => {
    const todayDate = Number(new Date());

    return allItems.map((item) => {
      const {
        id,
        name,
        number,
        closing_date_real,
        closing_date_planned,
        current_documentation,
        files: filesData,
        group_id: groupId,
      } = item;

      const filesList = prepareFiles(filesData);
      const datePlan = closing_date_planned?.date;
      const dateReal = closing_date_real?.date;

      const propertiesItem = setProperties({
        id: { value: id },
        name: { value: name },
        closing_date_real: { value: dateReal, isDate: true },
        closing_date_planned: { value: datePlan, isDate: true },
        current_documentation: { value: current_documentation },
        files: { value: filesList, isFile: true },
        route: { value: isWatcherUser ? getWatcherRouteUrl({ objectId, groupId, groupItemId: id }) : getRouteUrl({ contractId, objectId, objectType: 'UP', groupId, groupItemId: id }) },
        number: { value: number },
      });

      if (!dateReal && datePlan && Number(new Date(datePlan)) <= todayDate) {
        propertiesItem._systemSettings = { colored: true };
      }

      return propertiesItem;
    });
  }, [contractId, objectId, groupId, isWatcherUser]);

  const requestUrl = API_getGroupConstructionCertificates(groupId);

  const getExportRequestUrlWithFilters = () => {
    const { no_closed_dates_checkbox, date_before, date_from } = filtersData;

    const filtersArray = [{
      column: 'group_id',
      operator: '=',
      value: groupId,
    }];

    if (no_closed_dates_checkbox.value) {
      filtersArray.push({
        column: 'closing_date_real',
        operator: '=',
        value: '',
      });
    }

    if (date_from.value) {
      filtersArray.push({
        column: 'closing_date_planned',
        operator: '>=',
        value: prepareDateToSee(date_from.value),
      });
    }

    if (date_before.value) {
      filtersArray.push({
        column: 'closing_date_planned',
        operator: '<=',
        value: prepareDateToSee(date_before.value),
      });
    }

    return API_getConstructionCertificates(filtersArray);
  };

  const exportRequestUrl = getExportRequestUrlWithFilters();

  const EXPORT_MODULE_URL = prepareExportUrl(exportRequestUrl, [
    { name: 'orders[construction_certificate]', label: 'number', withoutPrefix: true },
    { name: 'orders[report]', label: 'number', withoutPrefix: true },
    { name: 'orders[group]', label: 'position', withoutPrefix: true },
    { name: 'number', label: '№ УП' },
    { name: 'construction_certificate.*.reports.*.number', label: '№ Рапорта/извещание' },
    { name: 'construction_certificate.*.reports.*.name', label: 'Наименование' },
    { name: 'construction_certificate.*.reports.*.current_documentation', label: 'Действующая документация - ПДСП, РКД' },
    { name: 'closing_date_planned', label: 'Плановая дата закрытия УП' },
    { name: 'construction_certificate.*.reports.*.presentations.*.note', label: 'Дата предъявления' },
    { name: 'construction_certificate.*.reports.*.presentations.*.date', label: 'Замечание к предъявлению' },
    { name: 'construction_certificate.*.reports.*.files.*.name', label: 'Фотографии и другие необходимые файлы' },
  ]);

  useEffect(() => {
    if (!isExactRoute) return;

    dispatch(setExportState({
      exportUrl: EXPORT_MODULE_URL,
      exportName: 'up',
    }));
  }, [EXPORT_MODULE_URL, isExactRoute]);


  const getCertificatesData = useCallback(async () => {
    if (!groupId) return;

    setLoading(true);

    const options = {
      headers: {
        'Authorization': getAuthorizationHeader(),
      },
    };

    const { response, success } = await fetchRequest(requestUrl, options);

    setLoading(false);

    return success && prepareConstructionCertificates(response.data.construction_certificates);
  }, [groupId, history, contractId, objectId, requestUrl]);

  useEffect(() => {
    setAllUPData(UP_INITIAL_ARRAY);
    setFilteredUPData(UP_INITIAL_ARRAY);

    const getData = async () => {
      const preparedUPs = await getCertificatesData();

      if (preparedUPs) {
        setAllUPData(preparedUPs);
        setFilteredUPData(preparedUPs);
      }
    }

    getData();
  }, [getCertificatesData]);

  return (
    <PageContainer>
      <PageContent noPaddings>
        <Row direction='row' fullHeight>
          <ObjectGroups allGroupsData={allGroupsData} isWatcherUser={isWatcherUser} />
          <Switch>
            <Route exact={true} path={`${route.path}`} render={() => <GroupContent
              objectsData={allUPData}
              filteredObjectsData={filteredUPData}
              setFilteredObjectsData={setFilteredUPData}
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              isLoading={isLoading}
            />} />
            <Route exact={true} path={`${route.path}/:UPId`} render={(renderProps) => <GroupUP {...renderProps} isWatcherUser={isWatcherUser} />} />
            <Route exact={true} path={`${route.path}/:UPId/presentations`} component={Presentations} />
          </Switch>
        </Row>
      </PageContent>
    </PageContainer>
  );
};

export default UP;