import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setProperties } from '../../utils/setProperties';
import Loader from '../Loader';
import { API_getCashExecutions } from '../../utils/api';
import PageConstructor from '../PageConstructor';
import { searchIcons } from '../BankGuarantees/components';
import EditCashExecutionModal from '../Forms/EditCashExecutionModal';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {prepareFiles} from "../../utils/prepareFiles";
import {useSelector} from "react-redux";

const tableFields = [
  {
    id: 0,
    name: 'stage_name',
    label: 'Наименование этапа/аванс',
  },
  {
    id: 2,
    name: 'price',
    label: 'Стоимость этапа',
    props: {
      width: '180px',
    },
  },
  {
    id: 3,
    name: 'amount_paid',
    label: 'Оплачиваемая сумма',
    props: {
      width: '180px',
    },
  },
  {
    id: 4,
    name: 'payment_date_planned',
    label: 'Дата оплаты (план)',
    props: {
      width: '100px',
    },
  },
  {
    id: 5,
    name: 'payment_date',
    label: 'Дата оплаты',
    props: {
      width: '100px',
    },
  },
  {
    id: 6,
    name: 'note',
    label: 'Примечание',
  },
  {
    id: 7,
    name: 'files',
    label: 'Файлы',
    props: {
      width: '100px',
    },
  },
];

const CashExecution = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback(allItems => {
    return allItems.map(properties => {
      const {
        id,
        stage_name,
        price,
        payment_date,
        payment_date_planned,
        amount_paid,
        note,
        files: filesData,
      } = properties;

      const filesList = prepareFiles(filesData);

      const propertiesItem = setProperties({
        id: { value: id },
        stage_name: { value: stage_name },
        note: { value: note },
        amount_paid: { value: amount_paid, isPrice: true },
        payment_date: { value: payment_date?.date, isDate: true },
        payment_date_planned: { value: payment_date_planned?.date, isDate: true },
        price: { value: price, isPrice: true },
        files: { value: filesList, isFile: true },
      });

      return propertiesItem;
    });
  }, []);

  const requestUrl = API_getCashExecutions([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onSort,
      sort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск по кассовым исполнениям' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} onSort={onSort} sort={sort} fields={tableFields} data={filteredRecievedItems} modalType='cash-execution' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label={'Добавить новое кассовое исполнение'} onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, [
    ...tableFields,
    { name: 'numeric[][]', label: 'Сумма требования', withoutPrefix: true },
  ]);

  const cashExecutionProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'stage_name',
      'amount_paid',
      'payment_date',
      'payment_date_planned',
      'price',
      'note',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditCashExecutionModal,
      name: 'new-cash-execution',
      title: 'Новое кассовое исполнение',
      props: { contractId },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'cash-execution',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...cashExecutionProps} />;
};

export default CashExecution;