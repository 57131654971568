import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import Textarea from '../../Textarea';
import Group from '../../Structure/Group';
import Button from '../../Button';
import Column from '../../Structure/Column';
import DatePicker from '../../DatePicker';
import { prepareModalData } from '../../../utils/prepareModalData';
import { API_getPaymentStages, API_getPaymentStagesEditAndDeleteUrl, API_URL_PAYMENT_STAGES_SET_POSITIONS } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import { prepareDateToSee } from '../../../utils/dates';

const EditPaymentStageModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
  const paymentStageId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getPaymentStagesEditAndDeleteUrl(paymentStageId) :
      API_getPaymentStages();

    return requestUrl;
  }, [isEditable, paymentStageId]);

  const paymentStageProps = {
    amount: {
      required: true,
      value: item.amount || '',
      errorText: '',
      isPrice: true,
    },
    name: {
      required: true,
      value: item.name || '',
      errorText: '',
    },
    rationale: {
      required: false,
      value: item.rationale || '',
      errorText: '',
    },
    start_date: {
      required: true,
      value: item.start_date || '',
      errorText: '',
      isDate: true,
    },
    expiration_date_planned: {
      required: true,
      value: item.expiration_date_planned || '',
      errorText: '',
      isDate: true,
    },
    expiration_date_real: {
      required: false,
      value: item.expiration_date_real || '',
      errorText: '',
      isDate: true,
    },
    note: {
      required: false,
      value: item.note || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    }
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    return (
      <>
        <Row>
          <Input
            placeholder='Введите наименование этапа'
            label='Наименование этапа'
            onChange={handleForm}
            {...getFieldProps('name')}
          />
        </Row>
        <Row>
          <Input
            type='number'
            placeholder='Введите стоимость этапа'
            label='Стоимость этапа'
            onChange={handleForm}
            {...getFieldProps('amount')}
          />
        </Row>
        <Row direction='row'>
          <Column>
            <DatePicker
              label='Дата начала'
              onChange={handleForm}
              name='start_date'
              calendarValue={fieldsData.start_date.value}
              displayedValue={prepareDateToSee(fieldsData.start_date.value)}
              required={fieldsData.start_date.required}
              errorText={fieldsData.start_date.errorText}
            />
          </Column>
          <Column>
            <DatePicker
              label='Дата окончания (план)'
              onChange={handleForm}
              name='expiration_date_planned'
              calendarValue={fieldsData.expiration_date_planned.value}
              displayedValue={prepareDateToSee(fieldsData.expiration_date_planned.value)}
              required={fieldsData.expiration_date_planned.required}
              errorText={fieldsData.expiration_date_planned.errorText}
            />
          </Column>
        </Row>
        <Row>
          <DatePicker
            label='Дата окончания (факт)'
            onChange={handleForm}
            name='expiration_date_real'
            calendarValue={fieldsData.expiration_date_real.value}
            displayedValue={prepareDateToSee(fieldsData.expiration_date_real.value)}
            required={fieldsData.expiration_date_real.required}
            errorText={fieldsData.expiration_date_real.errorText}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите обоснование'
            label='Обоснование'
            onChange={handleForm}
            {...getFieldProps('rationale')}
          />
        </Row>
        <Row>
          <Textarea placeholder='Примечание' onChange={handleForm} name='note' value={fieldsData.note.value} label='Примечание' />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: paymentStageProps,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_PAYMENT_STAGES_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditPaymentStageModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditPaymentStageModal;