import React, {useCallback, useEffect, useState} from 'react';
import PageHeading from "../styled/PageHeading";
import Icon from "../Icon";
import FullArrowIcon from "../Icons/FullArrowIcon";
import Input from "../Input";
import {searchIcons} from "../ContractCharacteristics/components";
import PageContent from "../styled/PageContent";
import Table from "../Table";
import {UPtableFields as tableFields} from "./components";
import PageFooter from "../styled/PageFooter";
import Button from "../Button";
import PageContainer from "../styled/PageContainer";
import styled from "styled-components";
import {useModal} from "../../hooks/useModal";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import ClipboardTitle from "../ClipboardTitle";
import {ContentWrapper, InputWrapper} from "../GroupContent";
import {toggleGroupsPanel} from "../../features/app/appSlice";
import {useDispatch, useSelector} from "react-redux";
import Loader from '../Loader';
import {API_getReports} from '../../utils/api';
import {getAuthorizationHeader} from '../../utils/auth';
import {fetchRequest} from '../../utils/fetchRequest';
import {setProperties} from '../../utils/setProperties';
import {prepareFiles} from '../../utils/prepareFiles';
import {preparePresentations} from '../../utils/preparePresentations';
import {filterItemsBySearchText} from '../../utils/filterItemsBySearchText';
import EditUPRecordModal from '../Forms/EditUPRecordModal';
import {getRouteUrl, getWatcherRouteUrl} from '../../utils/getRouteUrl';
import {getGroupIdFromRoute} from '../../utils/getGroupIdFromRoute';
import {getContractIdFromRoute} from '../../utils/getContractIdFromRoute';
import {getObjectIdFromRoute} from '../../utils/getObjectIdFromRoute';
import NewPresentation from "../Forms/NewPresentation";
import {prepareExportUrl} from '../../utils/prepareExportUrl';
import {setExportState} from '../../features/export/exportReducer';
import {getUpIdFromRoute} from '../../utils/getUpIdFromRoute';

const GroupUP = (props) => {
    const {isWatcherUser} = props;

    const GROUP_DATA_INITIAL = [];

    const [allGroupData, setAllGroupData] = useState(GROUP_DATA_INITIAL);
    const [filteredGroupData, setFilteredGroupData] = useState(GROUP_DATA_INITIAL);
    const [isLoading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');


    const {isArchive} = useSelector(state => state.breadcrumbs);

    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()

    const route = useRouteMatch()
    const groupId = getGroupIdFromRoute(route)
    const contractId = getContractIdFromRoute(route)
    const objectId = getObjectIdFromRoute(route)
    const UPId = getUpIdFromRoute(route);

    const app = useSelector(state => state.app)

    const [showModal] = useModal({
        component: EditUPRecordModal,
        name: 'new-up-record',
        title: 'Новая запись',
        props: {constructionCertificateId: UPId},
    });

    const prepareGroupUpData = useCallback((allItems) => {
        return allItems.map((item) => {
            const {
                id,
                name,
                number,
                closing_date,
                files: filesData,
                current_documentation,
                presentations,
            } = item;

            const filesList = prepareFiles(filesData);
            const presentationsList = preparePresentations([...presentations].reverse());

            const propertiesItem = setProperties({
                id: {value: id},
                name: {value: name},
                current_documentation: {value: current_documentation},
                closing_date: {value: closing_date?.date, isDate: true},
                presentations: {value: presentationsList, isList: true},
                files: {value: filesList, isFile: true},
                number: {value: number},
            });

            return propertiesItem;
        });
    }, []);

    const requestUrl = API_getReports([{
        column: 'construction_certificate_id',
        operator: '=',
        value: UPId,
    }]);

    const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, tableFields);

    useEffect(() => {
        dispatch(setExportState({
            exportUrl: EXPORT_MODULE_URL,
            exportName: 'reports',
        }));
    }, [EXPORT_MODULE_URL]);

    const getGroupUpData = useCallback(async () => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': getAuthorizationHeader(),
            },
        };

        const {response, success} = await fetchRequest(requestUrl, options);

        setLoading(false);

        return success && prepareGroupUpData(response.data);
    }, [requestUrl]);

    useEffect(() => {
        const getData = async () => {
            const groupUpData = await getGroupUpData();

            if (groupUpData) {
                setAllGroupData(groupUpData);
                setFilteredGroupData(groupUpData);
            }
        }

        getData();
    }, [getGroupUpData]);

    const toggleGroups = () => {
        dispatch(toggleGroupsPanel())
    }

    const handleSearchValue = useCallback(({value: searchValue}) => {
        setSearchValue(searchValue);

        if (!searchValue) {
            setFilteredGroupData(allGroupData);
            return;
        }

        const FILTERED_PROPERTIES = [
            'name',
            'number',
            'current_documentation',
            'closing_date',
            'number',
        ];

        const filteredItems = filterItemsBySearchText(allGroupData, FILTERED_PROPERTIES, searchValue);

        setFilteredGroupData(filteredItems);
    }, [allGroupData, setFilteredGroupData]);

    const [showPresentationModal] = useModal({
        component: NewPresentation,
        name: 'new-presentation',
        title: 'Редактирование предъявлений'
    });

    const addPresentation = [
        {
            label: 'Редактировать предъявления',
            onClick: (item) => showPresentationModal({data: {item, modelType: 'reports'}})
        }
    ];

    const onBackButtonClick = useCallback(() => {
        const routeUrl = isWatcherUser ?
            getWatcherRouteUrl({objectId, groupId: groupId}) :
            getRouteUrl({objectId, groupId, contractId, objectType: 'UP'});
        history.push(routeUrl);
    }, [objectId, groupId, contractId]);

    return (
        <ContentWrapper>
            <PageContainer>
                <PageHeading>
                    <TitleWrapper>
                        <ClipboardTitle text={location.state}/>
                    </TitleWrapper>
                    <SearchWrapper>
                        <IconWrapper isActive={app.sideMenu}>
                            <Icon icon={FullArrowIcon} onClick={toggleGroups}/>
                        </IconWrapper>
                        <InputWrapper>
                            <Input icon={searchIcons} value={searchValue} placeholder='Поиск по УП' name='search'
                                   onChange={handleSearchValue}/>
                        </InputWrapper>
                        <ButtonWrapper>
                            <Button label='Назад' onClick={onBackButtonClick}/>
                        </ButtonWrapper>
                    </SearchWrapper>
                </PageHeading>
                <PageContent>
                    <Loader isLoading={isLoading}/>
                    {filteredGroupData.length ?
                        <Table disabled={isArchive} addBetween={false} fields={tableFields} data={filteredGroupData}
                               controlsList={addPresentation} modalType='group-up-record'/> : null}
                </PageContent>
                <PageFooter>
                    {!isArchive && <Button label='Добавить новую запись' onClick={showModal}/>}
                </PageFooter>
            </PageContainer>
        </ContentWrapper>
    );
};

const TitleWrapper = styled.div`
    margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
    margin-left: 10px;
`

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const IconWrapper = styled.div`
    transform-origin: 50% 50%;
    transform: rotate(${({isActive}) => isActive ? 0 : 180}deg);
    transition: transform ${({theme}) => theme.transitions.normal};
`

GroupUP.propTypes = {};

export default GroupUP;