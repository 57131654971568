import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = React.memo((props) => {
    const {
        fields,
        data,
        controls,
        onRowClick,
        controlsList,
        onSort,
        sort,
        addBetween,
        modalType,
        modalProps,
        disabled,
    } = props;

    return (
        <TableContainer>
            <TableHeader fields={fields} controls={controls} onSort={onSort} sort={sort} />
            <TableBody
                data={data}
                fields={fields}
                controls={controls}
                onRowClick={onRowClick}
                controlsList={controlsList}
                addBetween={addBetween}
                modalType={modalType}
                modalProps={modalProps}
                disabled={disabled}
            />
        </TableContainer>
    );
});

const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`

Table.propTypes = {
    fields: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    controls: PropTypes.bool,
    onRowClick: PropTypes.func,
    controlsList: PropTypes.array,
    addBetween: PropTypes.bool,
};

Table.defaultProps = {
    controls: true,
    addBetween: true,
}

export default Table;