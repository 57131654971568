import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Row from "../../Structure/Row";
import DatePicker from "../../DatePicker";
import Group from "../../Structure/Group";
import Button from "../../Button";
import Textarea from "../../Textarea";
import Column from "../../Structure/Column";
import { fetchRequest } from '../../../utils/fetchRequest';
import { getAuthorizationHeader } from '../../../utils/auth';
import { API_getPresentations, API_getPresentationsBulk } from '../../../utils/api';
import { prepareDateToSee } from '../../../utils/dates';
import styled from "styled-components";
import { USER_FONT_SIZE_CONST_MD } from '../../../theme';

const NewPresentation = (props) => {
  const { hideModal, item = {}, modelType } = props;
  const modelId = item.id?.value;

  const [presentationsData, setPresentationsData] = useState([]);
  const [startedPresentationsData, setStartedPresentationsData] = useState([]);

  const DEFAULT_ERROR_TEXT = 'Поле обязательно для ввода';

  const toJsonField = (value) => "\"" + value + "\"";

  const fieldsDataDefault = {
    date: {
      value: '',
      required: true,
      isDate: true,
      errorText: ''
    },
    note: {
      value: '',
      required: true,
      errorText: ''
    },
  };

  const [fieldsData, setFieldsData] = useState(fieldsDataDefault);

  const preparePresentationsData = (responseData) => {
    return responseData.map(responseItem => {
      return {
        id: { value: responseItem.id },
        date: { value: responseItem.date },
        note: { value: responseItem.note },
      };
    });
  };

  const fetchPresentaions = useCallback(() => {
    const fetchData = async () => {
      const options = {
        method: 'GET',
        headers: {
          'Authorization': getAuthorizationHeader(),
        },
      };

      const requestUrl = API_getPresentations({
        filter: [{
          column: 'model_type',
          operator: '=',
          value: modelType,
        }, {
          column: 'model_id',
          operator: '=',
          value: modelId,
        }]
      });

      const { response, success } = await fetchRequest(requestUrl, options);
      const responseData = response.data;

      if (success && responseData) {
        setPresentationsData(preparePresentationsData(responseData));
        setStartedPresentationsData(preparePresentationsData(responseData));
      }
    }

    fetchData();
  }, [modelType, modelId]);

  useEffect(() => {
    fetchPresentaions();
  }, []);

  const handleForm = useCallback(({ name, value }) => {
    const newFieldsData = Object.keys(fieldsData).reduce((result, key) => {
      result[key] = { ...fieldsData[key] };
      return result;
    }, {});

    newFieldsData[name].errorText = '';
    newFieldsData[name].value = value;

    setFieldsData(newFieldsData);
  }, [setFieldsData, fieldsData]);

  const checkIsCorrectFieldsData = () => {
    const keys = Object.keys(fieldsData);

    let newFieldsData = {};
    let hasErrors = false;

    for (let i = 0, l = keys.length; i < l; i++) {
      const key = keys[i];
      const fieldsItem = fieldsData[key];

      const isRequired = fieldsItem.required;

      const fieldsValue = fieldsItem.value;

      newFieldsData[key] = { ...fieldsItem };

      if (!isRequired) continue;

      const isEmpty = !Boolean(fieldsValue);

      if (isEmpty) {
        newFieldsData[key].errorText = DEFAULT_ERROR_TEXT;
        hasErrors = true;
      }
    }

    setFieldsData(newFieldsData);

    if (hasErrors) {
      console.log('❌ [checkIsCorrectFieldsData] hasErrors', newFieldsData);
    }

    return !hasErrors;
  };

  const handlErrorMessages = useCallback(messages => {
    const newFieldsData = Object.keys(fieldsData).reduce((result, key) => {
      const errorText = Array.isArray(messages[key]) && messages[key].length ?
        messages[key].map(text => text).join('') : '';

      result[key] = {
        ...fieldsData[key],
        errorText,
      };

      return result;
    }, {});

    setFieldsData(newFieldsData);
  }, [fieldsData, setFieldsData]);

  const saveDataRequest = async (requestData, requestUrl) => {
    const options = {
      method: 'POST',
      headers: {
        'Authorization': getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    };

    const { response } = await fetchRequest(requestUrl, options);

    const messages = response.error?.messages;

    if (messages) {
      handlErrorMessages(messages);
      return;
    }
  };

  const onAddPresentationButtonClick = () => {
    if (checkIsCorrectFieldsData()) {
      const newPresentationsItem = {
        id: { value: 'new_element_' + Math.random() * 100 },
        date: { value: prepareDateToSee(fieldsData.date.value) },
        note: { value: fieldsData.note.value },
      };

      const newPresentationsData = [
        newPresentationsItem,
        ...presentationsData
      ];

      setPresentationsData(newPresentationsData);
      setFieldsData(fieldsDataDefault);
    }
  }

  const handleActualValues = ({ name, value }, id) => {
    const newPresentationsData = [];
    const preparedValue = name === 'date' ? prepareDateToSee(value) : value;

    for (let i = 0, l = presentationsData.length; i < l; i++) {
      const presentationsItem = presentationsData[i];

      if (presentationsItem.id.value === id) {
        newPresentationsData.push({
          ...presentationsItem,
          ...{ [name]: { value: preparedValue } },
        });

      } else {
        newPresentationsData.push(presentationsItem);
      }
    }

    setPresentationsData(newPresentationsData);
  }

  const renderData = (data) => {
    return data.map(el => {
      const id = el.id.value;
      const handleValues = (event) => {
        handleActualValues(event, id);
      };

      const deleteRow = (id) => {
        const newPresentationsData = [];

        for (let i = 0, l = presentationsData.length; i < l; i++) {
          const presentationsItem = presentationsData[i];

          if (presentationsItem.id.value === id) continue;

          newPresentationsData.push(presentationsItem);
        }

        setPresentationsData(newPresentationsData);
      };

      return (
        <Row key={id} direction={'row'}>
          <Column width={250}>
            <DatePicker
              onChange={handleValues}
              name={'date'}
              calendarValue={new Date(el.date.value)}
              displayedValue={prepareDateToSee(el.date.value)}
              required={el.date.required}
              errorText={el.date.errorText}
            />
          </Column>
          <Column>
            <Textarea height={30} placeholder={'Содержание предъявления'} onChange={handleValues} name={'note'} value={el.note.value} required={el.note.required} errorText={el.note.errorText} />
          </Column>
          <Button height={20} label='Удалить' color='alert' onClick={() => deleteRow(id)} />
        </Row>
      );
    });
  }

  const hasAnyChangesInData = useCallback((startedData, data) => {
    if (startedData.length !== data.length) return true;

    const changedItems = data.some(({ note, date }, index) => {
      return (
        startedData[index].note.value !== note.value ||
        startedData[index].date.value !== date.value
      );
    });

    return changedItems;
  }, [startedPresentationsData, presentationsData]);

  const getRequestDataFromPresentationsData = (presentationsData) => {
    const data = [...presentationsData].reverse().map(presentationsItem => ({
      date: toJsonField(presentationsItem.date.value),
      note: toJsonField(presentationsItem.note.value),
    }));

    return {
      data,
      model_id: modelId,
      model_type: modelType,
    };
  };

  const validateData = () => {
    const newPresentationsData = [];

    let hasValidateErrors = false;

    for (let i = 0, l = presentationsData.length; i < l; i++) {
      const presentationsItem = presentationsData[i];

      const newPresentationsItem = { ...presentationsItem };

      if (!newPresentationsItem.note.value) {
        newPresentationsItem.note.errorText = DEFAULT_ERROR_TEXT;

        hasValidateErrors = true;
      }

      newPresentationsData.push(newPresentationsItem);
    }

    if (hasValidateErrors) {
      setPresentationsData(newPresentationsData);
    }

    return !hasValidateErrors;
  };

  const saveAndCloseModal = useCallback(() => {
    const saveAndClose = async () => {
      const hasAnyChanges = hasAnyChangesInData(startedPresentationsData, presentationsData);

      if (!hasAnyChanges) {
        hideModal();
        return;
      }

      const isValidData = validateData();

      if (isValidData) {
        const requestUrl = API_getPresentationsBulk();

        const requestData = getRequestDataFromPresentationsData(presentationsData);

        await saveDataRequest(requestData, requestUrl);

        hideModal();

        window.location.reload();
      }
    };

    saveAndClose();
  }, [hasAnyChangesInData, hideModal]);

  return (
    <>
      <Heading>
        Новое предъявление
      </Heading>
      <Row direction={'row'}>
        <Column width={250}>
          <DatePicker
            label='Дата предъявления'
            onChange={handleForm}
            name='date'
            calendarValue={fieldsData.date.value}
            displayedValue={prepareDateToSee(fieldsData.date.value)}
            required={fieldsData.date.required}
            errorText={fieldsData.date.errorText}
          />
        </Column>
        <Column>
          <Textarea placeholder={'Содержание предъявления'} onChange={handleForm} name={'note'} value={fieldsData.note.value} required={fieldsData.note.required} errorText={fieldsData.note.errorText} label={'Содержание предъявления'} />
        </Column>
      </Row>
      <Row>
        <Group align='right'>
          <Button label='Добавить предъявление' onClick={onAddPresentationButtonClick} />
        </Group>
      </Row>
      {presentationsData.length ? (
        <>
          <Heading>
            Список предъявлений
          </Heading>
          <Row>
            {renderData(presentationsData)}
          </Row>
        </>
      ) : null}
      <Group align='right'>
        <Button label='Закрыть' theme='hollow' onClick={hideModal} />
        <Button label='Сохранить' onClick={saveAndCloseModal} />
      </Group>
    </>
  );
};

const Heading = styled.div`
  margin-bottom: 20px;
  ${({ theme }) => theme.mixins.gothamBold()};
  font-size: ${({ theme }) => theme.fonts.sizes[USER_FONT_SIZE_CONST_MD]};
`

NewPresentation.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default NewPresentation;