import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const PageHeading = ({children}) => {
    return (
        <PageHeadingContainer>
            {children}
        </PageHeadingContainer>
    );
};

const PageHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //margin-bottom: 10px;
  padding: 10px 10px 0 10px;
`

PageHeading.propTypes = {
    children: PropTypes.any.isRequired,
};

export default PageHeading;