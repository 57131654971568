import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Card from "./Card";

const Cards = ({ items, theme, modalType, addBetween, disabled = false }) => {
    const allIds = items.map(({ id }) => id.value);


    return (
        <CardsContainer>
            {items.map((item, idx) => <Card disabled={disabled} allIds={allIds} key={`card-${item.id.value}`} item={item} modalType={modalType} route={item.route} theme={theme} addBetween={addBetween} last={items.length - 1 === idx} />)}
        </CardsContainer>
    );
};

const CardsContainer = styled.div`
  flex: 1 1 auto;
`

Cards.propTypes = {
    items: PropTypes.array.isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
};

Cards.defaultProps = {
    theme: 'dark',
}

export default Cards;