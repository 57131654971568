import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import EditObjectModal from '../Forms/EditObjectModal';
import { useRouteMatch } from 'react-router';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { setProperties } from '../../utils/setProperties';
import { API_getContractObjects } from '../../utils/api';
import PageConstructor from '../PageConstructor';
import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Loader from '../Loader';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import { searchIcons } from '../BankGuarantees/components';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const tableFields = [
  {
    id: 1,
    name: 'building_number',
    label: 'Строительный номер',
    props: {
      width: '150px',
    },
  },
  {
    id: 2,
    name: 'name',
    label: 'Название',
    props: {
      width: '250px',
    }
  },
  {
    id: 3,
    name: 'contract_name',
    label: 'Название контракта',
  },
  {
    id: 4,
    name: 'delivery_port',
    label: 'Порт поставки',
    props: {
      width: '200px',
    }
  },
  {
    id: 5,
    name: 'registration_port',
    label: 'Порт регистрации',
    props: {
      width: '200px',
    }
  },
  {
    id: 6,
    name: 'price',
    label: 'Стоимость',
    props: {
      width: '200px',
    }
  },
  {
    id: 7,
    name: 'note',
    label: 'Примечание',
    props: {
      width: '200px',
    }
  },
];

export const prepareContractObjects = allItems => {
  return allItems.map(properties => {
    const {
      registration_port,
      delivery_port,
      building_number,
      contract_name,
      price,
      name,
      note,
      id,
    } = properties;

    const propertiesItem = setProperties({
      registration_port: { value: registration_port },
      delivery_port: { value: delivery_port },
      building_number: { value: building_number },
      contract_name: { value: contract_name },
      price: { value: price, isPrice: true },
      name: { value: name },
      note: { value: note },
      id: { value: id },
    });

    return propertiesItem;
  });
};

const ContractObjects = () => {
  const history = useHistory();
  const location = useLocation();
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);

  const requestUrl = API_getContractObjects([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const onRowClick = useCallback(item => {
    const { id: objectId } = item;

    history.push(`${location.pathname}/${objectId.value}/about`, item);
  }, [history, location]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onSort,
      sort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск объектов' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} onSort={onSort} sort={sort} onRowClick={onRowClick} fields={tableFields} data={filteredRecievedItems} modalType='objects' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label={'Добавить новый объект'} onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, [
    ...tableFields,
    { name: 'numeric[][]', label: 'Сумма', withoutPrefix: true },
  ]);

  const objectsProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'registration_port',
      'delivery_port',
      'building_number',
      'contract_name',
      'price',
      'name',
      'note',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditObjectModal,
      name: 'new-object',
      title: 'Новый объект',
      props: { contractId },
    },

    prepareRecievedItemsFunction: allItems => prepareContractObjects(allItems),

    requestUrl,

    EXPORT_MODULE_NAME: 'objects',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...objectsProps} />;
};

export default ContractObjects;