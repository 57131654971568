import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useRouteMatch } from 'react-router';
import { useSelector } from "react-redux";

import Item from "./Item";
import styled from "styled-components";
import Icon from "../Icon";
import ArrowIcon from "../Icons/ArrowIcon";
import { routes } from '../../routes';
import { tabsList as contractTabsList, TAB_CHARACTERISTICS } from '../../pages/Contract/tabs';
import { TABS_ALL as objectTabsList, TAB_ABOUT } from '../../pages/ObjectPage';
import { DEFAULT_EMPTY_PROPERTY } from '../../utils/setProperties';
import { checkIsWatcherRole } from '../../utils/auth';

const Breadcrumbs = () => {
    const route = useRouteMatch()
    const { state: locationState } = useLocation()
    const state = useSelector(state => state.breadcrumbs);

    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const getStateValue = value => value === DEFAULT_EMPTY_PROPERTY ? '…' : value;

    const getBreadCrumbs = useCallback(() => {
        const breadcrumbs = [];

        switch (route.path) {
            case routes.users.path:
                breadcrumbs.push({
                    label: routes.users.title,
                    name: 'users',
                });
                break;

            case routes.contracts.path:
                breadcrumbs.push({
                    label: routes.contracts.title,
                    name: 'contracts',
                    path: routes.contracts.path,
                });
                break;

            case routes.archive.path:
                breadcrumbs.push({
                    label: routes.contracts.title,
                    name: 'contracts',
                    path: routes.contracts.path,
                });
                breadcrumbs.push({
                    label: routes.archive.title,
                    name: 'archive',
                    path: routes.archive.path,
                });
                break;
        }

        const { id: contractId, type: contractType, objectId, type: objectType } = route.params;

        const contractNumber = getStateValue(state.contractNumber);

        if (contractId) {
            breadcrumbs.push({
                label: routes.contracts.title,
                name: 'contracts',
                path: routes.contracts.path,
            });

            breadcrumbs.push({
                label: contractNumber,
                name: 'contract',
                path: routes.contracts.path + '/' + contractId + '/' + TAB_CHARACTERISTICS.key,
            });
        }

        if (contractType) {
            for (let i = 0; i < contractTabsList.length; i++) {
                const { key, label } = contractTabsList[i];

                if (key === contractType) {
                    breadcrumbs.push({
                        label,
                        name: key,
                        path: routes.contracts.path + '/' + contractId + '/' + key,
                    });
                }
            }
        }

        if (objectType && objectId) {
            breadcrumbs.push({
                label: 'Объекты',
                name: 'objects',
                path: checkIsWatcherRole() ?
                    routes.watcherObjects.path :
                    (routes.contracts.path + '/' + contractId + '/objects')
            });

            breadcrumbs.push({
                label: getStateValue(state.buildingNumber),
                name: 'object',
                path: checkIsWatcherRole() ?
                    routes.watcherObjects.path + '/' + objectId + '/UP' :
                    routes.contracts.path + '/' + contractId + '/objects/' + objectId + '/' + TAB_ABOUT.key,
            });

            for (let i = 0; i < objectTabsList.length; i++) {
                const { key, label } = objectTabsList[i];

                if (key === objectType) {
                    breadcrumbs.push({
                        label,
                        name: key,
                        path: routes.contracts.path + '/' + contractId + '/objects/' + objectId + '/' + key,
                    });
                }
            }
        }

        return breadcrumbs;
    }, [route, locationState, state]);

    useEffect(() => {
        const breadcrumbsNew = getBreadCrumbs();

        setBreadcrumbs(breadcrumbsNew);
    }, [getBreadCrumbs]);

    return (
        <BreadcrumbsContainer>
            {breadcrumbs.map((item, index) => (
                <React.Fragment key={index}>
                    <Item key={`breadcrumb-${item}`} item={item} last={index === breadcrumbs.length - 1} />
                    {index !== (breadcrumbs.length - 1) && (
                        <IconWrapper>
                            <Icon icon={ArrowIcon} />
                        </IconWrapper>
                    )}
                </React.Fragment>
            ))}
        </BreadcrumbsContainer>
    );
};

const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
`

Breadcrumbs.propTypes = {
};

export default Breadcrumbs;