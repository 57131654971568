import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import File from "./File";

const FilesList = ({list}) => {
    return (
        <FilesListContainer>
            {list.map(file => <File key={`file-${file.id}`} file={file} />)}
        </FilesListContainer>
    );
};

const FilesListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

FilesList.propTypes = {
    list: PropTypes.array.isRequired,

};

export default FilesList;