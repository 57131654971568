import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import TableRow from "./TableRow";

const TableBody = (props) => {
    const { data, fields, controls, onRowClick, controlsList, addBetween, modalType, modalProps, disabled } = props;
    const allIds = data.map(({ id }) => id.value);

    return (
        <TableBodyContainer>
            {data.map((item, rowIndex) => (
                <TableRow
                    key={`table-row-${item.id.value}`}
                    item={item}
                    rowIndex={rowIndex}
                    fields={fields}
                    controls={controls}
                    onRowClick={onRowClick}
                    controlsList={controlsList}
                    addBetween={addBetween}
                    modalType={modalType}
                    modalProps={modalProps}
                    allIds={allIds}
                    lastItem={data.length - 1 === rowIndex}
                    disabled={disabled}
                />
            ))}
        </TableBodyContainer>
    );
};

const TableBodyContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  align-self: flex-start;
  min-width: 100%;
`

TableBody.propTypes = {
    data: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    controls: PropTypes.bool,
    onRowClick: PropTypes.func,
    controlsList: PropTypes.array,
    addBetween: PropTypes.bool,
};

export default TableBody;