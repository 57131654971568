import React from 'react';

import { API_getContractObjectsEditAndDeleteUrl } from '../../utils/api';

import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteObject = ({ hideModal, data }) => {
  const objectId = data.id.value;

  const deleteModalProps = {
    hideModal,  

    DELETE_REQUEST_URL: API_getContractObjectsEditAndDeleteUrl(objectId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить объект',
    DELETE_STATIC_VALUE: data.name.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteObject;