import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {useDispatch, useSelector} from 'react-redux';

import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import { searchIcons } from '../AboutObject/components';
import PageContent from '../styled/PageContent';
import PageContainer from '../styled/PageContainer';
import styled from 'styled-components';
import Card from '../Cards/Card';
import Checkbox from '../Checkbox';
import { getObjectIdFromRoute } from '../../utils/getObjectIdFromRoute';
import { getAuthorizationHeader } from '../../utils/auth';
import { fetchRequest } from '../../utils/fetchRequest';
import { API_getContractObjectsEditUrl, API_getObjectWatchers, API_OBJECT_WATHERS_EXPORT_URL, API_URL_USERS } from '../../utils/api';
import { filterItemsBySearchText } from '../../utils/filterItemsBySearchText';
import Loader from '../Loader';
import { keyToValue } from '../../utils/dataConversion';
import { setExportState } from '../../features/export/exportReducer';

const tableFields = [
  {
    name: 'fullname',
    label: keyToValue('fio'),
    width: 500,
  },
  {
    name: 'note',
    label: keyToValue('note'),
    width: 700,
  },
  {
    name: 'checked',
    label: 'Статус'
  },
];

const Watchers = () => {
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const WATCHERS_INITIAL = [];

  const dispatch = useDispatch();

  const [allWatchers, setAllWatchers] = useState(WATCHERS_INITIAL);
  const [filteredWatchers, setFilteredWatchers] = useState(WATCHERS_INITIAL);
  const [searchValue, setSearchValue] = useState('');
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  const route = useRouteMatch();
  const objectId = getObjectIdFromRoute(route);

  const getRequestData = useCallback((newAllWatchers) => {
    const selectedUsers = newAllWatchers.filter(watcher => watcher.checked.value);
    const selectedPreparedUsers = selectedUsers.map(userData => userData.id.value);

    return {
      users_ids: selectedPreparedUsers,
    };
  }, []);

  const requestUrl = API_getContractObjectsEditUrl(objectId);

  const getExportData = () => {
    return {
      watchers: allWatchers.map(({ fio, note, checked }) => {
        return {
          checked: checked.value,
          fullname: fio.value || '',
          note: note.value || '',
        };
      }),
      export: {
        checked: 'Выбран',
        fullname: 'ФИО',
        note: 'Заметка',
      }
    };
  };

  const exportData = getExportData();

  useEffect(() => {
    dispatch(setExportState({
      exportUrl: API_OBJECT_WATHERS_EXPORT_URL,
      exportName: 'watchers',
      exportData,
    }));
  }, [exportData]);

  const updateWatchersRequest = useCallback((newAllWatchers) => {
    const getData = async () => {
      setUpdateLoading(true);

      const requestData = getRequestData(newAllWatchers);
      const options = {
        method: 'PUT',
        headers: {
          'Authorization': getAuthorizationHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      };

      await fetchRequest(requestUrl, options);

      setUpdateLoading(false);
    };

    getData();
  }, [getRequestData, setUpdateLoading, objectId, requestUrl]);

  const prepareObjectWatchers = useCallback((usersResponse, watchersResponse) => {
    const preparedUsersResponse = usersResponse.filter(user => user.role === 0);
    const watchersUserIds = watchersResponse.users.map(user => user.id);

    return preparedUsersResponse.map(item => {
      const {
        fullname,
        company,
        id,
      } = item;

      const checked = watchersUserIds.includes(id);

      const propertiesItem = {
        id: { value: id },
        fio: { value: fullname, width: 500 },
        note: { value: company, width: 700 },
        checked: { value: checked },
      };

      return propertiesItem;
    });
  }, []);

  const fetchStartData = useCallback(async (url) => {
    const options = {
      headers: {
        'Authorization': getAuthorizationHeader(),
      },
    };

    const { response, success } = await fetchRequest(url, options);

    return success && response.data;
  }, [objectId]);

  const getData = useCallback(async () => {
    const watchersResponse = await fetchStartData(API_getObjectWatchers(objectId));
    const usersResponse = await fetchStartData(API_URL_USERS);

    const watchersObject = prepareObjectWatchers(usersResponse, watchersResponse);

    if (watchersObject) {
      setAllWatchers(watchersObject);
      setFilteredWatchers(watchersObject);
    }
  }, [objectId, setAllWatchers, setFilteredWatchers]);

  useEffect(() => {
    getData();
  }, []);

  const handleSearchValue = useCallback(({ value: searchValue }) => {
    setSearchValue(searchValue)

    if (!searchValue) {
      setFilteredWatchers(allWatchers);
      return;
    }

    const FILTERED_PROPERTIES = [
      'fio',
      'note',
    ];

    const filteredItems = filterItemsBySearchText(allWatchers, FILTERED_PROPERTIES, searchValue);

    setFilteredWatchers(filteredItems);
  }, [allWatchers, setFilteredWatchers]);

  const isLoaderVisible = Boolean(!allWatchers.length);

  const changeWatchersCheckedValueById = useCallback((watchersArray, selectedWatcherId, checkedValue) => {
    const preparedWatchers = watchersArray.map(watcherData => {
      const { id: watcherId } = watcherData;

      const isModifiedWatcher = watcherId.value === selectedWatcherId;

      if (isModifiedWatcher) {
        return {
          ...watcherData,
          checked: {
            value: checkedValue,
          },
        };
      }

      return watcherData;
    });

    return preparedWatchers;
  }, []);

  const handleCheckbox = useCallback(({ id, checked: checkedValue }) => {
    if (isUpdateLoading || isArchive) return;

    const newFileterdWatchers = changeWatchersCheckedValueById(filteredWatchers, id, checkedValue);
    const newAllWatchers = changeWatchersCheckedValueById(allWatchers, id, checkedValue);

    setFilteredWatchers(newFileterdWatchers);
    setAllWatchers(newAllWatchers);

    updateWatchersRequest(newAllWatchers);
  }, [isUpdateLoading, setFilteredWatchers, setAllWatchers, allWatchers, filteredWatchers]);

  return (
    <PageContainer>
      <PageHeading>
        <SearchWrapper>
          <Input icon={searchIcons} value={searchValue} placeholder='Поиск по наблюдателям' name='search' onChange={handleSearchValue} />
        </SearchWrapper>
      </PageHeading>
      <PageContent>
        <Loader isLoading={isLoaderVisible} />
        {filteredWatchers.length ? (
          <CheckboxList isUpdateLoading={isUpdateLoading}>
            {
              filteredWatchers.map(item => {
                return <Checkbox
                  key={item.id.value}
                  id={item.id.value}
                  render={<Card plain={true} controls={false} item={{ id: item.id, fio: item.fio, note: item.note }} />}
                  checked={item.checked.value}
                  onChange={handleCheckbox}
                />
              })
            }
          </CheckboxList>
        ) : null}
      </PageContent>
    </PageContainer>
  );
};

const CheckboxList = styled.div`
  opacity: ${({ isUpdateLoading }) => isUpdateLoading && `0.5`};
  margin-bottom: 10px;
`

export default Watchers;