import React, { useCallback, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getAuthorizationHeader } from '../../utils/auth';
import { fetchRequest } from '../../utils/fetchRequest';
import Row from '../Structure/Row';
import Group from '../Structure/Group';
import Button from '../Button';
import { API_getLimitBudgetaryObligations, API_getLimitBudgetaryObligationsEditAndDeleteUrl } from '../../utils/api';
import Input from '../Input';

const AddNewLimitsYearModal = (props) => {
  const { hideModal, responseData, data } = props;
  const isEmptyLimitsTable = !Boolean(responseData);

  const contractId = data?.contractId;
  const years = responseData?.years;
  const limitId = responseData?.id;

  const yearDefault = {
    value: '',
    required: true,
    errorText: '',
  };

  const [year, setYear] = useState(yearDefault);

  const handleForm = useCallback(({ value }) => {
    setYear({
      ...year,
      value,
      errorText: ''
    })
  }, [year]);

  const getRequestData = useCallback((selectedYear) => {
    if (isEmptyLimitsTable) {
      const requestData = {
        contract_id: contractId,
        years: [selectedYear],
        prices: [[0]],
      };

      return requestData;
    } else {

      const requestData = {
        contract_id: responseData.contract_id,
        notes: [].concat(responseData.notes),
        update_dates: [].concat(responseData.update_dates),
        years: [].concat(responseData.years),
        prices: [].concat(responseData.prices),
      };

      let APPEND_POSITION = 0;

      for (let i = 0; i < years.length; i++) {
        const year = years[i];

        if (selectedYear < year && i === 0) {
          break;
        }

        if (selectedYear > year) {
          APPEND_POSITION = i + 1;
        }
      }

      requestData.years.splice(APPEND_POSITION, 0, selectedYear);
      requestData.prices = requestData.prices.map(prices => {
        return [...prices.slice(0, APPEND_POSITION), 0, ...prices.slice(APPEND_POSITION)];
      });

      return requestData;
    }
  }, [contractId]);

  const sendRequest = useCallback(async (selectedYear) => {
    const requestData = getRequestData(selectedYear);

    const options = {
      method: isEmptyLimitsTable ? 'POST' : 'PUT',
      headers: {
        'Authorization': getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    };

    const url = isEmptyLimitsTable ? API_getLimitBudgetaryObligations() : API_getLimitBudgetaryObligationsEditAndDeleteUrl(limitId);

    const { success } = await fetchRequest(url, options);

    if (success) {
      window.location.reload();
    }
  }, [responseData, years, limitId, getRequestData]);

  const onSubmitAction = () => {
    const selectedYear = Number(year.value);

    if (!isEmptyLimitsTable) {
      let isTheSameYear = false;

      for (let i = 0, l = years.length; i < l; i++) {
        const yearResponse = years[i];

        if (Number(yearResponse) === selectedYear) {
          isTheSameYear = true;
          break;
        }
      }

      if (isTheSameYear) {
        setYear({
          ...year,
          errorText: 'Такой год уже существует'
        });

        return;
      }
    }

    if (selectedYear < 999 || selectedYear > 9999) {
      setYear({
        ...year,
        errorText: 'Введите положительное 4-х значное значение',
      });

      return;
    }

    sendRequest(selectedYear);
  };

  return (
    <ConfirmDeleteContainer>
      <Row>
        <Input
          type='number'
          label='Новый год'
          placeholder='Введите новый год'
          onChange={handleForm}
          value={year.value}
          required={year.required}
          errorText={year.errorText}
          name='year'
        />
      </Row>
      <Group align='right'>
        <Button label='Закрыть' theme='hollow' onClick={hideModal} />
        <Button label='Создать' onClick={onSubmitAction} />
      </Group>
    </ConfirmDeleteContainer>
  );
};

const ConfirmDeleteContainer = styled.div`
  ${({ theme }) => theme.mixins.gothamRegular()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

const ContextName = styled.span`
  ${({ theme }) => theme.mixins.gothamSemibold()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

AddNewLimitsYearModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default AddNewLimitsYearModal;