import { createSlice } from '@reduxjs/toolkit';
import {
    getContractIdFromStorage,
    getContractNameFromStorage,
    getContractNumberFromStorage,
    getObjectIdFromStorage,
    getBuildingNumberFromStorage,
    getContractIsArchiveFromStorage
} from '../../utils/breadcrumbsLocalStorage';

export const breadcrumbsReducer = createSlice({
    name: 'up',
    initialState: {
        contractId: getContractIdFromStorage() || null,
        contractName: getContractNameFromStorage() || null,
        contractNumber: getContractNumberFromStorage() || null,
        objectId: getObjectIdFromStorage() || null,
        buildingNumber: getBuildingNumberFromStorage() || null,
        isArchive: getContractIsArchiveFromStorage() === "true" || false
    },
    reducers: {
        setBreadcrumbsData: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { setBreadcrumbsData } = breadcrumbsReducer.actions;

export default breadcrumbsReducer.reducer;
