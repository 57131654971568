import { checkIsAuthorized } from './auth';
import { routes } from '../routes';
import { useHistory } from 'react-router';

export const withAuth = (Component) => {
  return () => {
    const history = useHistory();
    const isAuthorized = checkIsAuthorized();

    if (isAuthorized) {
      return <Component />;
    }

    history.push(routes.login.path);
    return null;
  };
};