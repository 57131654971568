import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Input from '../../Input';
import Row from '../../Structure/Row';
import Column from '../../Structure/Column';
import DatePicker from '../../DatePicker';
import Textarea from '../../Textarea';
import Group from '../../Structure/Group';
import Button from '../../Button';
import { API_URL_CONTRACTS, API_getContractEditAndDeleteUrl } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import { prepareDateToSee } from '../../../utils/dates';

const prepareEditData = (props) => {
  const data = props.data || {};
  const item = data.item || {};
  const isEditable = data.isEditable;
  const hideModal = props.hideModal;

  const prepareItem = item => {
    return Object.keys(item).reduce((result, key) => {
      const { value, isDate } = item[key];

      let preparedValue = value;

      if (isDate) {
        preparedValue = new Date(preparedValue);
      }

      result[key] = preparedValue;

      return result;
    }, {});
  };

  return {
    item: prepareItem(item),
    isEditable,
    hideModal
  };
};

const EditContractModal = (props) => {
  const { item, isEditable, hideModal } = prepareEditData(props);
  const itemId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ? API_getContractEditAndDeleteUrl(itemId) : API_URL_CONTRACTS;

    return requestUrl;
  }, [isEditable, itemId]);

  const contractFieldsData = {
    name: {
      required: true,
      value: item.name || '',
      errorText: '',
    },
    number: {
      required: true,
      value: item.number || '',
      errorText: '',
    },
    counterpart: {
      required: true,
      value: item.counterpart || '',
      errorText: '',
    },
    price: {
      required: true,
      value: item.price || '',
      errorText: '',
      isPrice: true,
    },
    date: {
      required: true,
      value: item.date || '',
      errorText: '',
      isDate: true,
    },
    program_name: {
      required: true,
      value: item.program_name || '',
      errorText: '',
    },
    deadline: {
      required: true,
      value: item.deadline || '',
      errorText: '',
      isDate: true,
    },
    note: {
      required: false,
      value: item.note || '',
      errorText: '',
    },
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    return (
      <>
        <Row>
          <Input
            placeholder='Введите наименование контракта'
            onChange={handleForm}
            label='Наименование'
            {...getFieldProps('name')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Укажите номер гос. контракта'
            label='Номер гос. контракта'
            onChange={handleForm}
            {...getFieldProps('number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Напишите наименование контрагента'
            label='Контрагент'
            onChange={handleForm}
            {...getFieldProps('counterpart')}
          />
        </Row>
        <Row>
          <Input
            type='number'
            placeholder='Укажите цену контракта в рублях'
            label='Цена контракта (руб.)'
            onChange={handleForm}
            {...getFieldProps('price')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Напишите наименование программы'
            label='Наименование программы'
            onChange={handleForm}
            {...getFieldProps('program_name')}
          />
        </Row>
        <Row direction='row'>
          <Column>
            <DatePicker
              label='Дата подписания контракта'
              onChange={handleForm}
              name='date'
              calendarValue={fieldsData.date.value}
              displayedValue={prepareDateToSee(fieldsData.date.value)}
              required={fieldsData.date.required}
              errorText={fieldsData.date.errorText}
            />
          </Column>
          <Column>
            <DatePicker
              label='Окончание выполнения работ'
              onChange={handleForm}
              name='deadline'
              calendarValue={fieldsData.deadline.value}
              displayedValue={prepareDateToSee(fieldsData.deadline.value)}
              required={fieldsData.deadline.required}
              errorText={fieldsData.deadline.errorText}
            />
          </Column>
        </Row>
        <Row>
          <Textarea
            onChange={handleForm}
            placeholder='Примечание'
            value={fieldsData.note.value}
            name='note'
            label='Примечание'
          />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editContractModalProps = {
    fieldsData: contractFieldsData,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,
  };

  return <ModalConstructor {...editContractModalProps} />
};

EditContractModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditContractModal;