import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { Label } from "../Input";
import Error from '../Error';

const Textarea = ({ name, value, onChange, placeholder, label, required, errorText, height }) => {

    const handleChange = (value) => {
        onChange({ name, value })
    }

    return (
        <TextareaContainer height={height}>
            {label && (
                <Label required={required} error={!!errorText}>
                    {label}
                </Label>
            )}
            <textarea name={name} value={value} onChange={(e) => handleChange(e.currentTarget.value)} placeholder={placeholder} />
            {errorText && (
                <ErrorWrapper>
                    <Error error={errorText} />
                </ErrorWrapper>
            )}
        </TextareaContainer>
    );
};

const TextareaContainer = styled.div`
  textarea {
    resize: none;
    width: 100%;
    border-radius: ${({ theme }) => theme.other.borderRadiusNormal};
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.border};
    outline: none;
    min-height: ${({height}) => height}px;
    padding: 10px;
    ${({ theme }) => theme.mixins.gothamRegular};
    font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
    ::placeholder {
      opacity: .3;
    }
  }
`

const ErrorWrapper = styled.div`
  margin-top: 3px;
`

Textarea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    height: PropTypes.number,
};

Textarea.defaultProps = {
    height: 100,
}

export default Textarea;