import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Column = ({children, width}) => {
    return (
        <ColumnContainer width={width}>
            {children}
        </ColumnContainer>
    );
};

const ColumnContainer = styled.div`
  flex: ${({width}) => width ? '0 0 auto' : '1 1 auto'};
  margin-right: 20px;
  width: ${({width}) => width ? width + 'px' : 'auto'};
  :last-child {
    margin-right: 0;
  }
`

Column.propTypes = {
    children: PropTypes.any.isRequired,
    width: PropTypes.number,
};

export default Column;