import React, { useCallback, useState } from 'react';

import PageContainer from '../styled/PageContainer';
import PageContent from '../styled/PageContent';
import Input from '../Input';
import { searchIcons } from '../ContractCharacteristics/components';
import PageHeading from '../styled/PageHeading';
import Button from '../Button';
import PageFooter from '../styled/PageFooter';
import { useModal } from '../../hooks/useModal';
import Table from '../Table';
import { UPGrouptableFields as tableFields } from './components';
import Icon from '../Icon';
import FullArrowIcon from '../Icons/FullArrowIcon';
import styled from 'styled-components';
import StatItem from '../StatItem';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleGroupsPanel } from '../../features/app/appSlice';
import { IconWrapper } from '../GroupUP';
import { filterItemsBySearchText } from '../../utils/filterItemsBySearchText';
import EditGroupRecordModal from '../Forms/EditGroupRecordModal';
import { getGroupIdFromRoute } from '../../utils/getGroupIdFromRoute';
import FilterIcon from '../Icons/FilterIcon';
import Filters from '../Filters';
import { DEFAULT_FILTERS_DATA, filterClosedDateItems } from '../../utils/filtersHelpers';
import Loader from '../Loader';

const GroupContent = (props) => {
  const { filteredObjectsData, objectsData, setFilteredObjectsData, filtersData, setFiltersData, isLoading } = props;

  const {isArchive} = useSelector(state => state.breadcrumbs);

  const route = useRouteMatch();
  const groupId = getGroupIdFromRoute(route);

  const totalUPCount = filteredObjectsData.length;
  const closedUPCount = filteredObjectsData.filter(item => Boolean(item.closing_date_real.value)).length;
  const overdueUPCount = filteredObjectsData.filter(item => (!(item.closing_date_real.value) && +(new Date(item.closing_date_planned.value)) < +(new Date())) || (!!(item?.closing_date_real?.value) && !!(item?.closing_date_planned?.value) && item.closing_date_planned.value < item.closing_date_real.value)).length;

  const [searchValue, setSearchValue] = useState('');
  const [isFiltersVisible, setFiltersVisible] = useState(false);

  const history = useHistory()
  const dispatch = useDispatch()
  const app = useSelector(state => state.app)

  const [showModal] = useModal({
    component: EditGroupRecordModal,
    name: 'new-group-record',
    title: 'Новая запись',
    props: { groupId },
  });

  const toggleGroups = () => {
    dispatch(toggleGroupsPanel())
  }

  const handleSearchValue = useCallback(({ value: searchValue }) => {
    setSearchValue(searchValue);

    if (!searchValue) {
      setFilteredObjectsData(objectsData);
      return;
    }

    const FILTERED_PROPERTIES = [
      'number',
      'name',
      'current_documentation',
      'closing_date_real',
      'closing_date_planned',
    ];

    const filteredItems = filterItemsBySearchText(objectsData, FILTERED_PROPERTIES, searchValue);

    setFilteredObjectsData(filteredItems);
  }, [objectsData, setFilteredObjectsData]);

  const toggleFilters = () => {
    setFiltersVisible(!isFiltersVisible)
  }

  const onFiltersSubmit = (filterProps) => {
    const objectsDataNew = filterClosedDateItems([...objectsData], filterProps);

    setFilteredObjectsData(objectsDataNew);
    setSearchValue('');
  };

  return (
    <ContentWrapper>
      <PageContainer>
        <PageHeading>
          {groupId && <SearchWrapper>
            <IconWrapper isActive={app.sideMenu}>
              <Icon icon={FullArrowIcon} onClick={toggleGroups} />
            </IconWrapper>
            <InputWrapper>
              <Input icon={searchIcons} value={searchValue} placeholder='Поиск по записям' name='search' onChange={handleSearchValue} />
            </InputWrapper>
            <FilterWrapper>
              <Icon icon={FilterIcon} onClick={toggleFilters} />
              {isFiltersVisible && <Filters handleHide={toggleFilters} filtersData={filtersData} onFiltersSubmit={onFiltersSubmit} setFiltersData={setFiltersData} DEFAULT_FILTERS_DATA={DEFAULT_FILTERS_DATA} />}
            </FilterWrapper>
            <Statistics>
              <StatItem label={'Количество УП'} value={totalUPCount} />
              <StatItem label={'Закрытые УП'} value={closedUPCount} />
              <StatItem label={'Просроченные УП'} value={overdueUPCount} />
            </Statistics>
          </SearchWrapper>}
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredObjectsData.length ? (
            <Table disabled={isArchive} addBetween={false} fields={tableFields} modalType='edit-group-content' data={filteredObjectsData} onRowClick={(item) => history.push(item.route.value, item.name.value)} />
          ) : null}
        </PageContent>
        {groupId && !isArchive && <PageFooter>
          <Button label='Добавить новую запись' onClick={showModal} />
        </PageFooter>}
      </PageContainer>
    </ContentWrapper>
  );
};

export const FilterWrapper = styled.div`
  margin-right: 10px;
  position: relative;
`

export const ContentWrapper = styled.div`
  flex: 1 1 auto;
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Statistics = styled.div`
  display: flex;
  align-items: center;
  
  > div {
    margin-right: 30px;
    :last-child {
      margin-right: 0;
    }
  }
`

export const InputWrapper = styled.div`
  margin-right: 10px;
`

GroupContent.propTypes = {

};

export default GroupContent;