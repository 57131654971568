import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import EditPaymentStageModal from '../Forms/EditPaymentStageModal';
import { getContractIdFromRoute } from '../../utils/getContractIdFromRoute';
import { API_getPaymentStages } from '../../utils/api';
import { setProperties } from '../../utils/setProperties';
import PageConstructor from '../PageConstructor';
import { tableFields } from './components';
import PageContainer from '../styled/PageContainer';
import PageHeading from '../styled/PageHeading';
import { SearchWrapper } from '../BankGuarantees';
import Input from '../Input';
import PageContent from '../styled/PageContent';
import Loader from '../Loader';
import Table from '../Table';
import PageFooter from '../styled/PageFooter';
import Button from '../Button';
import { searchIcons } from '../BankGuarantees/components';
import { prepareExportUrl } from '../../utils/prepareExportUrl';
import {useSelector} from "react-redux";

const PaymentStages = () => {
  const route = useRouteMatch();
  const contractId = getContractIdFromRoute(route);
  const {isArchive} = useSelector(state => state.breadcrumbs);

  const prepareRecievedItemsFunction = useCallback(allItems => {
    return allItems.map(properties => {
      const {
        id,
        amount,
        note,
        name,
        rationale,
        start_date,
        expiration_date_planned,
        expiration_date_real,
      } = properties;

      const propertiesItem = setProperties({
        id: { value: id },
        name: { value: name },
        note: { value: note },
        rationale: { value: rationale },
        amount: { value: amount, isPrice: true },
        start_date: { value: start_date?.date, isDate: true },
        expiration_date_planned: { value: expiration_date_planned?.date, isDate: true },
        expiration_date_real: { value: expiration_date_real?.date, isDate: true },
      });

      return propertiesItem;
    });
  }, []);

  const requestUrl = API_getPaymentStages([{
    column: 'contract_id',
    operator: '=',
    value: contractId,
  }]);

  const getTemplate = (props) => {
    const {
      searchValue,
      handleSearchValue,
      isLoading,
      filteredRecievedItems,
      showModal,
      onSort,
      sort,
    } = props;

    return (
      <PageContainer>
        <PageHeading>
          <SearchWrapper>
            <Input icon={searchIcons} value={searchValue} placeholder='Поиск по этапам' name='search' onChange={handleSearchValue} />
          </SearchWrapper>
        </PageHeading>
        <PageContent>
          <Loader isLoading={isLoading} />
          {filteredRecievedItems.length ? <Table disabled={isArchive} onSort={onSort} sort={sort} fields={tableFields} data={filteredRecievedItems} modalType='payment-stages' /> : null}
        </PageContent>
        <PageFooter>
          {
              !isArchive && <Button label='Добавить новый этап' onClick={showModal} />
          }
        </PageFooter>
      </PageContainer>
    );
  };

  const EXPORT_MODULE_URL = prepareExportUrl(requestUrl, [
    ...tableFields,
    { name: 'numeric[][]', label: 'Стоимость этапа', withoutPrefix: true },
    { name: 'numeric[][]', label: 'Сумма требования', withoutPrefix: true },
  ]);

  const paymentStagesProps = {
    FILTERED_SEARCH_PROPERTIES: [
      'name',
      'note',
      'amount',
      'rationale',
      'start_date',
      'expiration_date_planned',
      'expiration_date_real',
    ],

    isSortableTable: true,

    modalProps: {
      component: EditPaymentStageModal,
      name: 'new-payment-stage',
      title: 'Новый этап',
      props: { contractId },
    },

    prepareRecievedItemsFunction,

    requestUrl,

    EXPORT_MODULE_NAME: 'payment-stages',
    EXPORT_MODULE_URL,

    getTemplate,
  };

  return <PageConstructor {...paymentStagesProps} />;
};

export default PaymentStages;