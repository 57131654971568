import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Icon from "../../../Icon";
import ArrowIcon from "../../../Icons/ArrowIcon";

export const Item = ({width, item, onSort, sort, id, name, noSortable}) => {
    const onSortCallback = useCallback(() => {
        onSort && onSort({ id, direction: sort?.direction, key: name });
    }, [id, sort, name]);

    const isSortable = !noSortable && sort;

    return (
        <ItemContainer width={width} onClick={onSortCallback}>
            {item}
            {isSortable && <IconWrapper sort={sort}>
                <Icon size={30} icon={ArrowIcon} />
            </IconWrapper>}
        </ItemContainer>
    );
};

const calculateRotation = (sort) => {
    switch (sort) {
        case 'up':
            return 180
        case 'down':
            return 0
        default:
            return 0
    }
}

const ItemContainer = styled.div`
  width: ${({width}) => width};
  flex: ${({width}) => width === '100%' ? '1 1 auto' : '0 0 auto'};
  ${({theme}) => theme.mixins.gothamSemibold({color: theme.colors.primary})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  margin-right: 30px;
  cursor: pointer;
  min-height: 40px;
  padding: 3px 0;
  display: flex;
  align-items: center;
  :last-child {
    margin-right: 0;
  }
`

const IconWrapper = styled.div`
  transform-origin: 50% 50%;
  transition: transform ${({theme}) => theme.transitions.normal};
  transform: rotate(${({sort}) => calculateRotation(sort.direction)}deg);
`

Item.propTypes = {
    width: PropTypes.string,
    item: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired]),
    onSort: PropTypes.func,
    sort: PropTypes.object,
};

Item.defaultProps = {
    width: '100%',
}

export default Item;