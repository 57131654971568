import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";
import Group from "../../Structure/Group";
import Input from "../../Input";
import Row from "../../Structure/Row";
import { API_getLimitBudgetaryObligationsEditAndDeleteUrl } from '../../../utils/api';
import { fetchRequest } from '../../../utils/fetchRequest';
import { getAuthorizationHeader } from '../../../utils/auth';

const EditYearModal = (props) => {
  const { hideModal, data } = props;
  const { year: yearProps, responseData } = data;
  const { id: limitId, years: yearsResponse } = responseData;

  const oldYearIndexPosition = yearsResponse.findIndex(yearResponse => Number(yearResponse) === Number(yearProps));

  const [yearState, setYearState] = useState({
    value: yearProps,
    errorText: '',
  });

  const getRequestData = useCallback(() => {
    const requestData = {
      contract_id: responseData.contract_id,
      notes: [].concat(responseData.notes),
      update_dates: [].concat(responseData.update_dates),
      prices: [],
      years: [],
    };

    let yearsPrepared = responseData.years.map(year => {
      if (Number(year) === Number(yearProps)) {
        return Number(yearState.value);
      }

      return year;
    });

    yearsPrepared.sort((a, b) => a - b);

    const newYearIndexPosition = yearsPrepared.findIndex(yearPrepared => Number(yearPrepared) === Number(yearState.value));

    requestData.years.push(...yearsPrepared);

    const updatedPrices = [];

    for (let i = 0, l = responseData.prices.length; i < l; i++) {
      const pricesArray = [...responseData.prices[i]];

      const newPriceArray = [];
      for (let j = 0, k = pricesArray.length; j < k; j++) {
        const priceValue = pricesArray[j];

        if (j === oldYearIndexPosition) {
          newPriceArray.push(pricesArray[newYearIndexPosition]);
        } else if (j === newYearIndexPosition) {
          newPriceArray.push(pricesArray[oldYearIndexPosition])
        } else {
          newPriceArray.push(priceValue)
        }
      }

      updatedPrices.push(newPriceArray);
    }

    requestData.prices.push(...updatedPrices);

    return requestData;
  }, [yearState, responseData, yearProps]);

  const makeRequest = useCallback(async () => {
    const requestData = getRequestData();
    const options = {
      method: 'PUT',
      headers: {
        'Authorization': getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    };

    const url = API_getLimitBudgetaryObligationsEditAndDeleteUrl(limitId);
    const { success } = await fetchRequest(url, options);

    if (success) {
    window.location.reload();
    }
  }, [getRequestData]);

  const onCreateSubmit = useCallback(() => {
    const selectedYear = Number(yearState.value);
    let isTheSameYear = false;

    for (let i = 0, l = yearsResponse.length; i < l; i++) {
      const yearResponse = yearsResponse[i];

      if (Number(yearResponse) === selectedYear) {
        isTheSameYear = true;
        break;
      }
    }

    if (isTheSameYear) {
      setYearState({
        ...yearState,
        errorText: 'Такой год уже существует'
      });

      return;
    }

    if (selectedYear < 999 || selectedYear > 9999) {
      setYearState({
        ...yearState,
        errorText: 'Введите положительное 4-х значное значение'
      });

      return;
    }

    makeRequest();
  }, [yearState, yearsResponse]);

  const handleChange = useCallback(({ value }) => {
    setYearState({
      value,
      errorText: '',
    });
  }, []);

  return (
    <>
      <Row>
        <Input
          type='number'
          label='Год'
          placeholder='Напишите год'
          name='year'
          value={yearState.value}
          required={true}
          errorText={yearState.errorText}
          onChange={handleChange}
        />
      </Row>
      <Group align='right'>
        <Button label='Закрыть' theme='hollow' onClick={hideModal} />
        <Button label='Создать' onClick={onCreateSubmit} />
      </Group>
    </>
  );
};

EditYearModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditYearModal;