import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { API_getLimitBudgetaryObligationsEditAndDeleteUrl } from '../../utils/api';
import { getAuthorizationHeader } from '../../utils/auth';
import Button from '../Button';
import Group from '../Structure/Group';
import Row from '../Structure/Row';
import { fetchRequest } from '../../utils/fetchRequest';

const DeleteLimitsYearModal = (props) => {
  const { hideModal, data } = props;
  const { year, responseData } = data;
  const { id: limitId } = responseData;

  const getRequestData = useCallback(() => {
    const requestData = {
      contract_id: responseData.contract_id,
      notes: [].concat(responseData.notes),
      update_dates: [].concat(responseData.update_dates),
      years: [].concat(responseData.years),
      prices: [],
    };

    const selecterYearIndex = requestData.years.findIndex(requestYear => requestYear === year);

    const updatedPrices = [];
    for (let i = 0, l = responseData.prices.length; i < l; i++) {
      const priceArray = [...responseData.prices[i]];

      const newPriceArray = [];
      for (let j = 0, k = priceArray.length; j < k; j++) {
        if (j !== selecterYearIndex) {
          newPriceArray.push(priceArray[j]);
        }
      }

      updatedPrices.push(newPriceArray);
    }

    requestData.years.splice(selecterYearIndex, 1);
    requestData.prices.push(...updatedPrices);

    return requestData;
  }, [year, responseData]);

  const deleteYear = useCallback(() => {
    const sendRequest = async () => {
      const requestData = getRequestData();

      const options = {
        method: 'PUT',
        headers: {
          'Authorization': getAuthorizationHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      };

      const url = API_getLimitBudgetaryObligationsEditAndDeleteUrl(limitId);
      const { success } = await fetchRequest(url, options);

      if (success) {
        window.location.reload();
      }
    };

    sendRequest();
  }, [getRequestData, limitId]);

  return (
    <ConfirmDeleteContainer>
      <Row>
        Вы уверены, что хотите удалить год лимита:<ContextName>{year}</ContextName>
      </Row>
      <Group align='right'>
        <Button label='Отмена' theme='hollow' onClick={hideModal} />
        <Button label='Удалить' color='alert' onClick={deleteYear} />
      </Group>
    </ConfirmDeleteContainer>
  );
};

const ConfirmDeleteContainer = styled.div`
  ${({ theme }) => theme.mixins.gothamRegular()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

const ContextName = styled.span`
  ${({ theme }) => theme.mixins.gothamSemibold()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

DeleteLimitsYearModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default DeleteLimitsYearModal;