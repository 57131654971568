import React from 'react';
import PageContainer from "../styled/PageContainer";
import PageHeading from "../styled/PageHeading";
import Input from "../Input";
import PageContent from "../styled/PageContent";
import Loader from "../Loader";
import PageFooter from "../styled/PageFooter";
import Button from "../Button";
import {SearchWrapper} from "../BankGuarantees";
import SearchIcon from "../Icons/SearchIcon";
import {useModal} from "../../hooks/useModal";
import Table from "../Table";
import {ContentWrapper} from "../GroupContent";
import {tableData, tableFields} from "./components";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import NewPresentation from "../Forms/NewPresentation";

const Presentations = () => {

    const history = useHistory()

    const searchIcon = {
        id: 0,
        component: SearchIcon,
        position: 'left'
    };

    const [showModal] = useModal({
        component: NewPresentation,
        name: 'new-presentation',
        title: 'Новое предъявление'
    });

    return (
        <ContentWrapper>
            <PageContainer>
                <PageHeading>
                    <SearchWrapper>
                        <Input icon={[searchIcon]} value={''} placeholder='Поиск по предъявлениям' name='search' onChange={() => {}} />
                        <ButtonWrapper>
                            <Button label={'Назад'} onClick={() => history.goBack()} />
                        </ButtonWrapper>
                    </SearchWrapper>
                </PageHeading>
                <PageContent>
                    <Loader isLoading={false} />
                    <Table fields={tableFields} data={tableData} />
                </PageContent>
                <PageFooter>
                    <Button label='Добавить новое предъявление' onClick={showModal} />
                </PageFooter>
            </PageContainer>
        </ContentWrapper>
    );
};

const ButtonWrapper = styled.div`
  margin-left: 10px;
`

Presentations.propTypes = {

};

export default Presentations;