import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const StatItem = ({label, value}) => {
    return (
        <StatItemContainer>
            <Label>
                {label}:
            </Label>
            <Value>
                {value}
            </Value>
        </StatItemContainer>
    );
};

const StatItemContainer = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  ${({theme}) => theme.mixins.gothamRegular()};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  margin-right: 10px;
`

const Value = styled.div`
  ${({theme}) => theme.mixins.gothamBold()};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

StatItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
};

export default StatItem;