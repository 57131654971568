import React from 'react';

const ExitIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.653 20H4.329C3.18142 19.9982 2.08135 19.5411 1.26988 18.7288C0.458417 17.9166 0.00176145 16.8154 0 15.6667V4.33333C0.00176145 3.1846 0.458417 2.08343 1.26988 1.27115C2.08135 0.458876 3.18142 0.00176321 4.329 0H13.653V2H4.329C3.71078 2 3.11788 2.24583 2.68074 2.68342C2.24359 3.121 1.998 3.71449 1.998 4.33333V15.6667C1.998 16.2855 2.24359 16.879 2.68074 17.3166C3.11788 17.7542 3.71078 18 4.329 18H13.653V20Z" />
            <path d="M14.1392 15.7999L12.7273 14.3866L17.1762 9.93991L12.7273 5.48657L14.1392 4.07324L20 9.93991L14.1392 15.7999Z" />
            <path d="M18.5881 8.93994H7.19946V10.9399H18.5881V8.93994Z" />
        </svg>
    );
};

export default ExitIcon;