import FilesList from "../FilesList";

const filesList = [
    {
        id: 0,
        name: 'filename.png',
        link: '/file.png',
    },
    {
        id: 1,
        name: 'file.jpg',
        link: '/file.jpg',
    },
    {
        id: 2,
        name: 'excel.xls',
        link: '/file.xls',
    },
    {
        id: 3,
        name: 'filename.png',
        link: '/file.png',
    },
    {
        id: 4,
        name: 'file.jpg',
        link: '/file.jpg',
    },
    {
        id: 5,
        name: 'excel.xls',
        link: '/file.xls',
    },
]

export const tableFields = [
    {
        id: 0,
        name: 'number',
        label: 'Номер УХ',
        props: {
            width: '80px',
        },
    },
    {
        id: 1,
        name: 'name',
        label: 'Наименование УХ',
    },
    {
        id: 2,
        name: 'current_documentation',
        label: 'Действующая документация',
        props: {
            width: '300px',
        },
    },
    {
        id: 3,
        name: 'presentations',
        label: 'Дата предъявления',
        props: {
            width: '250px',
        },
    },
    {
        id: 4,
        name: 'closing_date_planned',
        label: 'Плановая дата закрытия УХ',
        props: {
            width: '100px',
        },
    },
    {
        id: 5,
        name: 'closing_date_real',
        label: 'Дата закрытия',
        props: {
            width: '100px',
        },
    },
    {
        id: 6,
        name: 'files',
        label: 'Фотографии и необходимые файлы',
        props: {
            width: '300px',
        },
    },
]