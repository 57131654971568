import {useDispatch} from "react-redux";
import {hide, registerModal, show, unregisterModal} from "../features/modal/modalSlice";

export const useModal = ({name, component, title, props}) => {

    const dispatch = useDispatch();

    const showModal = ({data = {}, modalComponent} = {}) => {
        dispatch(registerModal({
            name,
            title,
            component: component ? component : modalComponent,
            data,
            props
        }))
        dispatch(show(name))
    }

    const hideModal = () => {
        dispatch(hide(name))
        dispatch(unregisterModal(name))
    }

    return [showModal, hideModal]
}