import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Label} from "../Input";
import styled from "styled-components";
import {useAnchorPopup} from "../AnchorPopup/useAnchorPopup";
import List from "../List";
import Icon from "../Icon";
import ArrowIcon from "../Icons/ArrowIcon";

const Select = ({label, value, list, onChange}) => {

    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef()

    const onListItemClick = useCallback((...props) => {
        setIsOpen(false);

        onChange(...props);
    }, [setIsOpen, onChange]);

    const [showAnchorPopup, hideAnchorPopup] = useAnchorPopup({component: List, props: {list, align: 'left', onClick: onListItemClick}})

    const toggleSelect = useCallback((event) => {
        if (isOpen) {
            hideAnchorPopup(event)
        } else {
            showAnchorPopup(event, {width: ref?.current?.getBoundingClientRect().width})
        }

        setIsOpen(!isOpen)
    }, [isOpen, setIsOpen, hideAnchorPopup, showAnchorPopup]);

    return (
        <SelectContainer ref={ref}>
            {label && <Label>
                {label}
            </Label>}
            <SelectField onClick={toggleSelect}>
                <FieldValue>
                    {value}
                </FieldValue>
                <FieldIcon isOpen={isOpen}>
                    <Icon icon={ArrowIcon} />
                </FieldIcon>
            </SelectField>
        </SelectContainer>
    );
};

const SelectContainer = styled.div`
  
`

const SelectField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  border-radius: ${({theme}) => theme.other.borderRadiusNormal};
  background-color: #fff;
  border: 1px solid ${({theme}) => theme.colors.border};
  outline: none;
  min-height: 40px;
  padding: 0 0 0 10px;
  ${({theme}) => theme.mixins.gothamRegular};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  ::placeholder {
    opacity: .3;
  }
`

const FieldValue = styled.div`

`

const FieldIcon = styled.div`
  transform-origin: 50% 50%;
  transform: rotate(${({isOpen}) => isOpen ? 180 : 0}deg);
`

Select.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Select;