import React from 'react';

const CopyIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 22H12.5C11.3073 21.9974 10.1643 21.5224 9.32094 20.6791C8.4776 19.8357 8.00264 18.6927 8 17.5V4.5C8.00264 3.30734 8.4776 2.16428 9.32094 1.32094C10.1643 0.477595 11.3073 0.00264157 12.5 0H25.5C26.6927 0.00264157 27.8357 0.477595 28.6791 1.32094C29.5224 2.16428 29.9974 3.30734 30 4.5V17.5C29.9974 18.6927 29.5224 19.8357 28.6791 20.6791C27.8357 21.5224 26.6927 21.9974 25.5 22ZM12.5 3C12.1022 3 11.7206 3.15804 11.4393 3.43934C11.158 3.72064 11 4.10218 11 4.5V17.5C11 17.8978 11.158 18.2794 11.4393 18.5607C11.7206 18.842 12.1022 19 12.5 19H25.5C25.8978 19 26.2794 18.842 26.5607 18.5607C26.842 18.2794 27 17.8978 27 17.5V4.5C27 4.10218 26.842 3.72064 26.5607 3.43934C26.2794 3.15804 25.8978 3 25.5 3H12.5Z" />
            <path d="M17.5 30H4.5C3.30734 29.9974 2.16428 29.5224 1.32094 28.6791C0.477595 27.8357 0.00264157 26.6927 0 25.5V12.5C0.00264157 11.3073 0.477595 10.1643 1.32094 9.32094C2.16428 8.4776 3.30734 8.00264 4.5 8V11C4.10218 11 3.72064 11.158 3.43934 11.4393C3.15804 11.7206 3 12.1022 3 12.5V25.5C3 25.8978 3.15804 26.2794 3.43934 26.5607C3.72064 26.842 4.10218 27 4.5 27H17.5C17.8978 27 18.2794 26.842 18.5607 26.5607C18.842 26.2794 19 25.8978 19 25.5H22C21.9974 26.6927 21.5224 27.8357 20.6791 28.6791C19.8357 29.5224 18.6927 29.9974 17.5 30Z" />
        </svg>
    );
};

export default CopyIcon;