import React from 'react';
import Archive from "./pages/Archive";

const Login = React.lazy(() => import('./pages/Login'))
const Contracts = React.lazy(() => import('./pages/Contracts'))
const Contract = React.lazy(() => import('./pages/Contract'))
const UsersPage = React.lazy(() => import('./pages/UsersPage'))
const ObjectPage = React.lazy(() => import('./pages/ObjectPage'))
const WatcherObjects = React.lazy(() => import('./pages/watcher/WatcherObjects'))
const WatcherObject = React.lazy(() => import('./pages/watcher/WatcherObject'))

export const ACCESS_TYPE_ADMIN = 'admin';
export const ACCESS_TYPE_WATCHER = 'watcher';

export const routes = {
    login: {
        id: 0,
        path: '/login',
        title: 'Логин',
        component: Login,
        exact: true,
        access: [ACCESS_TYPE_ADMIN, ACCESS_TYPE_WATCHER],
    },
    contracts: {
        id: 1,
        path: '/contracts',
        title: 'Контракты',
        component: Contracts,
        exact: true,
        access: [ACCESS_TYPE_ADMIN],
    },
    archive: {
        id: 7,
        path: '/archive',
        title: 'Архив',
        component: Archive,
        exact: true,
        access: [ACCESS_TYPE_ADMIN],
    },
    contract: {
        id: 2,
        path: '/contracts/:id(\\d+)/:type',
        title: 'Контракт',
        component: Contract,
        exact: true,
        access: [ACCESS_TYPE_ADMIN],
    },
    users: {
        id: 3,
        path: '/users',
        title: 'Пользователи',
        component: UsersPage,
        exact: true,
        access: [ACCESS_TYPE_ADMIN],
    },
    object: {
        id: 4,
        path: '/contracts/:id(\\d+)/objects/:objectId/:type/:groupId?',
        title: 'Объекты',
        component: ObjectPage,
        access: [ACCESS_TYPE_ADMIN],
    },
    watcherObjects: {
        id: 5,
        path: '/watcherObjects',
        title: 'Объекты',
        component: WatcherObjects,
        exact: true,
        access: [ACCESS_TYPE_WATCHER],
    },
    watcherObject: {
        id: 6,
        path: '/watcherObjects/:objectId/:type/:groupId?',
        title: 'Объект',
        component: WatcherObject,
        access: [ACCESS_TYPE_WATCHER],
    }
};