import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Input, { Label } from "../Input";
import DateIcon from "../Icons/DateIcon";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { formatDate } from "react-calendar/src/shared/dateFormatter";
import { useAnchorPopup } from "../AnchorPopup/useAnchorPopup";
import CloseIcon from '../Icons/CloseIcon';
import {useSelector} from "react-redux";

const DatePicker = ({ label, onChange, name, calendarValue, displayedValue, errorText, required }) => {

    const anchorPopup = useSelector(state => state.anchorPopup)

    const handleChange = (date, event) => {
        hideAnchorPopup(event)
        onChange({ value: date, name })
    }

    const calendarIcon = [
        {
            id: 0,
            component: DateIcon,
            position: 'left',
        },
    ];

    if (displayedValue) {
        calendarIcon.push({
            id: 0,
            component: CloseIcon,
            position: 'right',
            options: {
                top: 0,
                right: 0,
                width: '40px',
                height: '40px',
                padding: '15px',
            },
            action: (event) => {
                event.stopPropagation();
                handleChange('', event);
            }
        });
    }

    const [showAnchorPopup, hideAnchorPopup] = useAnchorPopup({
        component: Calendar,
        props: {
            value: calendarValue,
            onChange: handleChange,
            locale: 'ru-RU',
            formatLongDate: (locale, date) => formatDate(date, 'YYYY-mm-dd')
        }
    })

    const onDatePickerClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if(!anchorPopup.visible) {
            showAnchorPopup(event);
        } else {
            hideAnchorPopup(event)
        }
    }, [showAnchorPopup]);

    return (
        <DatePickerContainer onClick={onDatePickerClick}>
            {label && <Label required={required} error={errorText}>
                {label}
            </Label>}
            <Input icon={calendarIcon} cursor='pointer' required={required} errorText={errorText} value={displayedValue} placeholder={'Дата'} onChange={onChange} name={name} disabled={true} />
        </DatePickerContainer>
    );
};

const DatePickerContainer = styled.div`
  cursor: pointer;
`

DatePicker.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default DatePicker;