import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../Icon";
import CopyIcon from "../Icons/CopyIcon";
import styled from "styled-components";
import {copyTextToClipboard} from "../../utils/copyTextToClipboard";

const ClipboardTitle = ({text}) => {

    const copyToClipboard = () => {
        copyTextToClipboard(text);
    }

    return (
        <ClipboardTitleContainer>
            <Title>
                {text}
            </Title>
            <IconWrapper>
                <Icon icon={CopyIcon} onClick={copyToClipboard} />
            </IconWrapper>
        </ClipboardTitleContainer>
    );
};

const ClipboardTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  ${({theme}) => theme.mixins.gothamBold({size: theme.fonts.sizes.smd})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  margin-right: 15px;
`

const IconWrapper = styled.div`
  
`

ClipboardTitle.propTypes = {
    text: PropTypes.string.isRequired,
};

export default ClipboardTitle;