import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";

const Row = ({children, marginBottom, direction, fullHeight}) => {
    return (
        <RowContainer direction={direction} marginBottom={marginBottom} fullHeight={fullHeight}>
            {children}
        </RowContainer>
    );
};

const RowContainer = styled.div`
  display: flex;
  flex-direction: ${({direction}) => direction};
  margin-bottom: ${({marginBottom}) => marginBottom}px;
  ${({fullHeight}) => fullHeight && css`
    height: 100%;
  `};
  :last-child {
    margin: 0;
  }
`

Row.propTypes = {
    children: PropTypes.any.isRequired,
    marginBottom: PropTypes.number,
    direction: PropTypes.oneOf(['row', 'column']),
    fullHeight: PropTypes.bool,
};

Row.defaultProps = {
    marginBottom: 20,
    direction: 'column',
    fullHeight: false,
}

export default Row;