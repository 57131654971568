import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Input from '../../Input';
import Row from '../../Structure/Row';
import Textarea from '../../Textarea';
import Group from '../../Structure/Group';
import Button from '../../Button';
import { prepareModalData } from '../../../utils/prepareModalData';
import { API_getLimitBudgetaryObligationsEditAndDeleteUrl } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import DatePicker from '../../DatePicker';
import { prepareDateToSee } from '../../../utils/dates';
import { isDate } from '../../../utils/isDate';

const EditLimitModal = (props) => {
  const { item, isEditable, hideModal, contractId, data, rowIndex } = prepareModalData(props);
  const responseData = data.responseData || {};
  const isBetweenClicked = data.isBetweenClicked;
  const limitId = responseData.id;

  const getFetchRequestUrl = useCallback(() => {
    const requestUrl = API_getLimitBudgetaryObligationsEditAndDeleteUrl(limitId);

    return requestUrl;
  }, [limitId]);

  const yearsFieldsData = useMemo(
    () => data.tableFields.filter(({ _isYear }) => _isYear)
      .reduce((result, yearField) => {
        result[yearField.name] = {
          required: true,
          _isYear: true,
          isPrice: true,
          _yearName: yearField._yearName,
          value: Number(item[yearField.name]) || 0,
          errorText: '',
        };

        return result;
      }, {}),
    [data.tableFields]);

  const getToday = () => new Date();
  const update_dates = isDate(item.update_dates) ? item.update_dates : getToday();

  const limitFieldsData = {
    ...yearsFieldsData,
    update_dates: {
      required: true,
      value: update_dates,
      isDate: true,
      errorText: '',
    },
    note: {
      required: false,
      value: item.note || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    }
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    return (
      <>
        {data.tableFields.filter(({ _isYear }) => _isYear).map(item => (
          <Row key={item.label}>
            <Input
              type='number'
              label={item.label}
              placeholder={`Лимит за ${item.label}г.`}
              onChange={handleForm}
              {...getFieldProps(item.name)}
            />
          </Row>
        ))}
        <Row>
          <DatePicker
            label='Дата изменения'
            onChange={handleForm}
            name='update_dates'
            calendarValue={fieldsData.update_dates.value}
            displayedValue={prepareDateToSee(fieldsData.update_dates.value)}
            required={fieldsData.update_dates.required}
            errorText={fieldsData.update_dates.errorText}
          />
        </Row>
        <Row>
          <Textarea placeholder='Примечание' onChange={handleForm} name='note' value={fieldsData.note.value} label='Примечание' />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const getFetchRequestData = ({ fieldsData }) => {
    const requestData = {
      contract_id: responseData.contract_id,
      notes: [].concat(responseData.notes),
      update_dates: [].concat(responseData.update_dates),
      years: [].concat(responseData.years),
      prices: [].concat(responseData.prices),
    };

    const pricesCalculated = [];

    Object.keys(fieldsData).forEach(key => {
      const { _isYear, value: yearPrice } = fieldsData[key];

      if (_isYear) {
        pricesCalculated.push(yearPrice || 0);
      }
    });

    // Редактируем строку таблицы — редактируем элемент по индексу
    if (isEditable) {
      requestData.notes[rowIndex] = fieldsData.note.value || '';
      requestData.update_dates[rowIndex] = fieldsData.update_dates.value || '';
      requestData.prices[rowIndex] = pricesCalculated;

      // Клик между строк — вставляем посередине
    } else if (isBetweenClicked) {
      const appendIndex = rowIndex + 1;

      requestData.notes.splice(appendIndex, 0, fieldsData.note.value || '');
      requestData.update_dates.splice(appendIndex, 0, fieldsData.update_dates.value || '');
      requestData.prices.splice(appendIndex, 0, pricesCalculated);

      // Иначе добавляем новую строку — вставляем в конец
    } else {
      requestData.notes.push(fieldsData.note.value || '');
      requestData.update_dates.push(fieldsData.update_dates.value || '');
      requestData.prices.push(pricesCalculated);
    }

    return JSON.stringify(requestData);
  };

  // Удаление и добавление новых происходит через изменение текущего экземпляра — используем метод PUT
  const getRequestMethod = () => 'PUT';

  const editModalProps = {
    fieldsData: limitFieldsData,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    getRequestMethod,
    getFetchRequestData,
  };

  return <ModalConstructor {...editModalProps} />
};

EditLimitModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditLimitModal;