export const tableFields = [
    {
        id: 0,
        name: 'date',
        label: 'Дата предъявления',
        props: {
            width: '50%',
        },
    },
    {
        id: 1,
        name: 'value',
        label: 'Содержание предъявления',
    },
];

export const tableData = [
    {
        id: {value: 0 },
        date: {value: '10.10.2010г.' },
        value: {value: 'Текст' },
    },
    {
        id: {value: 1 },
        date: {value: '10.10.2010г.' },
        value: {value: 'Текст' },
    },
    {
        id: {value: 2 },
        date: {value: '10.10.2010г.' },
        value: {value: 'Текст' },
    },
    {
        id: {value: 3 },
        date: {value: '10.10.2010г.' },
        value: {value: 'Текст' },
    },
]