import React from 'react';
import PropTypes from 'prop-types';
import SettingsIcon from "../Icons/SettingsIcon";
import Icon from "../Icon";
import styled from "styled-components";
import { useAnchorPopup } from "../AnchorPopup/useAnchorPopup";
import List from "../List";
import { useModal } from "../../hooks/useModal";
import EditYearModal from "../Forms/EditYearModal";
import DeleteLimitsYearModal from '../DeleteLimitsYearModal';

const LimitsYear = ({ label, responseData }) => {
    const listItems = [
        {
            id: 0,
            label: 'Редактировать',
        },
        {
            id: 1,
            label: 'Удалить',
            type: 'alert',
            action: 'delete',
        }
    ];

    const [showDeleteYearModal] = useModal({
        component: DeleteLimitsYearModal,
        title: 'Удалить год',
        props: { year: label, responseData },
    });

    const [showEditModal] = useModal({
        component: EditYearModal,
        title: 'Редактирование года лимита',
        props: { year: label, responseData },
    });

    const [showAnchorPopup] = useAnchorPopup({
        component: List, props: {
            list: listItems, onClick: (el) => {
                if (el.action === 'delete') {
                    showDeleteYearModal()
                } else {
                    showEditModal()
                }
            }
        }
    })

    const handleClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        showAnchorPopup(e)
    }

    return (
        <LimitsYearContainer>
            <Label>
                {label}
            </Label>
            <Icon icon={SettingsIcon} onClick={handleClick} />
        </LimitsYearContainer>
    );
};

const LimitsYearContainer = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  ${({ theme }) => theme.mixins.gothamSemibold()};
  font-size: ${({ theme }) => theme.fonts.sizes[theme.userSettings.fontSize]};
`

LimitsYear.propTypes = {
    label: PropTypes.string.isRequired,
};

export default LimitsYear;