import SearchIcon from "../Icons/SearchIcon";

export const tableFields = [
    {
        id: 0,
        name: 'name',
        label: 'Наименование',
        props: {
            width: '50%',
        },
    },
    {
        id: 1,
        name: 'value',
        label: 'Значение',
    },
];

export const searchIcons = [
    {
        id: 0,
        component: SearchIcon,
    },
];
