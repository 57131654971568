import ConfirmDeleteUser from '../components/ConfirmDeleteUser';
import ConfirmDeleteCharacteristic from '../components/ConfirmDeleteCharacteristic';
import EditUserModal from '../components/Forms/EditUserModal';
import EditCharacteristicsModal from '../components/Forms/EditCharacteristicsModal';
import EditBankGuaranteeModal from '../components/Forms/EditBankGuaranteeModal';
import ConfirmDeleteBankGuarantee from '../components/ConfirmDeleteBankGuarantee';
import EditObjectModal from '../components/Forms/EditObjectModal';
import ConfirmDeleteObject from '../components/ConfirmDeleteObject';
import EditPaymentStageModal from '../components/Forms/EditPaymentStageModal';
import ConfirmDeletePaymentStage from '../components/ConfirmDeletePaymentStage';
import EditKeyEventModal from '../components/Forms/EditKeyEventModal';
import ConfirmDeleteKeyEvent from '../components/ConfirmDeleteKeyEvent';
import EditClaimWorksModal from '../components/Forms/EditClaimWorksModal';
import ConfirmDeleteClaimWorks from '../components/ConfirmDeleteClaimWorks';
import EditJudicalWorkModal from '../components/Forms/EditJudicalWorkModal';
import ConfirmDeleteJudicalWork from '../components/ConfirmDeleteJudicalWork';
import EditCashExecutionModal from '../components/Forms/EditCashExecutionModal';
import ConfirmDeleteCashExecution from '../components/ConfirmDeleteCashExecution';
import EditLimitModal from '../components/Forms/EditLimitModal';
import ConfirmDeleteContractLimit from '../components/ConfirmDeleteContractLimit';
import EditObjectCharacteristicsModal from '../components/Forms/EditObjectCharacteristicsModal';
import ConfirmDeleteAboutObjects from '../components/ConfirmDeleteAboutObjects';
import EditUShModal from '../components/Forms/EditUShModal';
import ConfirmDeleteUsh from '../components/ConfirmDeleteUsh';
import EditUHModal from '../components/Forms/EditUHModal';
import ConfirmDeleteUH from '../components/ConfirmDeleteUH';
import EditGroupRecordModal from '../components/Forms/EditGroupRecordModal';
import ConfirmDeleteObjectGroupUp from '../components/ConfirmDeleteObjectGroupUp';
import EditUPRecordModal from '../components/Forms/EditUPRecordModal';
import ConfirmDeleteUPRec from '../components/ConfirmDeleteUPRec';

export const getAllModalPropsByModalType = ({
  modalType,
  modalProps,

  item,

  contractId,
  objectId,
  groupId,
  constructionCertificateId,

  rowIndex,
  ROW_INDEX_INITIAL_STATIC,
}) => {
  let editModalProps = {};
  let betweenAddModalProps = {};
  let deleteModalProps = {};

  switch (modalType) {
    case 'user':
      editModalProps = {
        component: EditUserModal,
        name: 'edit-user',
        title: 'Редактировать пользователя',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteUser,
        name: 'confirm-delete-user',
        title: 'Подтверждение удаления пользователя',
        props: item,
      };

      betweenAddModalProps = {
        component: EditUserModal,
        name: 'create-user',
        title: 'Добавить пользователя',
      }

      break;

    case 'characteristics': {
      editModalProps = {
        component: EditCharacteristicsModal,
        name: 'edit-characteristics',
        title: 'Редактировать характеристику',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteCharacteristic,
        name: 'confirm-delete-characteristic',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditCharacteristicsModal,
        name: 'create-characteristics',
        title: 'Добавить новую характеристику',
        props: { contractId },
      }

      break;
    }

    case 'guarantees': {
      editModalProps = {
        component: EditBankGuaranteeModal,
        name: 'edit-bank-guarantees',
        title: 'Редактировать гарантию',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteBankGuarantee,
        name: 'confirm-delete-guarantees',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditBankGuaranteeModal,
        name: 'create-bank-guarantees',
        title: 'Добавить новую гарантию',
        props: { contractId },
      }

      break;
    }

    case 'objects': {
      editModalProps = {
        component: EditObjectModal,
        name: 'edit-objects',
        title: 'Редактировать объект',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteObject,
        name: 'confirm-delete-objects',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditObjectModal,
        name: 'create-objects',
        title: 'Добавить новый объект',
        props: { contractId },
      }

      break;
    }

    case 'payment-stages': {
      editModalProps = {
        component: EditPaymentStageModal,
        name: 'edit-payment-stages',
        title: 'Редактировать этап платежа',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeletePaymentStage,
        name: 'confirm-delete-payment-stages',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditPaymentStageModal,
        name: 'create-payment-stages',
        title: 'Добавить новый этап платежа',
        props: { contractId },
      }

      break;
    }

    case 'key-event': {
      editModalProps = {
        component: EditKeyEventModal,
        name: 'edit-key-event',
        title: 'Редактировать ключевое событие',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteKeyEvent,
        name: 'confirm-delete-key-event',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditKeyEventModal,
        name: 'create-key-event',
        title: 'Добавить новое ключевое событие',
        props: { contractId },
      }

      break;
    }

    case 'claim-works': {
      editModalProps = {
        component: EditClaimWorksModal,
        name: 'edit-claim-works',
        title: 'Редактировать претензионную работу',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteClaimWorks,
        name: 'confirm-delete-claim-works',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditClaimWorksModal,
        name: 'create-claim-works',
        title: 'Добавить новую претензионную работу',
        props: { contractId },
      }

      break;
    }

    case 'judical-works': {
      editModalProps = {
        component: EditJudicalWorkModal,
        name: 'edit-judical-works',
        title: 'Редактировать судебную работу',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteJudicalWork,
        name: 'confirm-delete-judical-works',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditJudicalWorkModal,
        name: 'create-judical-works',
        title: 'Добавить новую судебную работу',
        props: { contractId },
      }

      break;
    }

    case 'cash-execution': {
      editModalProps = {
        component: EditCashExecutionModal,
        name: 'edit-cash-execution',
        title: 'Редактировать кассовое исполнение',
        props: { item, isEditable: true, contractId },
      };

      deleteModalProps = {
        component: ConfirmDeleteCashExecution,
        name: 'confirm-delete-cash-execution',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditCashExecutionModal,
        name: 'create-cash-execution',
        title: 'Добавить новое кассовое исполнение',
        props: { contractId },
      }

      break;
    }

    case 'contract-limit': {
      editModalProps = {
        component: EditLimitModal,
        name: 'edit-contract-limit',
        title: 'Редактировать лимит',
        props: { item, isEditable: true, ...modalProps, rowIndex },
      };

      deleteModalProps = {
        component: ConfirmDeleteContractLimit,
        name: 'confirm-delete-contract-limit',
        title: 'Подтверждение удаления',
        props: { ROW_INDEX_INITIAL_STATIC, rowIndex, ...modalProps }
      };

      betweenAddModalProps = {
        component: EditLimitModal,
        name: 'create-contract-limit',
        title: 'Добавить новый лимит',
        props: { contractId, ...modalProps, rowIndex, isBetweenClicked: true },
      }

      break;
    }

    case 'objects-about': {
      editModalProps = {
        component: EditObjectCharacteristicsModal,
        name: 'edit-objects-about',
        title: 'Редактировать характеристику',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteAboutObjects,
        name: 'confirm-delete-objects-about',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditObjectCharacteristicsModal,
        name: 'create-objects-about',
        title: 'Добавить новую характеристику',
        props: { contractId, objectId },
      }

      break;
    }

    case 'ush': {
      editModalProps = {
        component: EditUShModal,
        name: 'edit-ush',
        title: 'Редактировать УШ',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteUsh,
        name: 'confirm-delete-ush',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditUShModal,
        name: 'create-ush',
        title: 'Добавить новую запись',
        props: { contractId, objectId },
      }

      break;
    }

    case 'uh': {
      editModalProps = {
        component: EditUHModal,
        name: 'edit-uh',
        title: 'Редактировать УХ',
        props: { item, isEditable: true },
      };

      deleteModalProps = {
        component: ConfirmDeleteUH,
        name: 'confirm-delete-uh',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditUHModal,
        name: 'create-uh',
        title: 'Добавить новую запись',
        props: { contractId, objectId },
      }

      break;
    }

    case 'edit-group-content': {
      editModalProps = {
        component: EditGroupRecordModal,
        name: 'edit-group-content',
        title: 'Редактировать запись',
        props: { item, isEditable: true, groupId },
      };

      deleteModalProps = {
        component: ConfirmDeleteObjectGroupUp,
        name: 'confirm-delete-group-content',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditGroupRecordModal,
        name: 'create-group-content',
        title: 'Добавить новую запись',
        props: { contractId, objectId, groupId },
      }

      break;
    }

    case 'group-up-record': {
      editModalProps = {
        component: EditUPRecordModal,
        name: 'edit-group-up-record',
        title: 'Редактировать запись',
        props: { item, isEditable: true, constructionCertificateId },
      };

      deleteModalProps = {
        component: ConfirmDeleteUPRec,
        name: 'confirm-delete-group-up-record',
        title: 'Подтверждение удаления',
        props: item,
      };

      betweenAddModalProps = {
        component: EditUPRecordModal,
        name: 'create-group-up-record',
        title: 'Добавить новую запись',
        props: { constructionCertificateId },
      }

      break;
    }

    default:
      console.log('❌ Error. Unknown modalType=', modalType);
      break;
  }

  return {
    editModalProps,
    deleteModalProps,
    betweenAddModalProps,
  };
};