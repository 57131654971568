import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const NoData = ({children, color}) => {
    return (
        <NoDataContainer color={color}>
            {children}
        </NoDataContainer>
    );
};

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${({theme}) => theme.mixins.gothamBold()};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  color: ${({theme, color}) => theme.colors[color]};
`

NoData.propTypes = {
    children: PropTypes.any.isRequired,
};

export default NoData;