import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import DatePicker from '../../DatePicker';
import Group from '../../Structure/Group';
import Button from '../../Button';
import { API_getClaimWorks, API_getClaimWorksEditAndDeleteUrl, API_URL_CLAIM_WORKS_SET_POSITIONS } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import { prepareModalData } from '../../../utils/prepareModalData';
import { prepareDateToSee } from '../../../utils/dates';
import FilePreview from "../../FilePreview";

const EditClaimWorksModal = (props) => {
  const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
  const claimWorksId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getClaimWorksEditAndDeleteUrl(claimWorksId) :
      API_getClaimWorks();

    return requestUrl;
  }, [isEditable, claimWorksId]);

  const claimWorksProps = {
    amount: {
      required: true,
      value: item.amount || '',
      errorText: '',
      isPrice: true,
    },
    number: {
      required: true,
      value: item.number || '',
      errorText: '',
    },
    date: {
      required: true,
      value: item.date || '',
      errorText: '',
      isDate: true,
    },
    cause: {
      required: true,
      value: item.cause || '',
      errorText: '',
    },
    status: {
      required: false,
      value: item.status || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    },
    files: {
      required: false,
      value: item.files || [],
      errorText: '',
      isFile: true,
    },
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
      handleFileInput,
      handleFileDelete,
    } = templateProps;

    const getFieldProps = (name) => {
      return {
        name,
        value: fieldsData[name].value,
        required: fieldsData[name].required,
        errorText: fieldsData[name].errorText,
      };
    };

    const onFileLoadHandler = (files) => {
      handleFileInput({ files, fileKeyInState: 'files' });
    };

    const onFileDeleteHandler = (file) => {
      handleFileDelete({ file, fileKeyInState: 'files' });
    };

    return (
      <>
        <Row>
          <DatePicker
            label='Дата'
            onChange={handleForm}
            name='date'
            calendarValue={fieldsData.date.value}
            displayedValue={prepareDateToSee(fieldsData.date.value)}
            required={fieldsData.date.required}
            errorText={fieldsData.date.errorText}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите номер требования'
            label='Номер требования'
            onChange={handleForm}
            {...getFieldProps('number')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите причину выставления требования'
            label='Причина выставления требования'
            onChange={handleForm}
            {...getFieldProps('cause')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите сумму требования'
            label='Сумма требования, руб.'
            onChange={handleForm}
            {...getFieldProps('amount')}
          />
        </Row>
        <Row>
          <Input
            placeholder='Введите статус исполнения требования'
            label='Статус исполнения требования'
            onChange={handleForm}
            {...getFieldProps('status')}
          />
        </Row>
        <Row>
          <FilePreview onLoad={onFileLoadHandler} files={fieldsData.files.value} onDelete={onFileDeleteHandler} />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: claimWorksProps,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    SAVE_FILES_MODEL_TYPE: 'claim_works',

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_CLAIM_WORKS_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditClaimWorksModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditClaimWorksModal;