import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Column from '../../Structure/Column';
import DatePicker from '../../DatePicker';
import Input from '../../Input';
import Group from '../../Structure/Group';
import Button from '../../Button';
import Select from '../../Select';
import Textarea from '../../Textarea';
import { API_getCashExecutions, API_getCashExecutionsEditAndDeleteUrl, API_getPaymentStages, API_URL_GET_CASH_EXECUTIONS_SET_POSITIONS } from '../../../utils/api';
import ModalConstructor from '../ModalConstructor';
import { prepareModalData } from '../../../utils/prepareModalData';
import { prepareDateToSee } from '../../../utils/dates';
import { getAuthorizationHeader } from '../../../utils/auth';
import { fetchRequest } from '../../../utils/fetchRequest';
import FilePreview from "../../FilePreview";

const EditCashExecutionModal = (props) => {
	const { item, isEditable, hideModal, appendAfterId, allIds, contractId } = prepareModalData(props);
	const cashExecutionId = item.id;

	const getFetchRequestUrl = useCallback((isEditable) => {
		const requestUrl = isEditable ?
			API_getCashExecutionsEditAndDeleteUrl(cashExecutionId) :
			API_getCashExecutions();

		return requestUrl;
	}, [isEditable, cashExecutionId]);

	const [paymentStageData, setPaymentStageData] = useState([]);

	const staticPaymentsType = {
		id: 0,
		label: 'Аванс',
		value: 'Аванс',
		name: 'stage_name',
	};

	const cashExecutionProps = {
		stage_name: {
			required: false,
			value: item.stage_name || staticPaymentsType.value,
			errorText: '',
			needToSendAlways: true,
		},
		price: {
			required: true,
			value: item.price || '',
			errorText: '',
			isPrice: true,
		},
		payment_date: {
			required: false,
			value: item.payment_date || '',
			errorText: '',
			isDate: true,
		},
		payment_date_planned: {
			required: true,
			value: item.payment_date_planned || '',
			errorText: '',
			isDate: true,
		},
		amount_paid: {
			required: true,
			value: item.amount_paid || '',
			errorText: ''
		},
		note: {
			required: false,
			value: item.note || '',
			errorText: '',
		},
		contract_id: {
			value: contractId,
			needToSendAlways: true,
		},
		files: {
			required: false,
			value: item.files || [],
			errorText: '',
			isFile: true,
		},
	};

	const getPaymentStages = useCallback(() => {
		const getData = async () => {
			const url = API_getPaymentStages([{
				column: 'contract_id',
				operator: '=',
				value: contractId,
			}]);

			const options = {
				method: 'GET',
				headers: {
					'Authorization': getAuthorizationHeader(),
					'Content-Type': 'application/json',
				},
			};

			const { success, response } = await fetchRequest(url, options);

			if (success && response) {
				const paymentStageResponseData = response.data.map(({ name, amount, expiration_date_planned, id }) => ({
					id: { value: id },
					name: { value: name },
					amount: { value: amount, isPrice: true },
					expiration_date_planned: { value: expiration_date_planned, isDate: true },
				}));

				setPaymentStageData(paymentStageResponseData);
			}
		};

		getData();
	}, [contractId]);

	useEffect(() => {
		getPaymentStages();
	}, []);

	const getTemplate = (templateProps) => {
		const {
			fieldsData,
			submitButtonText,
			handleForm,
			onCloseAction,
			onSubmitAction,
			setFieldsData,
			handleFileInput,
			handleFileDelete,
		} = templateProps;

		const getFieldProps = (name) => {
			return {
				name,
				value: fieldsData[name].value,
				required: fieldsData[name].required,
				errorText: fieldsData[name].errorText,
			};
		};

		const onFileLoadHandler = (files) => {
			handleFileInput({ files, fileKeyInState: 'files' });
		};

		const onFileDeleteHandler = (file) => {
			handleFileDelete({ file, fileKeyInState: 'files' });
		};

		const paymentStageListItems = paymentStageData.map(paymentStageItem => {
			const { id } = paymentStageItem;

			return {
				id: id.value,
				name: 'stage_name',
				value: paymentStageItem.name.value,
				label: paymentStageItem.name.value,
			};
		});

		const selectList = [
			staticPaymentsType,
			...paymentStageListItems,
		];

		const handleSelect = ({ name, value, id }) => {
			const selectedPaymentStageItem = paymentStageData.find((paymentStateItem) => paymentStateItem.id.value === id);

			const newFieldsData = {
				...fieldsData,
				stage_name: {
					...fieldsData.stage_name,
					value,
				},
			};

			if (selectedPaymentStageItem) {
				const paymentDatePlannedValue = selectedPaymentStageItem.expiration_date_planned.value?.date;

				newFieldsData.price = {
					...fieldsData.price,
					value: selectedPaymentStageItem.amount.value || '',
				};
				newFieldsData.payment_date_planned = {
					...fieldsData.payment_date_planned,
					value: paymentDatePlannedValue ? new Date(paymentDatePlannedValue) : '',
				};
			};

			setFieldsData(newFieldsData);
		};

		return (
			<>
				<Row>
					<Select value={fieldsData.stage_name.value} required={true} onChange={handleSelect} name='stage_name' label='Наименование этапа/аванс' list={selectList} />
				</Row>
				<Row>
					<Input
						type='number'
						placeholder='Стоимость этапа'
						label='Введите стоимость этапа'
						onChange={handleForm}
						{...getFieldProps('price')}
					/>
				</Row>
				<Row>
					<Input
						type='number'
						placeholder='Оплачиваемая сумма'
						label='Введите оплачиваемую сумму'
						onChange={handleForm}
						{...getFieldProps('amount_paid')}
					/>
				</Row>
				<Row direction='row'>
					<Column>
						<DatePicker
							label='Планируемая дата оплаты'
							onChange={handleForm}
							name='payment_date_planned'
							calendarValue={fieldsData.payment_date_planned.value}
							displayedValue={prepareDateToSee(fieldsData.payment_date_planned.value)}
							required={fieldsData.payment_date_planned.required}
							errorText={fieldsData.payment_date_planned.errorText}
						/>
					</Column>
					<Column>
						<DatePicker
							label='Фактическая дата оплаты'
							onChange={handleForm}
							name='payment_date'
							calendarValue={fieldsData.payment_date.value}
							displayedValue={prepareDateToSee(fieldsData.payment_date.value)}
							required={fieldsData.payment_date.required}
							errorText={fieldsData.payment_date.errorText}
						/>
					</Column>
				</Row>
				<Row>
					<Textarea placeholder='Примечание' onChange={handleForm} name='note' value={fieldsData.note.value} label='Примечание' />
				</Row>
				<Row>
					<FilePreview onLoad={onFileLoadHandler} files={fieldsData.files.value} onDelete={onFileDeleteHandler} />
				</Row>
				<Group align='right'>
					<Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
					<Button label={submitButtonText} onClick={onSubmitAction} />
				</Group>
			</>
		);
	};

	const editModalProps = {
		fieldsData: cashExecutionProps,

		getFetchRequestUrl,
		getTemplate,

		hideModal,
		isEditable,

		SAVE_FILES_MODEL_TYPE: 'cash_executions',

		appendAfterId,

		ALL_OBJECT_IDS: allIds,
		SET_POSITIONS_URL: API_URL_GET_CASH_EXECUTIONS_SET_POSITIONS,
	};

	return <ModalConstructor {...editModalProps} />
};

EditCashExecutionModal.propTypes = {
	hideModal: PropTypes.func.isRequired,
};

export default EditCashExecutionModal;