import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import {darken} from 'polished'

export const calculateHeight = (size) => {
    switch (size) {
        case 'small':
            return '34px'
        case 'normal':
            return '40px'
        case 'big':
            return '50px'
        default:
            return '34px'
    }
}

const Button = ({type, label, onClick, size, theme, disabled, color, height}) => {
    return (
        <ButtonContainer height={height} color={color} buttonTheme={theme} size={size} type={type} onClick={onClick && !disabled && onClick} disabled={disabled}>
            {label}
        </ButtonContainer>
    );
};

const ButtonContainer = styled.button`
  height: ${({ height }) => height ? (height + 'px') : ''};
  border-radius: ${({theme}) => theme.other.borderRadiusNormal};
  border: ${({buttonTheme, theme}) => buttonTheme === 'hollow' ? `2px solid ${theme.colors.primary}` : 0};
  min-height: ${({size}) => calculateHeight(size)};
  padding: 0 20px;
  ${({theme, buttonTheme}) => theme.mixins.gothamBold({color: buttonTheme === 'hollow' ? theme.colors.primary : '#fff'})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  transition: background-color ${({theme}) => theme.transitions.normal};
  ${({disabled}) => disabled ? css`
    cursor: not-allowed;
    background-color: ${({theme, buttonTheme}) => buttonTheme === 'hollow' ? 'transparent' : theme.colors.border};
  ` : css`
    background-color: ${({theme, buttonTheme, color}) => buttonTheme === 'hollow' ? 'transparent' : theme.colors[color]};
    cursor: pointer;
    :hover {
        background-color: ${({theme, buttonTheme, color}) => buttonTheme === 'hollow' ? 'transparent' : darken(.1, theme.colors[color])};
    }
  `}
`

Button.propTypes = {
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    label: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'normal', 'big']),
    theme: PropTypes.oneOf(['normal', 'hollow']),
    color: PropTypes.string,
};

Button.defaultProps = {
    type: 'button',
    theme: 'normal',
    size: 'normal',
    color: 'primary',
}

export default Button;