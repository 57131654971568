import React from 'react';

import { API_getCashExecutionsEditAndDeleteUrl } from '../../utils/api';
import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteCashExecution = ({ hideModal, data }) => {
  const itemId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getCashExecutionsEditAndDeleteUrl(itemId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите кассовое исполнение',
    DELETE_STATIC_VALUE: data.stage_name.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteCashExecution;