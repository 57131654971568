export const DEFAULT_FILTERS_DATA = {
  date_before: {
    value: '',
    errorText: '',
  },
  date_from: {
    value: '',
    errorText: '',
  },
  no_closed_dates_checkbox: {
    value: '',
    errorText: '',
  },
};

export const filterClosedDateItems = (items, filterProps) => items.filter(item => {
  const {
    date_before,
    date_from,
    no_closed_dates_checkbox,
  } = filterProps;

  const dateReal = item.closing_date_real.value;
  const datePlan = item.closing_date_planned.value;

  if (no_closed_dates_checkbox.value) {
    return !dateReal;
  }

  if (date_from.value && date_before.value && datePlan) {
    const dateFromInt = Number(new Date(date_from.value));
    const dateBeforeInt = Number(new Date(date_before.value));
    const datePlanInt = Number(new Date(datePlan));

    return datePlanInt >= dateFromInt && datePlanInt <= dateBeforeInt;
  }

  if (date_from.value && datePlan) {
    const dateFromInt = Number(new Date(date_from.value));
    const datePlanInt = Number(new Date(datePlan));

    return datePlanInt >= dateFromInt;
  }

  if (date_before.value && datePlan) {
    const dateBeforeInt = Number(new Date(date_before.value));
    const datePlanInt = Number(new Date(datePlan));

    return datePlanInt <= dateBeforeInt;
  }

  if ((!dateReal || !datePlan) && (date_from.value || date_before.value)) {
    return false;
  }

  return true;
});