import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({color}) => {
    return (
        <svg width="158" height="59" viewBox="0 0 158 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.3996 9.39489C40.0796 9.39489 44.0206 11.3693 46.4345 15.5156L40.523 18.3291C39.2421 17.2432 38.2076 16.256 35.4981 16.256C32.5423 16.256 29.5373 18.5265 29.5373 22.7221C29.5373 26.8684 32.5423 29.0403 35.4981 29.0403C38.2076 29.0403 39.2421 28.1518 40.523 27.0658L46.5331 29.8794C44.0206 34.0256 40.1781 35.9013 35.3996 35.9013C29.6851 35.9013 22 31.8044 22 22.7221C22 13.7879 29.6851 9.39489 35.3996 9.39489ZM58.307 0.608765V35.5065H50.5234V0.608765H58.307ZM76.0417 9.39489C81.8548 9.39489 89.9832 13.7879 88.3575 25.3876H70.475C71.3617 28.0531 73.6771 29.4351 76.7806 29.4351C79.9335 29.4351 80.9188 28.7441 82.6922 28.0037L86.9781 32.15C84.712 34.3712 81.6577 36.0001 76.4851 36.0001C70.1301 36.0001 62.5436 31.607 62.5436 22.7715C62.5436 13.7879 70.2286 9.39489 76.0417 9.39489ZM76.0417 16.0585C73.8249 16.0585 71.5588 17.3419 70.5735 19.9086H81.4606C80.6724 17.3419 78.2585 16.0585 76.0417 16.0585ZM109.245 9.44425C109.935 9.44425 110.624 9.44425 111.265 9.59233V17.0457C110.674 16.8976 109.984 16.8976 109.491 16.8976C104.762 16.8976 100.969 20.3035 100.575 25.0421V35.5065H92.7912V9.83913H100.575V16.1079C101.757 12.2578 104.713 9.44425 109.245 9.44425ZM127.867 9.39489C132.547 9.39489 136.488 11.3693 138.902 15.5156L132.99 18.3291C131.709 17.2432 130.675 16.256 127.965 16.256C125.009 16.256 122.004 18.5265 122.004 22.7221C122.004 26.8684 125.009 29.0403 127.965 29.0403C130.675 29.0403 131.709 28.1518 132.99 27.0658L139 29.8794C136.488 34.0256 132.645 35.9013 127.867 35.9013C122.152 35.9013 114.467 31.8044 114.467 22.7221C114.467 13.7879 122.152 9.39489 127.867 9.39489Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6002 54C8.99102 54 7.20967 54.5187 5.19869 55.8417L0.801758 49.1583C3.85744 47.148 7.14276 46 10.6002 46C14.0577 46 17.343 47.148 20.3987 49.1583C22.4097 50.4813 24.191 51 25.8002 51C27.4094 51 29.1908 50.4813 31.2018 49.1583C34.2574 47.148 37.5428 46 41.0002 46C44.4577 46 47.743 47.148 50.7987 49.1583C52.8097 50.4813 54.591 51 56.2002 51C57.8094 51 59.5908 50.4813 61.6018 49.1583C64.6574 47.148 67.9428 46 71.4002 46C74.8577 46 78.143 47.148 81.1987 49.1583C83.2097 50.4813 84.991 51 86.6002 51C88.2094 51 89.9908 50.4813 92.0018 49.1583C95.0574 47.148 98.3428 46 101.8 46C105.258 46 108.543 47.148 111.599 49.1583C113.61 50.4813 115.391 51 117 51C118.609 51 120.391 50.4813 122.402 49.1583C125.457 47.148 128.743 46 132.2 46C135.658 46 138.943 47.148 141.999 49.1583C144.01 50.4813 145.791 51 147.4 51C149.009 51 150.791 50.4813 152.802 49.1583L157.199 55.8417C154.143 57.852 150.858 59 147.4 59C143.943 59 140.657 57.852 137.602 55.8417C135.591 54.5187 133.809 54 132.2 54C130.591 54 128.81 54.5187 126.799 55.8417C123.743 57.852 120.458 59 117 59C113.543 59 110.257 57.852 107.202 55.8417C105.191 54.5187 103.409 54 101.8 54C100.191 54 98.4097 54.5187 96.3987 55.8417C93.343 57.852 90.0577 59 86.6002 59C83.1428 59 79.8574 57.852 76.8018 55.8417C74.7908 54.5187 73.0094 54 71.4002 54C69.791 54 68.0097 54.5187 65.9987 55.8417C62.943 57.852 59.6577 59 56.2002 59C52.7428 59 49.4574 57.852 46.4018 55.8417C44.3908 54.5187 42.6094 54 41.0002 54C39.391 54 37.6097 54.5187 35.5987 55.8417C32.543 57.852 29.2577 59 25.8002 59C22.3428 59 19.0574 57.852 16.0018 55.8417C13.9908 54.5187 12.2094 54 10.6002 54Z" fill="#0DA1E0"/>
        </svg>
    );
};

Logo.propTypes = {
    color: PropTypes.string,
};

Logo.defaultProps = {
    color: '#ffffff',
}

export default Logo;