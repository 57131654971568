import { SERVER_HOSTNAME } from './api';

export const prepareFiles = filesArrayData => {
  if (!filesArrayData.length) return [];

  return filesArrayData.map((item, index) => {
    const fileName = item.name + (item.extension ? '.' + item.extension : '');

    return {
      id: item.id,
      name: item.name,
      label: fileName,
      link: SERVER_HOSTNAME + item.url,
    };
  });
};