import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Row from '../../Structure/Row';
import Input from '../../Input';
import Group from '../../Structure/Group';
import Button from '../../Button';
import { prepareModalData } from '../../../utils/prepareModalData';
import ModalConstructor from '../ModalConstructor';
import { API_getGroups, API_getGroupsEditAndDeleteUrl, API_URL_GROUPS_SET_POSITIONS } from '../../../utils/api';

const EditGroupUPModal = (props) => {
  const { item, isEditable, hideModal, contractId, objectId, appendAfterId, allIds } = prepareModalData(props);
  const itemId = item.id;

  const getFetchRequestUrl = useCallback((isEditable) => {
    const requestUrl = isEditable ?
      API_getGroupsEditAndDeleteUrl(itemId) :
      API_getGroups();

    return requestUrl;
  }, [isEditable, itemId]);

  const editProps = {
    name: {
      required: true,
      value: item.text || '',
      errorText: '',
    },
    contract_id: {
      value: contractId,
      needToSendAlways: true,
    },
    contract_object_id: {
      value: objectId,
      needToSendAlways: true,
    },
  };

  const getTemplate = (templateProps) => {
    const {
      fieldsData,
      submitButtonText,
      handleForm,
      onCloseAction,
      onSubmitAction,
    } = templateProps;

    return (
      <>
        <Row>
          <Input
            placeholder='Введите название группы'
            label='Название'
            onChange={handleForm}
            required={fieldsData.name.required}
            name='name'
            value={fieldsData.name.value}
            errorText={fieldsData.name.errorText}
          />
        </Row>
        <Group align='right'>
          <Button label='Закрыть' theme='hollow' onClick={onCloseAction} />
          <Button label={submitButtonText} onClick={onSubmitAction} />
        </Group>
      </>
    );
  };

  const editModalProps = {
    fieldsData: editProps,

    getFetchRequestUrl,
    getTemplate,

    hideModal,
    isEditable,

    appendAfterId,

    ALL_OBJECT_IDS: allIds,
    SET_POSITIONS_URL: API_URL_GROUPS_SET_POSITIONS,
  };

  return <ModalConstructor {...editModalProps} />
};

EditGroupUPModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default EditGroupUPModal;