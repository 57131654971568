import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from "styled-components";
import Error from "../Error";

const Input = ({value, name, cursor, placeholder, onChange, icon, theme, type, label, required, errorText, size, disabled, multiple}) => {

    const handleChange = (value) => {
        onChange({name, value})
    }

    const isIconLeft = (icons) => {
        return icons.some(el => el.position !== 'right')
    }

    return (
        <InputContainer cursor={cursor} hasIcons={!!icon?.length && isIconLeft(icon)} localTheme={theme} size={size}>
            {icon && icon.map((el, index) => (
                <InputIcon size={size} options={el.options} localTheme={theme} hasAction={!!el.action} key={`input-icon-${el.id}-${index}`} position={el.position || 'left'} onClick={el.action && el.action}>
                    <el.component />
                </InputIcon>
            ))}
            {label && <Label error={!!errorText} required={required}>
                {label}
            </Label>}
            <input
                autocomplete='off'
                type={type}
                multiple={multiple}
                placeholder={placeholder}
                name={name}
                disabled={disabled}
                value={value}
                onChange={(e) => handleChange(e.currentTarget.value)}
            />
            {errorText && (
                <ErrorWrapper>
                    <Error error={errorText} />
                </ErrorWrapper>
            )}
        </InputContainer>
    );
};

const calculateSize = (size) => {
    switch (size) {
        case 'small':
            return '34px'
        case 'normal':
            return '40px'
        case 'big':
            return '50px'
        default:
            return '40px'
    }
}

const calculateTop = (size) => {
    switch (size) {
        case 'small':
            return '7px'
        case 'normal':
            return '10px'
        case 'big':
            return '15px'
        default:
            return '10px'
    }
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  input {
    width: 100%;
    cursor: ${({cursor}) => cursor};
    border-radius: ${({theme}) => theme.other.borderRadiusNormal};
    background-color: #fff;
    border: 1px solid ${({theme}) => theme.colors.border};
    outline: none;
    height: ${({size}) => calculateSize(size)};
    padding: 0 ${({hasIcons}) => hasIcons ? '44px' : '10px'};
    ${({theme}) => theme.mixins.gothamRegular()};
    font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
    ::placeholder {
      color: #000;
      opacity: .3;
    }
  }
  ${({localTheme}) => localTheme === 'dark' && css`
    input {
      color: #fff;
      background-color: rgba(0,0,0,.1);
      border: 0;
      ::placeholder {
        color: #fff;
        opacity: .3;
      }
    }
  `};
`

const InputIcon = styled.div`
  position: absolute;
  top: ${({size}) => calculateTop(size)};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({hasAction}) => hasAction ? 'pointer' : 'initial'};
  opacity: ${({hasIcons}) => hasIcons ? .5 : 1};
  transition: opacity ${({theme}) => theme.transitions.normal};
  ${({position}) => position === 'left' ? css`
    left: 12px;
  ` : css`
    right: 12px;
  `}
  svg {
    width: 100%;
    height: 100%;
    fill: ${({theme}) => theme.colors.primary};
  }
  ${({hasAction}) => hasAction && css`
    :hover {
      opacity: 1;
    }
  `}
    ${({localTheme}) => localTheme === 'dark' && css`
      opacity: .5;
      svg {
        fill: #fff;
      }
    `};
  ${({options}) => css`
    ${options}
  `}
`

const ErrorWrapper = styled.div`
  margin-top: 3px;
`

export const Label = styled.div`
  margin-bottom: 5px;
  ${({theme, error}) => theme.mixins.gothamBold({color: error ? theme.colors.alert : theme.colors.text})};
  font-size: ${({theme}) => theme.fonts.sizes[theme.userSettings.fontSize]};
  ${({required}) => required && css`
    :after {
      content: '*';
      color: ${({theme}) => theme.colors.alert};
    }
  `};
`

Input.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    icon: PropTypes.object,
    type: PropTypes.oneOf(['text', 'password', 'button', 'checkbox', 'color', 'date', 'file', 'radio', 'number']),
    label: PropTypes.string,
    theme: PropTypes.oneOf(['normal', 'dark']),
    size: PropTypes.oneOf(['small', 'normal', 'big']),
};

Input.defaultProps = {
    type: 'text',
    theme: 'normal',
    size: 'normal',
}

export default Input;