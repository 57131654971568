export const DEFAULT_EMPTY_PROPERTY = '—';

export const setProperties = (properties) => {
  return Object.keys(properties).reduce((result, property) => {
    const value = properties[property];

    result[property] = value;

    return result;
  }, {});
};