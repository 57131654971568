import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageContainer = ({ children }) => {
	return (
		<PageContainerWrapper>
			{children}
		</PageContainerWrapper>
	);
};

const PageContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
`

PageContainer.propTypes = {
	children: PropTypes.any.isRequired,
};

export default PageContainer;