import React from 'react';

import { API_getUserEditAndDeleteUrl } from '../../utils/api';
import DeleteModalConstructor from '../Forms/DeleteModalConstructor';

const ConfirmDeleteUser = ({ hideModal, data }) => {
  const userId = data.id.value;

  const deleteModalProps = {
    hideModal,

    DELETE_REQUEST_URL: API_getUserEditAndDeleteUrl(userId),
    DELETE_STATIC_HINT: 'Вы уверены, что хотите удалить пользователя',
    DELETE_STATIC_VALUE: data.email.value,
  };

  return <DeleteModalConstructor {...deleteModalProps} />
};

export default ConfirmDeleteUser;