import React from 'react';

const DateIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 30H4.5C3.30734 29.9974 2.16428 29.5224 1.32094 28.6791C0.477595 27.8357 0.00264157 26.6927 0 25.5V7.5C0.00264157 6.30734 0.477595 5.16428 1.32094 4.32094C2.16428 3.4776 3.30734 3.00264 4.5 3H25.5C26.6927 3.00264 27.8357 3.4776 28.6791 4.32094C29.5224 5.16428 29.9974 6.30734 30 7.5V25.5C29.9974 26.6927 29.5224 27.8357 28.6791 28.6791C27.8357 29.5224 26.6927 29.9974 25.5 30ZM4.5 6C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V25.5C3 25.8978 3.15804 26.2794 3.43934 26.5607C3.72064 26.842 4.10218 27 4.5 27H25.5C25.8978 27 26.2794 26.842 26.5607 26.5607C26.842 26.2794 27 25.8978 27 25.5V7.5C27 7.10218 26.842 6.72064 26.5607 6.43934C26.2794 6.15804 25.8978 6 25.5 6H4.5Z" />
            <path d="M29 10H2V13H29V10Z" />
            <path d="M9 0H6V5H9V0Z" />
            <path d="M24 0H21V5H24V0Z" />
        </svg>
    );
};

export default DateIcon;