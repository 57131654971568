import { createSlice } from '@reduxjs/toolkit';

export const upReducer = createSlice({
    name: 'up',
    initialState: {
        upCount: null,
        upClosedCount: null,
    },
    reducers: {
        setUpCount: (state, action) => {
            const { upCount, upClosedCount } = action.payload;

            return {
                upCount,
                upClosedCount,
            };
        },
        resetUpCount: () => {
            return {
                upCount: null,
                upClosedCount: null,
            };
        },
    },
});

export const { setUpCount, resetUpCount } = upReducer.actions;

export default upReducer.reducer;
